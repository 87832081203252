<template>
  <div>
    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-folder"></i>
                  <span>Profile Setting</span>
                </a>
              </li>
              <li><a href="#">User</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">
  
        <div class="col-xs-12">
  
        </div>
        <div class="col-xs-12">
          <section class="boxWrap">
            <div class="box">
              <div class="boxHeader">
                <div class="boxHeaderLeft">
                  <h3>
                    <i class="fa fa-user"></i>
                    <span>User Profile</span>
                  </h3>
                </div>
                <div class="boxHeaderRight">
                </div>
              </div>
              <div class="boxBody">
                <nav class="nav nav-tabs nav-pills w-100">
                  <ul class="zp-tab" id='navList'>
                  <li class="active"><a class="nav-item-1 nav-link nav-text active" data-toggle="tab" href="#UserDetails"><i class="fa fa-user" aria-hidden="true"></i><span class="tab-txt">User Details</span></a></li>
                  <li><a class="nav-item-2 nav-link nav-text " @click="toggleView" data-toggle="tab" href="#Password"><i class="fa fa-unlock-alt" aria-hidden="true"></i><span class="tab-txt">Password & Security</span></a></li>
                  </ul>
                </nav>
                <div id="highcharts-1"></div>
                <div class="tab-content edit-user-page">
                  <div class="tab-pane fade in active" id="UserDetails">
                  <form autocomplete="off" class="tab-content edit-user-page" :initial-values="editProfile">
                    <section class="productsGalleryWrap">
                      <section class="productsGalleryWrapBody">
                        <div id="productsGalleryWrapBodyRow">
                            <div class="profile-form mt-15">
                            <div class="form-group formfield-container">
                                <div class="lbl-bx">                                
                                <label class="edit-lbl">User Name :</label>
                                </div>
                                <div class="field-bx">
                                <input v-model="this.editProfile.userName" class="form-input zp-input" autocomplete="nope" type="text" placeholder="">
                                </div>
                            </div>

                            <div class="form-group formfield-container">
                                <div class="lbl-bx">
                                <label class="edit-lbl">Email :</label>
                                </div>
                                <div class="field-bx">
                                <input v-model="this.editProfile.email" class="form-input zp-input" autocomplete="nope" type="email" placeholder="">
                                </div>
                            </div>

                            <div class="form-group formfield-container">
                                <div class="lbl-bx">
                                <label class="edit-lbl">Phone Number :</label>
                                </div>
                                <div class="field-bx">
                                <input v-model="this.editProfile.phoneNumber" class="form-input zp-input" type="tel" placeholder="">
                                </div>
                            </div>
                            </div>
                          </div>
                      </section>
                    </section>
                    <div class="d-flex" style="float:right"> 
                      <div class="mr-10"><router-link to="/" style="text-decoration: none !important;"><button style="color: #C8C9CE !important; border-radius:5px; padding: 5px 20px 5px 20px; background-color: #272c33; font-size:14px" type="button" title="Cancel">Cancel</button></router-link></div>
                      <div><button @click="this.editUser(this.editProfile)" style=" color: #C8C9CE !important; background-color: #272c33;padding: 5px 20px 5px 20px; font-size:14px;border-radius:5px;" class="editUserBtn" title="Save">Save</button></div>
                  </div>
                  </form>
                  </div>
                  <div class="tab-pane fade in" id="Password">
                    <section class="productsGalleryWrap">
                      <section class="productsGalleryWrapBody">
                        <div id="productsGalleryWrapBodyRow">
                            <div v-for="error in errors" :key="error.id">
                              <span style="color: red">{{typeof error === 'Array' ? error[0] : error}}</span>
                            </div>
                              <span style="color: red" v-if="confirmError">Passwords do not match</span>
                            <div class="profile-form mt-15">
                            
                            <div class="form-group formfield-container">
                              <div class="lbl-bx">
                                <label class="edit-lbl">Old Password :</label>
                              </div>
                              <div class="field-bx">
                                <input v-model="oldPassword" class="form-input zp-input" autocomplete="nope" type="password" placeholder="">
                              </div>
                            </div>

                            <div class="form-group formfield-container">
                              <div class="lbl-bx">
                                <label class="edit-lbl">New Password :</label>
                              </div>
                              <div class="field-bx">
                                <input v-model="password" class="form-input zp-input" autocomplete="nope" type="password" placeholder="">
                              </div>
                            </div>

                            <div class="form-group formfield-container">
                              <div class="lbl-bx">
                                <label class="edit-lbl">Confirm Password :</label>
                              </div>
                              <div class="field-bx">
                                <input v-model="confirmPassword" class="form-input zp-input" autocomplete="nope" type="password" placeholder="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </section>
                    <div class="d-flex" style="float:right"> 
                      <div class="mr-10"><router-link to="/" style="text-decoration: none !important;"><button style="color: #C8C9CE !important; border-radius:5px; padding: 5px 20px 5px 20px; background-color: #272c33; font-size:14px" type="button" title="Cancel">Cancel</button></router-link></div>
                      <div><button @click="editPassword" style=" color: #C8C9CE !important; background-color: #272c33;padding: 5px 20px 5px 20px; font-size:14px;border-radius:5px;" class="editUserBtn" title="Save">Update</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    </div>
  </template>
  
  <script>
import Environment from "../../environment/env.js";
import UserService from "../../_services/userService.js";
  export default {
data() {
  const editProfile = {
    userName:"",
    email:"",
    phoneNumber:"",
         };
  return{
    editProfile,
    oldPassword:"",
    password:"",
    confirmPassword:"",
  };
      },
      mounted() {
        this.getUser();
      },
    methods: {
      getUser() {
            const API_URL = Environment.GET_USER
            const response = UserService.getUser(API_URL);
            response
                .then((res) => {
                  if(res.status==200){
                      this.editProfile.userName = res.data?.userName;
                      this.editProfile.email = res.data?.email;
                      this.editProfile.phoneNumber = res.data?.phoneNumber;
                    } else {
                        this.err = [];
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
      editUser(values) {
            const API_URL = Environment.UPDATE_PROFILE_URL;
            const user = {}
            user.userName = values.userName
            user.email = values.email
            user.phoneNumber = values.phoneNumber
            const response = UserService.editUser( API_URL,user);
            response
                .then((res) => {
                    if (res.data.status) {
                      this.$toast.success(res.data.message, {
                        position: 'top-right'
                      });
                      this.getUser();
                    } else {
                        this.err = [];
                        this.err.push(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
      toggleView(e) {
        if (document.querySelector('#navList a.active') !== null) {
          document.querySelector('#navList a.active').classList.remove('active');
        }
        e.target.className = "active";
      },
      logout() {
        this.$store.dispatch('authentication/logout').then(() => {
          this.$router.push('/auth/login');
          this.$disconnectHub();
        });
    },
      editPassword() {
        const API_URL = Environment.UPDATE_PASSWORD_URL;
        const pass = {}
          pass.oldPassword = this.oldPassword
          pass.password = this.password
          pass.confirmPassword = this.confirmPassword
          const response = UserService.editPassword(API_URL,pass);
          response
          .then((res) => { 
            if(res.status==200){
              this.$toast.success(`Password Updated Successfully`, {
                  position: 'top-right'
                });
              this.logout();
              } else {
                  this.err = [];
                  this.err.push(res.data.message);
              }
          })
          .catch((err) => {
              console.log(err);
          });
      }
    }
  }
  </script>
  <style scoped>
  .form-group > input[type='text'], .form-group > input[type='tel'], .form-group > input[type='email'], .form-group > input[type='password'], .form-group > input[type='file']{
    border: 1px solid #ccc;
    padding: 3px;
    color: #6B7280 !important;
  }
  

  .form-group > input[type=file]:focus{
    outline:none;
  }

  .form-group > input[type='file']{
    text-align: center;
    margin: auto;
  }
   .user-details{
    max-width: 450px;
  }
  .password-security{
    padding-left: 2%;
  } 
.nav-text{
    margin-bottom: 10px;
    color: black ;
    font-size: 14px !important;
    text-decoration: none;
    padding: 5px;
}

.nav-tabs-custom:first-child, .boxWrap .box .boxBody > .nav-tabs:first-child {
    margin: 2px 0 0 0;
}

.edit-user-page{
  height: calc(100vh - 25.29rem) !important;
}
.nav-link .nav-text .active::after{
  font-weight: 600;
}

nav {
  width: 30%;
  padding: 0rem 0.35rem;
  border-radius: 10px;
  font-size: 14px !important;
  background-color: #ffff !important;
}

ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

.profile-form {
  max-width: 541px;
}

.edit-lbl{
  font-weight: 500 !important;
}
  </style>

<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
          >
          </fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "FusionChart1",
  data(){
    return{
      type: 'column2d',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: 'Recommended Portfolio Split',
          subCaption: 'For a net-worth of $1M',
          showValues: '1',
          showPercentInTooltip: '0',
          numberPrefix: '$',
          enableMultiSlicing: '1',
          theme: 'gammel'
        },
        data: [
          {
            label: 'Equity',
            value: '300000'
          },
          {
            label: 'Debt',
            value: '230000'
          },
          {
            label: 'Bullion',
            value: '180000'
          },
          {
            label: 'Real-estate',
            value: '270000'
          },
          {
            label: 'Insurance',
            value: '20000'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
import Vue, { createApp } from 'vue';
import App from './App.vue'
import store from './_store';
import { router, Hub } from './_helpers';
import Environment from "@/environment/env.js";
import { VueCookieNext } from 'vue-cookie-next';



import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import { Toaster } from '@meforma/vue-toaster';
import loader from "vue-ui-preloader";
import Column2D from "fusioncharts/fusioncharts.charts";

VueCookieNext.config({ expire: '30d' })

const app = createApp(App);
app.use(Toaster);
app.use(router)
app.use(VueCookieNext)
app.use(store)
app.use(loader);

app.use(Hub)
app.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el) {
        // Focus the element
        el.focus()
    }
})
app.mount('#app');

app.use(VueFusionCharts, FusionCharts, Charts, TimeSeries, FusionTheme);

app.config.productionTip = false;

app.config.globalProperties.$apiUrl = Environment.APP_BASE_URL;
app.config.globalProperties.$toCelsius = (val) => {
    return (val - 32) * .5556;
};

// Vue.use(Hub)

// Toaster.install(Vue, {})
// const app = Vue.createApp({
//   // options object
// })
//
// app.use(Toaster)

//
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

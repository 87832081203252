<template>
  <div class="col-xs-12 col-md-3">
    <div v-bind:class="'mt-widget ' +color">
      <div class="mt-widgetIcon">
        <i class="fa fa-flag"></i>
      </div>
      <div class="mt-widgetContent">
        <h2>Widget Title</h2>
        <h3>1300</h3>
        <div class="mt-widgetProgress">
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 70%;"></div>
          </div>
          <span>70% Increase in 30 Days</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget",
  props: ['color']
}
</script>

<style scoped>

</style>
<template>
  <div>
    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-folder"></i>
                  <span>Alert Analyzer</span>
                </a>
              </li>
              <li><a href="#">Manager</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">

        <div class="col-xs-12">

        </div>
        <div class="col-xs-12">
          <section class="boxWrap">
            <div class="box">
              <div class="boxHeader">
                <div class="boxHeaderLeft">
                  <h3>
                    <i class="fa fa-shopping-bag"></i>
                    <span>Alert Analyzer
                    </span>
                  </h3>
                </div>
                <div class="boxHeaderRight">
                  <input size="18" class="alerttitle" type="search" v-model="searchQuery" placeholder="Search By Alert Title"
                    aria-label="Search">
                </div>
              </div>
              <div class="boxBody" style=" overflow: scroll;" id="containerBox">
                <div id="highcharts-1"></div>
                <div class="tab-content">
                  <div class="tab-pane fade in active" id="productsManagerGallery-tab">
                    <section class="productsGalleryWrap genalerts-tab">
                      <div class="alers_card">
                        <div class="label card-items" v-for="alert in this.filterGenAlerts" :key="alert?.Id">
                          <h1 @click="this.showDetail(alert)"><img style="height: 15rem; width: 100%;" v-bind:alt="pic" :id="'thumb_' + alert?.thumbImageId">
                          </h1>
                          <div class="d-flex align-items-center justify-content-between p-5">
                            <div class="temp-title white-space-normal text-left pr-10">
                              <span class="d-block">Alert Title : {{alert?.name}}</span>
                              <span>Mean Temperature : {{alert?.estimateTemp/10}}°C</span>
                            </div>
                            <div type="button" class="delete-alert" @click="deleteAlert(alert?.id)" title="Delete">
                              <i class="fa fa-trash"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </section>
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
    <GeneratedAlertInfo v-if="ModalAnalyzerAlert" :alert="this.currentAlert" @cancel="cancelModal(false)" />
  </div>
</template>

<script>
  import VConfirmModal from "../../components/v-confirm-modal"
  import Environment from "../../environment/env";
  import AnalyzerAlertService from "../../_services/AnalyzerAlert";
  import GeneratedAlertInfo from "./GeneratedAlertInfo";
  import _ from "lodash";
    import setActive from "../../mixins/globalFunction.js";
  export default {
    computed: {
      filterGenAlerts() {
        if (this.searchQuery) {
          return this.allGenAlerts.filter(alert => (alert?.name)?.replace(/ +/g, "").toLowerCase().includes(this.searchQuery.replace(/ +/g, "").toLowerCase()))
        } else {
          return this.allGenAlerts
        }
      },
    },
    components: { GeneratedAlertInfo , VConfirmModal },
    mixins: [setActive],
    data() {
      return {
        analyzer: {},
        allGenAlerts: [],
        alertId: "",
        currentAlert: {},
        searchQuery: null,
        ModalAnalyzerAlert: false,
        genAlerts: [],
        modalConfirm: false,
        confirmModalCallback: () => {
        }
      }
    },
    mounted() {
      this.setActive("Alerts");
      this.onAutoGeneratedAlertInfo();
      this.$questionHub.$on('AutoGeneratedAlertInfo', this.getLatestAlert);
    },
    methods: {
      getThumbnailSrc(id) {
        const API_URL = Environment.GET_ALERT_VIDEO_IMAGE + id;
        const response = AnalyzerAlertService.getGenAlertsVideoImage(API_URL);
        response.then(async (res) => {
            this.setThumbnailForAlert('thumb_'+id, res?.data);
        });
      },
      setThumbnailForAlert(id, data){
        document.getElementById(id).src ="data:image/png;base64,"+data;
      },
      onAutoGeneratedAlertInfo() {
        const API_URL = Environment.AUTO_GENERATED_ALERT;
        const response = AnalyzerAlertService.getGenAlerts(API_URL);
        response.then(async (res) => {
          this.allGenAlerts = res.data;
          this.getThumbnailforAlerts(this.allGenAlerts);
        });
      },
      getThumbnailforAlerts(alertArr){
        _.forEach(alertArr, (alert) => {
            this.getThumbnailSrc(alert?.thumbImageId);
        });
      },
      getLatestAlert(newAlert){
        this.allGenAlerts.push(JSON.parse(newAlert?.data));
        this.getThumbnailSrc(JSON.parse(newAlert?.data).thumbImageId);
      },
      confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.onAutoGeneratedAlertInfo();
      }
    },
      deleteAlert(alertId) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
        var alert = [];
        alert?.push(alertId.toString())
            var API_URL = Environment.DELETE_GENERATED_ALERTS;
            const response = AnalyzerAlertService.deleteGeneratedAlert(API_URL,alert);
            response
                .then((res) => {
                    if(res) {
                        this.confirmModalCheck(false);
                        this.$toast.success(`Alert Deleted Successfully !`, {
                          position: 'top-right'
                        });
                        this.onAutoGeneratedAlertInfo();

                    } else {
                        this.err = [];
                        this.err.push(res.data.message);
                    }
            })
            .catch((err) => {
                console.log(err);
            });  
        } 
        },
      showDetail(alert) {
        this.currentAlert = alert;
        this.ModalAnalyzerAlert = true;
      },
      cancelModal() {
        this.ModalAnalyzerAlert = !this.ModalAnalyzerAlert;
      },
    }
  };
</script>
<style>
  .alers_card {
    background: white;
    padding: 0;
    grid-template-columns: repeat(3, 33.33%);
    display: grid;
  }

  .label {
    border: 1px solid #86bce5;
    padding: 10px 0px;
  }

  .temp-title {
    color: black;
    font-size: 15px;
    float: left;
    font-weight: 400;
  }

  .delete-alert {
    color: red;
    font-size: large;
  }

  .card-items {
    border-radius: 0.25em;
    margin: 10px 10px 10px 10px !important;
    padding: 0 !important;
  }

  .current-page-number {
    font-size: 20px;
    padding: 10px;
    border: 1px solid lightblue;
    border-radius: 5px;
  }

  .d-block {
    display: block !important;
  }

  .genalerts-tab {
    height: calc(100vh - 25.29rem) !important;
    box-shadow: 0px 1px 2px 0px lightblue;
  }

  .alerttitle{
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 2px;
  background-color: transparent;
  margin-left: 3px;
  font-size: 0.875em;
  padding-left: 10px;
}
</style>
<template>
  <div class="tab-content">
    <div class="tab-pane fade in active" id="productsManagerGallery-tab">
      <section class="productsGalleryWrap">
        <section class="productsGalleryWrapHeader p-0">
          <div class="productsGalleryWrapHeaderLeft">
            <div>
            </div>
          </div>
        </section>
        <section class="productsGalleryWrapBody">
          <div class="row" id="productsGalleryWrapBodyRow">
            <div v-for="(item, i) in devices.results" v-bind:key="item.id">
              <div class="productsGalleryItem col-xs-12 col-sm-4 col-md-4">
                <div class="productsGalleryItemIn position-static">
                  <div class="productsGalleryItemInside position-static">
                    <div class="productsGalleryItemImg flex-center position-static w-100" :ref="el => {  liveVideosContainer[i] = el }">
                      <LiveVideo @actButton="actButton" @candidate="onCandidate" :session-id="item.id + 'streamPage'" :id="item.id" :edit_mode=false  :ref="el => { if (el) el.key = item.id; liveVideos[i] = el }" />
                    </div>
                    <div class="productsGalleryItemInfo position-static d-flex justify-content-between">
                      <div class="productsGalleryItemInfoLeft">
                       <div class="d-flex justify-content-between align-item-center"><p class="signal green"></p> <span class="devName" :title="item.name">{{ item.name }}</span></div>
                        
                      </div>
                      <div v-if="aButton" class="productsGalleryItemInfoRight btn-align position-static">
                        <div class="btn-group btn-group">
                          <button data-target="#productEditModal" class="btn btn-default btn-flat" title="Record"><i class="fa fa-circle fa-color" aria-hidden="true"></i></button>
                          <button  @click="this.addAlertModalCheck(true,item.id,false)" data-target="#productEditModal" class="btn btn-info btn-flat" title="view"><i class="fa fa-eye"></i></button>
                          <button  @click="this.addAlertModalCheck(true,item.id,true)"  data-target="#productEditModal" class="btn btn-info btn-flat" title="Add"><i class="fa fa-plus"></i></button>                          
                          <button @click="editItem(item)" data-target="#productEditModal" class="btn btn-default btn-flat" title="Edit"><i class="fa fa-pencil"></i></button>
                          <!-- <button  @click="deleteItem(item)" class="btn btn-danger btn-flat last-child" title="Delete"><i class="fa fa-trash-o"></i></button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
    <!--  Modal options -->
    <AnalyzerModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
    <FilterModal :id="edit_id" v-if="modalFilter" @cancel="filterModalCheck(false)"/>
    <!--  Modal options -->
    <!--  Modal Confirm -->
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
    <AlertManageModal v-if="modalAlertManage" :deviceId="deviceId" :isShow="isShow" @cancel="addAlertModalCheck(false)"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AnalyzerModel from "./AnalyzerModal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
import AlertManageModal from "../alerts/AlertManageModal.vue"
import LiveVideo from "./LiveVideo";
import FilterModal from "./FilterModal";
import {onBeforeUpdate, ref} from "vue";
import {StreamsectionMixin} from "@/mixins/stream/streamsection.mixin";
export default {
  components: { VConfirmModal, VVideoModal, LiveVideo, AnalyzerModel, FilterModal, AlertManageModal},
  data() {
    return{
      deviceId:null,
      modalAdd: false,
      modalFilter: false,
      isShow:false,
      modalConfirm: false,
      edit_mode: false,
      aButton:false,
      edit_id: null,
      numberOfCandidates: 0,
      modalAlertManage: false,
      confirmModalCallback: () => {
      }
    }
  },
  setup() {
    const liveVideos = ref([]);
    const liveVideosContainer = ref([]);
    // Make sure to reset the refs before each update.
    onBeforeUpdate(() => {
      liveVideos.value = [];
      liveVideosContainer.value = [];
    });
    return{
      liveVideos,
      liveVideosContainer,
    }
  },
  computed: {
    devices () {
      return this.$store.state.device.all;
    },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  created () {
    this.$store.dispatch('device/getAll');
  },
  mounted() {
    console.log(this.$store.state)
  },
  methods: {
    actButton(flag){
      this.aButton = flag;
    },
    addAlertModalCheck(open,deviceId,isDisplay){
      this.deviceId = deviceId
      this.modalAlertManage = open;
      this.isShow = isDisplay;
      if (!this.modalAlertManage) {
        this.loadingList = true;
      }
    },
    onCandidate(data) {
      this.numberOfCandidates = data;
    },
    addModalCheck(open){
      this.modalAdd = open;
      if (!this.modalAdd) {
        this.$store.dispatch('device/getAll');
      }
    },
    filterModalCheck(item, open){
      this.modalFilter = open;
      if (!this.modalFilter) {
        this.$store.dispatch('device/getAll');
        this.edit_id = null;
      } else {
        this.edit_id = item.id;
      }
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {
        }
        this.$store.dispatch('device/getAll');
      }
    },
    // deleteItem(item) {
    //   this.confirmModalCheck(true);
    //   this.confirmModalCallback = () => {
    //     this.$store.dispatch('device/remove',  [item.id]).then(result => {
    //       this.confirmModalCheck(false);
    //     });
    //   }
    // },
    addItem() {
      this.edit_mode = false;
      this.addModalCheck(true);
    },
    editItem(item) {
      this.edit_mode = true;
      this.edit_id = item.id;
      this.addModalCheck(true);
    },
    // syncItem(item) {
    //   this.$store.dispatch('device/sync',  [item.id]).then(result => {
    //     this.$toast.success(`settings synced`, {
    //       position: 'top-right'
    //     });
    //   });
    // }
  },
  mixins: [StreamsectionMixin],
  watch: {
  },
};
</script>
<style scoped>
.position-static{
  position:static !important;
}
.btn-align{
    display: inline-grid;
    align-items: center;
}
.last-child{
    margin-right: 3px !important;
  }
.btn-group > button {
  margin: 0 10px;
}
.flex-center{
  display: flex !important;
  align-items: center;
}
  .productsGalleryItemIn{
    background-color: initial !important;
  }
  .productsGalleryItem{
    width: 40% !important;
    right: 0;
    height: auto;
  }
.productsGalleryWrap .productsGalleryWrapBody .productsGalleryItem .productsGalleryItemIn .productsGalleryItemInside{
  border: 0;
}
.productsGalleryWrap .productsGalleryWrapBody .productsGalleryItem .productsGalleryItemIn .productsGalleryItemInside .productsGalleryItemImg{
  border: 0;
}
  .productsGalleryItemInfoRight{
    position: initial !important;
  }
  .productsGalleryItemInfo{
    height: 0px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .productsGalleryItemInfoLeft{
    position: static !important;
  }
  .btn-group{
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
  }
  .productsGalleryItemInfoRight:before, .productsGalleryItemInfoRight:after{
    display: none !important;
  }
  .message{
    width: 10px;
  }
  .signal{
    position: relative;
    width: max-content;
    padding-right: 20px;
    align-items: center;
    gap: 8px;
  }
  .signal:before{
    background-color: green !important;
    content: '';
    position: relative;
    right: auto;
    left: 5px;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-flex;
  }
  .red:after{
    background-color: red;
  }
  .yellow:after{
    background-color: #d09730;
  }
.green:after{
  background-color: #1a8720;
}
.text-wrap {
  word-wrap: break-word;
}
.devName {
    width: 13rem;
    overflow: hidden;
    white-space: nowrap;  
    text-overflow: ellipsis;
}
.fa-color {
  color:red;
}
.Rec{
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
</style>
<template>

  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Polygon</h3>
      </div>
      <video autoplay width="0" height="0" controls  :src="videoSource" @play="sourcePlay" @playing="sourcePlaying" @loadedmetadata="metadataLoaded" id="video">
        Your browser does not support the video tag.
      </video>
      <div class="forms-block">
        <h3>Heading Text</h3>
        <textarea name="" id="" cols="30" rows="10">{{JSON.stringify(perimeter)}}</textarea>

        <div class="options-block">
          <label>name:</label>
          <input class="form-control" v-model="name">
        </div>
      </div>

      <canvas :width="sourceCanvasWidth" :height="sourceCanvasHeight"  style="cursor:crosshair" @mousedown="point_it($event)" @mousemove="move_it($event)" @contextmenu="contMenu" ref="canRef" id="canvas"></canvas>



      <div class="footer">
        <div class="list">
<!--          <span>People: 7</span>-->
          <span>Position-X: {{xPos}}</span>
          <span>Position-Y: {{ yPos }}</span>
        </div>
        <button @click="undo" class="btn btn-warning btn-flat" v-if="perimeter.length > 0">{{ (perimeter.length > 0 ? 'Undo' : '') }}</button>
        <button @click="startSelectPolygon" v-show="!polygonSelectStarted && perimeter.length === 0" class="btn btn-success btn-flat">Start Selecting</button>
        <button @click="stopSelectPolygon" v-show="polygonSelectStarted" class="btn btn-danger btn-flat">Stop Selecting</button>
        <button @click="submit" v-show="!polygonSelectStarted && (end || complete)" class="btn btn-success btn-flat">{{ (item.id ? 'Edit' : 'Add') }}</button>
        <!--        <button class="btn btn-info btn-flat" @click="undo">Undo</button>-->
<!--        <button class="btn btn-info btn-flat" @click="clear">Clear</button>-->
        <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    }
  },
  name: "v-options-modal",
  data() {
    return{
      polygonSelectStarted: false,
      xPos: 0,
      yPos: 0,
      sourceCanvasWidth: 1000,
      sourceCanvasHeight: 600,
      videoWidth: 640,
      videoHeight: 480,
      name: '',
      url: '',
      iter: -1,
      item: {},
      perimeter: [],
      video_draw: false,
      complete: false,
      ctx: {},
      end: false,
      canvas: document.createElement("canvas"),
      sourceItem: {},
      videoSource: '',
      dataUrl: null,
      items: [
        { id: 1, src: 'https://raw.githubusercontent.com/matteomattei/jPolygon/master/image.jpg' },
      ]
    }
  },
  methods:{
    startSelectPolygon() {

      this.polygonSelectStarted = true;
    },
    stopSelectPolygon() {
      if(this.perimeter.length <= 2) {
        alert('You need at least three points for a polygon');
        return false;
      }

      this.polygonSelectStarted = false;
      this.end = true;
      this.draw(this.end);
    },
    sourcePlay(e) {
      let self = this;
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      var ctx = this.ctx;
      var draw = this.draw;
      var end = this.end;
      var iter = this.iter;
      var video_draw = this.video_draw;
      var $this = e.target; //cache
      (function loop() {
        if (!$this.paused && !$this.ended) {
          // if (!video_draw)
          ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);

          draw();
          video_draw = true;
          iter = iter + 1;
          setTimeout(loop, 1000 / 30); // drawing at 30fps
        }
      })();
      console.log('source playing', e)
    },
    sourcePlaying(e) {
      console.log('source playing', e)
    },
    metadataLoaded(e) {
      this.videoWidth = e.target.videoWidth;
      this.videoHeight = e.target.videoHeight;
      this.sourceCanvasHeight = (this.sourceCanvasWidth * this.videoHeight) / this.videoWidth;
    },
    contMenu() {
      return false;
    },
    point_it(event) {
      if (!this.polygonSelectStarted)
        return;

      if(this.complete){
        // alert('Polygon already created');
        return false;
      }
      var rect, x, y;
     {
        rect = this.canvas.getBoundingClientRect();
       x = (event.clientX - rect.left - 8); // + rect.left
       y = (event.clientY - rect.top - 8); // + rect.top
        if (this.perimeter.length>0 && x === this.perimeter[this.perimeter.length-1]['x'] && y === this.perimeter[this.perimeter.length-1]['y']){
          // same point - double click
          return false;
        }
        if(this.check_intersect(x,y)){
          alert('The line you are drowing intersect another line');
          return false;
        }
        this.perimeter.push({'x':x,'y':y});
        this.draw(false);
        return false;
      }
    },
    move_it(event) {
      if (!this.canvas)
        return;
        let rect = this.canvas.getBoundingClientRect();
      this.xPos = (event.clientX - rect.left - 8); // + rect.left
       this.yPos = (event.clientY - rect.top - 8); // + rect.top

    },
    undo() {
      this.perimeter.pop();

      this.end = false;
      this.complete = false;
      if (this.perimeter.length) {
        this.polygonSelectStarted = true;
      }
      // if (this.perimeter.length <= 2) {
      // }
      // this.polygonSelectStarted = false;
      // this.start(true);
    },
    clear() {
      this.ctx = undefined;
      this.perimeter = new Array();
      this.complete = false;
      //document.getElementById('coordinates').value = '';
      this.start();
    },
    start(with_draw) {

      const canvas = document.createElement("canvas");
      this.ctx = canvas.getContext('2d');
      // this.ctx = this.$refs.canRef;
      if (with_draw)
        this.draw(false);

    },
    cancel(){
      this.$emit('cancel')
    },
    draw(end){
      if (typeof end === 'undefined')
        end = this.end;
      console.log(this.end, this.complete);
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = "white";
      this.ctx.lineCap = "square";
      this.ctx.beginPath();
      for(let i=0; i<this.perimeter.length; i++){
        if(i===0){
          this.ctx.moveTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
          end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
        } else {
          this.ctx.lineTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
          end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
        }
      }
      if(end ){
        this.complete = true;
      }
      if (end || this.complete) {
        this.ctx.lineTo(this.perimeter[0]['x'],this.perimeter[0]['y']);
        this.ctx.closePath();
        this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        this.ctx.fill();
        this.ctx.strokeStyle = 'blue';
      }
      this.ctx.stroke();

      // print coordinates
      if(this.perimeter.length === 0){
        // document.getElementById('coordinates').value = '';
      } else {
        //document.getElementById('coordinates').value = JSON.stringify(perimeter);
      }
    },
    check_intersect(x,y){
      if(this.perimeter.length < 4){
        return false;
      }
      var p0 = new Array();
      var p1 = new Array();
      var p2 = new Array();
      var p3 = new Array();

      p2['x'] = this.perimeter[this.perimeter.length-1]['x'];
      p2['y'] = this.perimeter[this.perimeter.length-1]['y'];
      p3['x'] = x;
      p3['y'] = y;

      for(let i=0; i<this.perimeter.length-1; i++){
        p0['x'] = this.perimeter[i]['x'];
        p0['y'] = this.perimeter[i]['y'];
        p1['x'] = this.perimeter[i+1]['x'];
        p1['y'] = this.perimeter[i+1]['y'];
        if(p1['x'] === p2['x'] && p1['y'] === p2['y']){ continue; }
        if(p0['x'] === p3['x'] && p0['y'] === p3['y']){ continue; }
        if(this.line_intersects(p0,p1,p2,p3)===true){
          return true;
        }
      }
      return false;
    },

    line_intersects(p0, p1, p2, p3) {
      let s1_x, s1_y, s2_x, s2_y;
      s1_x = p1['x'] - p0['x'];
      s1_y = p1['y'] - p0['y'];
      s2_x = p3['x'] - p2['x'];
      s2_y = p3['y'] - p2['y'];

      let s, t;
      s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
      t = ( s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);

      if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
      {
        // CollisSelectingion detected
        return true;
      }
      return false; // No collision
    },
    point(x, y){
      if (!this.complete) {
        this.ctx.fillStyle="white";
        this.ctx.strokeStyle = "white";
      } else {
      }
      this.ctx.fillRect(x-2,y-2,4,4);
      this.ctx.moveTo(x,y);
    },
    drawTest() {
      var video = document.getElementById('video');

      if (!video)
        return;
// set canvas size = video size when known
      video.addEventListener('loadedmetadata', () => {
        // canvas.width = video.videoWidth;
        // canvas.height = video.videoHeight;
        // this.start(false);
      });

      // video.addEventListener('play', function() {
      //   var $this = this; //cache
      //   (function loop() {
      //     if (!$this.paused && !$this.ended) {
      //       // if (!video_draw)
      //         ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);
      //
      //       draw(end);
      //       video_draw = true;
      //       iter = iter + 1;
      //       setTimeout(loop, 1000 / 30); // drawing at 30fps
      //     }
      //   })();
      // }, 0);
    },
    submit() {
      let data = {
        deviceId: this.$route.params.deviceId,
        lines: JSON.stringify(this.perimeter),
        name: this.name
      };
      if (!this.edit_mode) {
        this.$store.dispatch('polygon/add', data).then(data => {
          this.cancel();
        });
      } else {
        data.id = this.item.id;
        this.$store.dispatch('polygon/edit', data).then(data => {
          this.cancel();
        });
      }
    },
  },
  mounted() {
    if (this.edit_mode) {
      console.log('edit polygon: ', this.id)
      this.$store.dispatch('polygon/get', this.id).then(response => {
        this.item = response;
        this.name = response.name;
        this.perimeter = JSON.parse(response.lines);
        this.end = true;
      });
    } else {
      console.log('add polygon')
    }
    let self = this;
    this.$store.dispatch('source/get', this.$route.params.deviceId).then(response => {
      self.sourceItem = response;
      self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
      self.drawTest();
    })
  },
  watch: {
    videoSource : (a) => {
      console.log(a)
    },
    perimeter: function (val) {
      if (this.perimeter.length) {
        // this.polygonSelectStarted = true;
      }
    }
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
/*.content > canvas{*/
/*  width: 640px;*/
/*  height: 320px;*/
/*}*/
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
</style>
<template>
  <div class="modal-options">
      <div class="content">
        <div class="header panel-heading">
          <h3>header</h3>
        </div>
        <div class="body">
          <div class="form-group">
            <label>Device Name</label>
            <input type="text" placeholder="device name here...">
          </div>
          <div class="form-group">
            <label>Device Identifier (Device Key)</label>
            <input type="text" placeholder="">
          </div>

          <h3>Radios block</h3>
          <div class="form-group inline-group">
            <div class="item-block">
              <label>Radio</label>
              <input type="radio" name="radio" placeholder="">
            </div>
            <div class="item-block">
              <label>Radio</label>
              <input type="radio" name="radio" placeholder="">
            </div>
            <div class="item-block">
              <label>Radio</label>
              <input type="radio" name="radio" placeholder="">
            </div>
          </div>

          <h3>Checkbox block</h3>
          <div class="form-group inline-group">
            <div class="item-block">
              <label>Checkbox</label>
              <input type="checkbox" placeholder="">
            </div>
            <div class="item-block">
              <label>Checkbox</label>
              <input type="checkbox" placeholder="">
            </div>
            <div class="item-block">
              <label>Checkbox</label>
              <input type="checkbox" placeholder="">
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="btn btn-info btn-flat">Accept</button>
          <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "v-options-modal",
  methods:{
    cancel(){
      this.$emit('cancel')
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 300px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}
.form-group > input[type='text']{
  width: 100%;
  border: 1px solid #ccc;
  padding: 3px;
}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > * {
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

</style>
import { authHeader } from '@/_helpers';
import handleResponse from '../_services/shared';

export const notificationRepository = {
    add,
    getAll,
    getAllUnread,
    get,
    edit,
    markRead,
    remove,
};

function add(data) {
    console.log('add', 'notification')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/notification/add`, requestOptions)
        .then(handleResponse);
}

function edit(data) {
    console.log('edit', 'notification')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/notification/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function markRead(data) {
    console.log('mark read', 'notification')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/notification/markread`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(data) {
    console.log('remove', 'notification')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/notification/delete`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/notification/${data}`, requestOptions).then(handleResponse);
}

function getAll() {
    console.log('getAll', 'notifications')
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/notification?PageSize=1000`, requestOptions).then(handleResponse);
}


function getAllUnread() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/notification?Read=0&PageSize=1000`, requestOptions).then(handleResponse);
}

<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
          ></fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FusionChart6",
  data(){
    return{
      type: "mssplinearea",
      width: "100%",
      height: "400",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Twitter Mentions",
          yaxisname: "Number of mentions",
          numbersuffix: "M",
          subcaption: "(iPhone Vs Samsung)",
          yaxismaxvalue: "2",
          plottooltext:
              "$seriesName was mentioned <b>$dataValue</b> times on Twitter in $label",
          theme: "gammel"
        },
        categories: [
          {
            category: [
              {
                label: "2007"
              },
              {
                label: "2008"
              },
              {
                label: "2009"
              },
              {
                label: "2010"
              },
              {
                label: "2011"
              },
              {
                label: "2012"
              },
              {
                label: "2013"
              },
              {
                label: "2014"
              },
              {
                label: "2015"
              }
            ]
          }
        ],
        dataset: [
          {
            seriesname: "iPhone",
            data: [
              {
                value: "1.90"
              },
              {
                value: "1.94"
              },
              {
                value: "1.69"
              },
              {
                value: "1.66"
              },
              {
                value: "1.43"
              },
              {
                value: "1.97"
              },
              {
                value: "1.78"
              },
              {
                value: "1.58"
              },
              {
                value: "1.55"
              }
            ]
          },
          {
            seriesname: "Samsung",
            data: [
              {
                value: "0.68"
              },
              {
                value: "0.74"
              },
              {
                value: "0.25"
              },
              {
                value: "0.64"
              },
              {
                value: "0.22"
              },
              {
                value: "0.74"
              },
              {
                value: "0.58"
              },
              {
                value: "0.15"
              },
              {
                value: "0.26"
              }
            ]
          }
        ]
      }
    }
  },
}
</script>

<style scoped>

</style>
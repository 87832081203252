<template>
  <div class="w-100" style="position:relative;">
    <video autoplay="true" class="w-100" :width="width" :height="height" controls :srcObject.prop="sourceObject" @loadeddata="loadVideo" @play="sourcePlay" @pause="sourcePause" @playing="sourcePlaying" @loadedmetadata="metadataLoaded">
      Your browser does not support the video tag.
    </video>
    <!-- <div title="Record video" v-if="rec && !edit_mode" id="recButton" class="notRec" @click="recordVideo(this.sourceObject)"></div> -->
  </div>
</template>

<script>
import {mapState} from "vuex";
import RecordRTC from "recordrtc";

export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    },
    sessionId: {
      type: String
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  name: "v-options-modal",
  data() {
    return{
      sourceObject: null,
      name: '',
      url: '',
      rec: false,
      file: null,
      file_id: null,
      item: {},
      recorder :{},
      recording: false,
      webRtcPeer: null,
      started: false,
      uploadPercentage: 0,
      numberOfCandidates: 0,
    }
  },
  methods:{
    sourcePlay: function(val) {
      this.$emit('play', val);
    },
    loadVideo: function(val) {
      this.rec = true;
      this.$emit('actButton','true');
    },
    sourcePlaying: function(val) {
      this.$emit('playing', val);
    },
    metadataLoaded: function(val) {
      this.$emit('loadedmetadata', val);
    },
    getSourceObject(hubState, sourceObject) {
      return hubState === 'Connected' ? sourceObject : null;
    },
    onIceCandidate(event) {
      console.log(`local candidate: ${JSON.stringify(event.candidate)}`);

      if (event.candidate == null) {
        console.log('null candidate');
        return;
      }

      var message = {
        id: this.id.toString(),
        token: this.user.jwt_token,
        sessionId: this.sessionId + this.user.jwt_token,
        candidate: event.candidate.candidate
      }

      this.$sendCommand('VideoCandidate', message);
    },
    onLocalOfferCreated(err, sdpOffer) {
      if(err){
        console.error(err);
      }

      //
      // remove all other rtp profiles and request only h264
      //
      let split = sdpOffer.sdp.split('a=rtpmap:96 VP8/90000');
      var h264Sdp = split[0] + "a=rtpmap:96 H264/90000\n";
      sdpOffer.sdp = h264Sdp;

      this.webRtcPeer.setLocalDescription(sdpOffer);

      var message = {
        id: this.id.toString(),
        token: this.user.jwt_token,
        sessionId: this.sessionId + this.user.jwt_token,
        sdpOffer: sdpOffer.sdp
      }

      console.log('sending local sdp offer');
      this.$sendCommand('VideoData', message)
    },
    sdpOfferArrived(received) {

      var data = received.data;
      if (data.sessionId === this.sessionId + this.user.jwt_token) {
        let desc = new RTCSessionDescription({ type: 'answer', sdp: data.sdp });
        this.webRtcPeer.setRemoteDescription(desc);
      }
    },
    iceCandidateArrived(received) {
      var data = received.data;
      if (data.sessionId === this.sessionId + this.user.jwt_token) {
        console.log(`ice can received ${typeof received}`);
        let candidate = data.candidate;
        this.numberOfCandidates += 1;
        this.$emit('candidate',this.numberOfCandidates);
        if(navigator.userAgent.includes("Firefox")){
          this.webRtcPeer.addIceCandidate(candidate);
        } else {
          this.webRtcPeer.addIceCandidate(candidate, function(err){
          if(err){
            console.error('ERROR: ' + err);
          }
        });
        }
      }
    },
    stateArrived(state) {
      this.hubSate = state;
    },
    startLive() {
        this.webRtcPeer = null;
        console.log('Creating WebRtcPeer and generating local sdp offer ...');

        //
        // Create the webRtcPeer instance
        //
      this.webRtcPeer = new RTCPeerConnection();

      this.webRtcPeer.onicecandidate = this.onIceCandidate;
      this.webRtcPeer.onopen = () => console.log('real time connection has established');
      this.webRtcPeer.onerror = (err) => console.log(`real time connection error ${err}`);
      this.webRtcPeer.onaddstream = (event) => {
          console.log('source object arrived', event)
        this.sourceObject = event.stream;
        }

      this.webRtcPeer.createOffer((offer) => this.onLocalOfferCreated(null, offer), (err) => this.onLocalOfferCreated(err, null), { offerToReceiveAudio: 0, offerToReceiveVideo: 1 });
      },
    recordVideo(stream) {
      this.recording = !this.recording;
      if (this.recording) {
        this.recorder = RecordRTC(stream, {
          type: 'video'
        });
        this.recorder.startRecording();
        if (document.getElementsByClassName('recButton').classList.contains('notRec')) {
          document.getElementsByClassName('recButton').classList.remove("notRec");
          document.getElementsByClassName('recButton').classList.add("Rec");
        }
      }
      else if (!this.recording) {
        if (document.getElementsByClassName('recButton').classList.contains('Rec')) {
          document.getElementsByClassName('recButton').classList.remove("Rec");
          document.getElementsByClassName('recButton').classList.add("notRec");
        }
        this.recorder.stopRecording(() => {
          let blob = this.recorder.getBlob();
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "test";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
    }
  },
  computed: {
    ...mapState({
      hubState: state => state.app.hubState,
      user: state => state.authentication.user,
    })
  },
  mounted() {
    this.$questionHub.$on('sdp-offer', this.sdpOfferArrived)
    this.$questionHub.$on('ice-candidate', this.iceCandidateArrived)
    this.$questionHub.$on('state', this.stateArrived)

    if (this.hubState === 'Connected') {
      this.startLive();
    }
  },
  unmounted() {
    this.$questionHub.$off('sdp-offer', this.sdpOfferArrived)
    this.$questionHub.$off('ice-candidate', this.iceCandidateArrived)
    this.$questionHub.$off('state', this.stateArrived)
  },
  watch: {
    hubState: function(val) {
      if (val === 'Connected') {
        this.startLive();
      }
    }
  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 400px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}
.body{
  padding: 8px 35px;
}
.form-group > input[type='text']{
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > * {
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

#recButton {
	width: 13px;
	height: 13px;
	font-size: 0;
  bottom: 41px;
  left: 100px;
	background-color: red;
	border: 0;
	border-radius: 35px;
	margin: 0px;
	outline: none;
  position: absolute;
}

.notRec{
	background-color: darkred;
}

.Rec{
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
#recButton{
  display: none;
}

@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

video::-webkit-media-controls-volume-slider {
  display: none !important;
}

video::-webkit-media-controls-mute-button {
  display: none !important;
}

video::-webkit-media-controls-volume-mute-indicator {
  display: none !important;
}

video::-webkit-media-controls-volume-slider-container {
  display: none !important;
}

video::-webkit-media-controls-timeline {
  display: none !important;
}

video::-webkit-media-controls-current-time-display {
  display: none !important;
}

video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

video::-webkit-media-controls-overflow-button {
  display: none !important;
}

video::-webkit-media-controls-zoom-button {
  display: none !important;
}

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

video::-moz-media-controls-zoom-button {
  display: none !important;
}

video::-internal-media-controls-overflow-button {
  display: none !important;
}

video::-webkit-media-controls-enclosure:not(.fullscreen)::-webkit-media-controls-picture-in-picture-button {
  display: none !important;
}

video::-internal-media-controls-button-hover-background {
  display: none !important;
}

video::-moz-media-controls-playpause {
  display: none !important;
}

video::-moz-media-controls-mute-button {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

video::-moz-media-controls {
  display: none !important;
}
</style>

<template>
  <section class="loginSection">
    <section class="boxWrap justify-content-center mt-4 overflow-x-hidden row">
      <div class="box col-10 bg-white p-50 rounded-20">
        <div class="boxHeader d-flex justify-content-center mb-4">
          <div class="d-flex justify-content-center">
              <div class="d-flex justify-content-center">
              <img class="brand-logo" src="@/assets/img/full-color-logo-dark.png" alt="">
              </div>
          </div>
        </div>
        <form @submit.prevent="handleSubmit">
        <div class="boxBody pb-0">
          <div class="row">
            <div class="col-xs-12 px-0">
              <div class="form-group mb-4">
                <label for="username" class="form-label text-col fw-bold">Username:</label>
                <input class="form-control pr-45 input-border-radius" autocomplete="off" @keyup="validateField('username')" v-model="username" name="username" :class="{ 'is-invalid': error['username'] }" type="text" id="username"  />
                <div v-show="error['username']" class="invalid-feedback red">{{ error['username'] }}</div>
              </div>
              <div class="form-group mb-4">
                <label for="password" class="form-label text-col fw-bold">Password:</label>
                <input type="password" @keyup="validateField('password')" autocomplete="off" v-model="password" name="password" class="form-control pr-45 input-border-radius" id="password" :class="{ 'is-invalid': error['password'] }"  />
                <div v-show="error['username']" class="invalid-feedback red">{{ error['password'] }}</div>
              </div>
                <div class="mb-2">
                    <input type="checkbox" class="form-check-input mr-8" id="rememberMe"/>
                    <label class="form-check-label text-col fw-bold" for="exampleCheck1">Remember Me
                  </label>
                </div>
                <div v-if="errors.length">
                  <div>
                    <ul>
                      <li v-for="error in errors" v-bind:key="error.id" class="msg-err">
                        <div class="invalid-feedback red">{{ error }}</div>
                     </li>
                    </ul>
                </div>
              </div>
          </div>
        </div>
        </div>
        <div class="boxFooter d-flex flex-column p-0">
            <button type="submit" :disabled="loggingIn" class="btn btn-flat color-2-bg color-2-hover-bg color-text-white w-100 fs-18 input-border-radius">Login</button>
            <button class="btn bg-transparent input-border-radius"><router-link :to="'/auth/demo'">Ask for Demo</router-link></button>
        </div>
        </form>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationMixin } from '../../mixins/validation.mixin'
export default {
    data () {
        return {
          username: '',
          password: '',
          validationRules: {
              'username': {
                validate: () => {
                  if (!this.username)
                    this.error['username'] = 'username is required'
                  return !!this.username;
                }
              },
              'password': {
                validate: () => {
                  if (!this.password)
                    this.error['password'] = 'password is required'
                  return !!this.password;
                }
              }
          }
        }
    },
  mixins: [ValidationMixin],
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
      ...mapState({
        errors: state => state.authentication.errors,

      })
    },
    mounted() {
    let isRememberMe = this.$cookie.getCookie("isRemeberMe");
      if (JSON.parse(isRememberMe)) {
        this.username = this.$cookie.getCookie("userEmail");
        this.password = this.$cookie.getCookie("userPassword");
        console.log(this.username)
        document.getElementById("rememberMe").checked = true;
      }
    },
    created () {
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        rememberMe(username,password) {
          let rememberMe = document.getElementById("rememberMe");
          if (rememberMe.checked && username != "" && password != "") {
            this.$cookie.setCookie("isRemeberMe", rememberMe.checked);
            document.getElementById("rememberMe").checked = true;
            this.$cookie.setCookie("userEmail", username);
            this.$cookie.setCookie("userPassword", password);
          }
          else{
            this.$cookie.setCookie("isRemeberMe",rememberMe.unchecked);
            this.$cookie.setCookie("userEmail","");
            this.$cookie.setCookie("userPassword","");
          }
        
        },
        handleSubmit (e) {
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (!this.error['username'] && !this.error['password']) {
                dispatch('authentication/login', { username, password }).then(data => {
                  this.rememberMe(this.username,this.password);
                }, error => {
                });
            }
        }
    }
};
</script>
<style scoped>
.boxHeaderLeft > h3{
  display: flex;
  align-items: center;
}
.boxHeaderLeft > h3 > img{
 width: 100px;
  float: left;
}
.boxFooter > button:last-child{
  float: right;
}
.boxFooter{
  display: flex;
  grid-template-columns: .1fr 20fr 20fr .1fr;
  grid-gap: 1em;
}
.boxFooter a{
  color: #6c7ebb;
  text-decoration: underline;
}
  .boxFooter > button:last-child{
    border-radius: 0;
    float: right;
  }
  .boxFooter:before{
    display: none !important;
  }
.msg-err{
  color: darkred;
  list-style: none !important;
  font-size: 13px;
}

.input-border-radius {
  border-radius: 0.375rem !important;
}

.text-col {
    color: #262626;
}

.brand-logo {
    width: 40%;
}

.pr-45 {
    padding-right: 45px;
}

.mr-8{
  margin-right: 8px !important;
}
.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}

.mtSidebarOpen {
    padding-left: 0px !important;
}

.image-banner{
    display: flex !important;
    justify-content: center !important;
}

.rm-margin{
  margin-left: -240px !important;
}
</style>
<template>
  <div class="modal-options">
    <div class="content">
      <div class="header panel-heading">
        <h3>Video and buttons play and stop</h3>
      </div>
      <div class="body">
        <video width="100%" height="100%" controls>
          <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
          <track label="English" kind="captions" srclang="en" default>
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="footer">
        <button class="btn btn-info btn-flat">Play</button>
        <button class="btn btn-danger btn-flat">Stop</button>
        <button class="btn btn-info btn-flat" @click="cancel">Exit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-video-modal",
  methods:{
    cancel(){
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 300px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
</style>
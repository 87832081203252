<template>
  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading d-flex justify-content-between">
        <h3 v-if="isShow">Node Editor</h3>
        <h3 v-else>Node View</h3>
        <button class="btn btn-flat btn-flat close"  title="Close" @click="this.cancel">&#x2715;</button>
      </div>
      <div class="alert-manage-modal" style="display:flex;">
      <div class="canvas-bx">
    <PolygonManageModal :deviceId="deviceId" :hideButton="isShow" @changeActivePolygon="changeActivePolygon" @deletePolygon="onDeletePolygon" @cancel="polygonModel(false)"/>
      </div>
      <div class="d-flex flex-column">
      <div style="padding-top: 1rem;" class="d-flex flex-1 flex-column polygon-frm">
        <div v-if="this.currentPolygon" class="options-block form-group d-flex"> <label class="m-0">Polygon: </label> <div class="">{{this.currentPolygon?.name}}</div></div>
        <div v-if="isShow" class="d-flex flex-column h-100">
          <div class="forms-block">
            <div class="options-block form-group alrt-form">
              <label class="text-align-right">Name: </label>
              <div class="form-group">
                <input v-model="alertName" class="border-clr placeholder-style" type="text" placeholder="Alert Name"/>
              </div>
              <!-- <label>Polygon: </label>
              <select class="" v-model="currentPolygon">
                <option v-if="polygons?.results?.length <= 0" value="" selected disabled>No Polygon found</option>
                <option v-if="polygons?.results?.length > 0" v-for="(option, index) in polygons.results" v-bind:value="option">
                  {{ option.name }}
                </option>
              </select> -->
              <!-- <button @click="polygonModel(true)">Edit</button> -->
            </div>
            <div class="options-block form-group alrt-form">
              <label class="text-align-right max-content-1">Threshold: </label>
              <div class="form-group d-flex align-item-center">
                <input v-model="threshold2" class="border-clr placeholder-style" min="1" max="100" style="width:160px" type="number" placeholder="Thermal Threshold..."><div>°C</div>
              </div>
            </div>
            <div class="options-block form-group alrt-form">
              <label class="text-align-right">From: </label>
              <div class="form-group">
                <vue-timepicker class="placeholder-style" manual-input format="HH:mm:ss" v-model="startTime"></vue-timepicker>
              </div>
            </div>
            <div class="options-block form-group alrt-form">
              <label class="text-align-right">To: </label>
              <div class="form-group">
                <vue-timepicker class="placeholder-style" manual-input format="HH:mm:ss" v-model="endTime"></vue-timepicker>
              </div>
            </div>
            <div class="options-block form-group alignday lg-desktop d-none">
              <!--          <div class="timepicker-block">-->
              <!--          </div>-->
              <label class="text-align-right">Days: </label>
              <select class="form-select form-control select-opt placeholder-style" multiple="" v-model="days">
                <option value="1">Mondays</option>
                <option value="2">Tuesdays</option>
                <option value="3">Wednesdays</option>
                <option value="4">Thursdays</option>
                <option value="5">Fridays</option>
                <option value="6">Saturdays</option>
                <option value="0">Sundays</option>
                <!-- <option value="7">All</option> -->
              </select>
            </div>
          </div>
          <div class="forms-block sm-desktop d-none">
            <div class="options-block form-group alignday d-flex align-items-start">
              <!--          <div class="timepicker-block">-->
              <!--          </div>-->
              <label class="text-left days-txt">Days: </label>
              <select class="form-select form-control select-opt placeholder-style m-dayspicker" multiple="" v-model="days">
                <option value="1">Mondays</option>
                <option value="2">Tuesdays</option>
                <option value="3">Wednesdays</option>
                <option value="4">Thursdays</option>
                <option value="5">Fridays</option>
                <option value="6">Saturdays</option>
                <option value="0">Sundays</option>
                <!-- <option value="7">All</option> -->
              </select>
            </div>
          </div>
        </div>
        <div v-if="isShow" class="btn-block btn-pad-btm-0">
          <button   @click="submitAlert" class="btn btn-success btn-flat">Save</button>
        </div>
      </div>
      <!-- <div v-if="!analyzerId">
        <label>Please select stream to create alert for</label>
        <select v-model="analyzerId">
          <option :value="null">Please selected Analyzer Id</option>
            <option  v-for="(item, i) in analyzers.results" :value="item.id">{{ item.name }}</option>
        </select>
      </div> -->
      <!-- <div class="btn-block">
        <button v-if="analyzerId" @click="submitAlert" class="btn btn-success btn-flat">Save</button>
        <button class="btn btn-flat btn-flat close"  title="Close" @click="this.cancel">&#x2715;</button>
      </div> -->
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import VConfirmModal from "../../components/v-confirm-modal"
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
import LiveVideo from "@/views/stream/LiveVideo";
import PolygonManageModal from "@/views/alerts/PolygonManageModal";
import moment from 'moment-timezone';
export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    },
    isShow:{
      type: Boolean,
    },
    deviceId: {
      type: [Number,String],
      default: null,
    }
  },
  components:{VConfirmModal,VueTimepicker,LiveVideo, PolygonManageModal},
  computed: {
    devices () {
      return this.$store.state.device.all;
    },
    ...mapState({
      polygons: state => state.polygon.all,
      alertFilters: state => state.alertFilter.all,
    })
  },
  name: "v-options-modal",
  data() {
    return{
      currentPolygon: null,
      currentAnalyzerFilter: null,
      currentEditPolygon: null,
      currentEditAnalyzerFilter: null,
      threshold1: null,
      modalConfirm: false,
      threshold2: null,
      polygonModalOpen: false,
      mode: 0,
      days: null,
      endTime: null,
      startTime: null,
      alertName: '',
      confirmModalCallback: () => {
      },
      device: null,
      edit_mode: true,
      polygonSelectStarted: false,
      xPos: 0,
      yPos: 0,
      sourceCanvasWidth: 1024,
      sourceCanvasHeight: 576,
      videoWidth: 640,
      videoHeight: 480,
      name: '',
      url: '',
      iter: -1,
      item: {},
      perimeter: [],
      video_draw: false,
      sourcePlayed: false,
      complete: false,
      ctx: {},
      end: true,
      canvas: document.createElement("canvas"),
      sourceItem: {},
      videoSource: '',
      dataUrl: null,
      items: [
        { id: 1, src: 'https://raw.githubusercontent.com/matteomattei/jPolygon/master/image.jpg' },
      ]
    }
  },
  methods: {
    // polygonModel(open){
    //   this.polygonModalOpen = open;
    //   if (!open) {
    //     this.getPolygons();
    //   }
    // },
    initAnalyzer(){
      // this.canvas = document.getElementById('canvas');
      // this.ctx = this.canvas.getContext('2d');
      let self = this;
      this.$store.dispatch('device/get', this.deviceId).then(response => {
        if (!response.id) {
          this.$toast.warning(`Stream has no source setup`, {
            position: 'top-right'
          });
        } else {
          self.device = response;
          this.getPolygons(self.device?.id);
          this.getAlerts();
        }
        // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
        // self.drawTest();
      })
    },
    onDeletePolygon(item){
      this.currentPolygon = null;
    },
    setMode(mode) {
      this.mode = mode;
      if (this.mode === 0 || this.mode === 2) {
        this.end = true;
      }
    },
    startSelectPolygon() {
      this.polygonSelectStarted = true;
      this.end = false;
    },
    stopSelectPolygon() {
      if(this.perimeter.length <= 2) {
        alert('You need at least three points for a polygon');
        return false;
      }
      this.polygonSelectStarted = false;
      this.end = true;
      this.draw(this.end);
    },
    sourcePlay(e) {
      let self = this;
      this.sourcePlayed = true;
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      var ctx = this.ctx;
      var draw = this.draw;
      var end = this.end;
      var iter = this.iter;
      var video_draw = this.video_draw;
      var $this = e.target; //cache
      (function loop() {
        if (!$this.paused && !$this.ended) {
          // if (!video_draw)
          ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);
          draw();
          video_draw = true;
          iter = iter + 1;
          setTimeout(loop, 1000 / 30); // drawing at 30fps
        }
      })();
      console.log('source playing', e)
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {
        }
        this.getAlerts();
        this.getPolygons();
      }
    },
    deletePolygon(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('polygon/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
          this.currentEditPolygon = null;
        });
      }
    },
    deleteFilter(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('alertFilter/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
          this.currentEditAnalyzerFilter = null;
        });
      }
    },
    changeActivePolygon(val){
      this.currentPolygon = val;
    },
    sourcePlaying(e) {
      console.log('source playing', e)
    },
    metadataLoaded(e) {
      this.videoWidth = e.target.videoWidth;
      this.videoHeight = e.target.videoHeight;
      this.sourceCanvasHeight = (this.sourceCanvasWidth * this.videoHeight) / this.videoWidth;
    },
    contMenu() {
      return false;
    },
    point_it(event) {
      if (!this.polygonSelectStarted)
        return;
      if(this.complete){
        // alert('Polygon already created');
        return false;
      }
      var rect, x, y;
      {
        rect = this.canvas.getBoundingClientRect();
        x = (event.clientX - rect.left - 8); // + rect.left
        y = (event.clientY - rect.top - 8); // + rect.top
        if (this.perimeter.length>0 && x === this.perimeter[this.perimeter.length-1]['x'] && y === this.perimeter[this.perimeter.length-1]['y']){
          // same point - double click
          return false;
        }
        if(this.check_intersect(x,y)){
          alert('The line you are drowing intersect another line');
          return false;
        }
        this.perimeter.push({'x':x,'y':y});
        this.draw(false);
        return false;
      }
    },
    move_it(event) {
      if (!this.canvas)
        return;
      let rect = this.canvas.getBoundingClientRect();
      this.xPos = (event.clientX - rect.left - 8); // + rect.left
      this.yPos = (event.clientY - rect.top - 8); // + rect.top
    },
    undo() {
      this.perimeter.pop();
      this.end = false;
      this.complete = false;
      if (this.perimeter.length) {
        this.polygonSelectStarted = true;
      }
      // if (this.perimeter.length <= 2) {
      // }
      // this.polygonSelectStarted = false;
      // this.start(true);
    },
    clear() {
      this.ctx = undefined;
      this.perimeter = new Array();
      this.complete = false;
      //document.getElementById('coordinates').value = '';
      this.start();
    },
    start(with_draw) {
      const canvas = document.createElement("canvas");
      this.ctx = canvas.getContext('2d');
      // this.ctx = this.$refs.canRef;
      if (with_draw)
        this.draw(false);
    },
    cancel(){
      this.$emit('cancel')
    },
    draw(end){
      if (typeof end === 'undefined')
        end = this.end;
      this.ctx.beginPath();
      this.ctx.lineWidth = 3;
      this.ctx.strokeStyle = "white";
      this.ctx.lineCap = "square";
      if (this.polygons.results) {
        for(const pol of this.polygons.results) {
          const periList = JSON.parse(pol.lines)
          for(let i=0; i<periList.length; i++){
            if(i===0){
              this.ctx.moveTo(periList[i]['x'],periList[i]['y']);
              end || this.point(periList[i]['x'],periList[i]['y']);
            } else {
              this.ctx.lineTo(periList[i]['x'],periList[i]['y']);
              end || this.point(periList[i]['x'],periList[i]['y']);
            }
          }
          this.ctx.lineTo(periList[0]['x'],periList[0]['y']);
        }
        this.ctx.closePath();
        this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        this.ctx.fill();
        this.ctx.strokeStyle = 'green';
        this.ctx.stroke();
      }
      if (this.perimeter.length) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "white";
        this.ctx.lineCap = "square";
        for(let i=0; i<this.perimeter.length; i++){
          if(i===0){
            this.ctx.moveTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
            end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
          } else {
            this.ctx.lineTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
            end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
          }
        }
        if(end ){
          this.complete = true;
        }
        if (end || this.complete) {
          if (this.perimeter[0]) {
            this.ctx.lineTo(this.perimeter[0]['x'],this.perimeter[0]['y']);
          }
          this.ctx.closePath();
          this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
          this.ctx.fill();
          this.ctx.strokeStyle = 'blue';
        }
        this.ctx.stroke();
        // print coordinates
        if(this.perimeter.length === 0){
          // document.getElementById('coordinates').value = '';
        } else {
          //document.getElementById('coordinates').value = JSON.stringify(perimeter);
        }
      }
    },
    check_intersect(x,y){
      if(this.perimeter.length < 4){
        return false;
      }
      var p0 = new Array();
      var p1 = new Array();
      var p2 = new Array();
      var p3 = new Array();
      p2['x'] = this.perimeter[this.perimeter.length-1]['x'];
      p2['y'] = this.perimeter[this.perimeter.length-1]['y'];
      p3['x'] = x;
      p3['y'] = y;
      for(let i=0; i<this.perimeter.length-1; i++){
        p0['x'] = this.perimeter[i]['x'];
        p0['y'] = this.perimeter[i]['y'];
        p1['x'] = this.perimeter[i+1]['x'];
        p1['y'] = this.perimeter[i+1]['y'];
        if(p1['x'] === p2['x'] && p1['y'] === p2['y']){ continue; }
        if(p0['x'] === p3['x'] && p0['y'] === p3['y']){ continue; }
        if(this.line_intersects(p0,p1,p2,p3)===true){
          return true;
        }
      }
      return false;
    },
    line_intersects(p0, p1, p2, p3) {
      let s1_x, s1_y, s2_x, s2_y;
      s1_x = p1['x'] - p0['x'];
      s1_y = p1['y'] - p0['y'];
      s2_x = p3['x'] - p2['x'];
      s2_y = p3['y'] - p2['y'];
      let s, t;
      s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
      t = ( s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);
      if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
      {
        // CollisSelectingion detected
        return true;
      }
      return false; // No collision
    },
    point(x, y){
      if (!this.complete) {
        this.ctx.fillStyle="white";
        this.ctx.strokeStyle = "white";
      } else {
      }
      this.ctx.fillRect(x-2,y-2,4,4);
      this.ctx.moveTo(x,y);
    },
    drawTest() {
      var video = document.getElementById('video');
      if (!video)
        return;
// set canvas size = video size when known
      video.addEventListener('loadedmetadata', () => {
        // canvas.width = video.videoWidth;
        // canvas.height = video.videoHeight;
        // this.start(false);
      });
      // video.addEventListener('play', function() {
      //   var $this = this; //cache
      //   (function loop() {
      //     if (!$this.paused && !$this.ended) {
      //       // if (!video_draw)
      //         ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);
      //
      //       draw(end);
      //       video_draw = true;
      //       iter = iter + 1;
      //       setTimeout(loop, 1000 / 30); // drawing at 30fps
      //     }
      //   })();
      // }, 0);
    },
    submitPolygon() {
      let data = {
        deviceId: this.device?.id,
        lines: JSON.stringify(this.perimeter),
        name: this.name
      };
      // if (!this.edit_mode) {
      this.$store.dispatch('polygon/add', data).then(data => {
        this.mode = 0;
        this.getPolygons();
      });
      // } else {
      //   data.id = this.item.id;
      //   this.$store.dispatch('polygon/edit', data).then(data => {
      //     this.mode = 0;
      //     this.getPolygons();
      //   });
      // }
    },
    submitAlert() {
      // const myTimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
      // const myTimezone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
      if(this.currentPolygon == null){
        this.$toast.error(`Please select the polygon before you create an alert !`, {
          position: 'top-right'
        });
        return;
      }
      if(this.alertName == "" && this.threshold2 == null && this.days == null  ){
        this.$toast.error(`Please enter alert name and threshold before you create an alert !`, {
          position: 'top-right'
        });
        return;
      }
      const myTimezone = moment.tz.guess();
      let data = {
        timezone: myTimezone,
        name: this.alertName,
        // analyzer_id: this.device.id,
        polygon_id: this.currentPolygon?.id,
        type: 2,
        start: `${this.startTime}`,
        end: `${this.endTime}`,
        days: JSON.stringify(this.days),
        type1_threshold: this.threshold,
        type2_threshold: this.threshold2*10,
        label: this.label,
      };
      data.device_id = this.device.id;
      data.type = this.type;
      this.$store.dispatch('alertFilter/add', data).then(val => {
        this.$toast.success(`The alert has been created successfully !`, {
          position: 'top-right'
        });
        this.$emit('cancel');
        // this.mode = 0;
        // this.getAlerts();
        // this.$store.dispatch('analyzer/sync',  [this.analyzer.id]).then(result => {
        //   this.$toast.success(`settings synced`, {
        //     position: 'top-right'
        //   });
        // });
      }, error => {
        this.$toast.error(`Alert Filter Create Error`, {
          position: 'top-right'
        });
      });
    },
    getPolygons(id) {
      this.$store.dispatch('polygon/getAll', this.device?.id).then(response => {
        // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
        // self.drawTest();
      });
    },
    getAlerts() {
      this.$store.dispatch('alertFilter/getAll', this.deviceId).then(response => {
        // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
        // self.drawTest();
      });
    },
    drawCircle(ctx, circle, progress) {
      ctx.beginPath();
      var start = this.accelerateInterpolator(progress) * circle.speed;
      var end = decelerateInterpolator(progress) * circle.speed;
      ctx.arc(circle.center.x, circle.center.y, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "white";
      ctx.fill();
      ctx.stroke();
    },
    accelerateInterpolator(x) {
      return x * x;
    },
    decelerateInterpolator(x) {
      return 1 - ((1 - x) * (1 - x));
    },
    addHours(numOfHours, date = new Date()) {
      date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
      return date;
    }
  },
  mounted() {
    const date = new Date();
    const endDate = this.addHours(1);
      this.startTime = `${date.getHours().toString().padStart(2,"0")}:${date.getMinutes().toString().padStart(2,"0")}:${date.getSeconds().toString().padStart(2,"0")}`
      this.endTime = `${endDate.getHours().toString().padStart(2,"0")}:${endDate.getMinutes().toString().padStart(2,"0")}:${endDate.getSeconds().toString().padStart(2,"0")}`
      this.initAnalyzer();
//     this.getAnalyzerFilters();
//     // this.$questionHub.$on('analyzer-data', this.onDataChanged)
//     this.start(true);
//
//
//     this.$store.dispatch('analyzer/get', this.analyzerId).then(response => {
//       this.analyzerItem = response.source;
//       this.sourcePolygons = response.source.sourcePolygons;
//       this.videoSource = this.$apiUrl + '/api/file/image/' + response.source.file.id;
//
// //         const video = document.getElementById("video");
// //
// //         const canvas = document.createElement("canvas");
// // // scale the canvas accordingly
// //         canvas.width = video.videoWidth;
// //         canvas.height = video.videoHeight;
// // // draw the video at that frame
// //         canvas.getContext('2d')
// //             .drawImage(video, 0, 0, canvas.width, canvas.height);
// // // convert it to a usable data URL
// //         this.dataUrl = canvas.toDataURL();
//
//       // this.ctx = canvas.getContext('2d');
//       // this.img = new Image();
//       // this.img.src = this.items[0].src;
//       // console.log('this.img.src:', this.img.src);
//       // this.img.onload = () => {
//       //   console.log('this.ctx:', this.ctx);
//       //   this.ctx.drawImage(this.img, 0, 0);
//       // }
//       // this.drawItem(0, 0, 0);
//       // this.drawTest();
//     });
  },
  watch: {
    videoSource : (a) => {
      console.log(a)
    },
    alertFilters : function(val) {
    },
    sourcePlayed : function(val) {
    },
    currentEditAnalyzerFilter : function(val) {
      console.log('current alert edit', val)
    },
    currentEditPolygon : function(val) {
      console.log('current polygon edit', val)
    },
    perimeter: function (val) {
      if (this.perimeter.length) {
        // this.polygonSelectStarted = true;
      }
    },
    deviceId: function(){
      this.initAnalyzer();
    },
    startTime: function(){
    },
    endTime: function(){
    },
    currentPolygon: function(){
    }
  }
}
</script>
<style scoped>

.m-0 {
  margin: 0 !important;
}

.d-none {
  display: none !important;
}

.mt-auto {
  margin-top: auto !important;
}

.d-block {
  display: block !important;
}

.canvas-bx {
  /* padding-right: 1rem; */
}

.align-items-start {
  align-items: flex-start;
}

.days-txt {
  min-width: 42px;
}

.polygon-frm {
    background-color: #f7f7f7;
    border-radius: 3px;
    /* padding: 10px; */
}

.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  position: relative;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;
}
.content > .header>*{
  margin-bottom: 0 !important;
}
/*.content > canvas{*/
/*  width: 640px;*/
/*  height: 320px;*/
/*}*/
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
  .btn-block{
    padding: 10px;
    display: flex ;
    align-items: center;
    justify-content: end;
    grid-gap: 1em;
  }
  .close{
    position: absolute;
    top: 8px;
    right: 5px;
  }
  .select-opt{
    height: 125px;
  }

  .m-dayspicker {
    max-width: 161px;
  }

  .border-clr{
    border: 1px solid #d2d2d2;
  }
  .alignday{
    align-items: normal;
  }
  .text-align-right{
    text-align: right;
  }
  .max-content-1{
    width: max-content;
  }
  .placeholder-style{
    font-size: 0.75em !important;
  }

  @media only screen and (min-width: 1367px) {
  .lg-desktop {
    display: grid !important;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1em;
    padding: 3px;
    align-items: start;
}
}

@media only screen and (max-width: 1366px) {
  .alert-manage-modal {
    flex-direction: column;
    max-height: 640px !important;
    overflow-y: auto;
  }

  .polygon-frm {
    margin-top: 10px;
  }

  .alrt-form {
    display: inline-flex !important;
    margin: 0 0 5px 10px !important;
  }

  .forms-block .alrt-form:first-child {
    margin-left: 0 !important;
  }

  .sm-desktop {
    display: block !important;
  }

  .canvas-bx {
    padding-right: 0;
  }
}
  .btn-pad-btm-0 {
    padding-bottom: 0px !important;
  }

</style>
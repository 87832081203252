<template>
  <div class="modal-options">
      <div class="content">
        <div class="header panel-heading">
          <h3>Analyzer</h3>
        </div>
        <div class="body">
          <div class="form-group">
            <label>Analyzer Name</label>
            <input v-model="name" type="text" placeholder="analyzer name...">
          </div>
          <div v-if="!edit_mode" class="form-group">
            <label>Device</label>
            <select v-model="device_id">
              <option v-bind:value="''"  v-if="sources.results && sources.results.length">Select Device</option>
              <option v-for="option in devices.results" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div  v-if="!edit_mode" class="form-group">
            <label>Video Source</label>
            <select v-model="source_id">
              <option v-bind:value="''" v-if="sources.results && sources.results.length">Select Source</option>
              <option v-for="option in sources.results" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div  v-if="!edit_mode" class="form-group">
<!--            <input type="radio" id="one" value="1" v-model="type">-->
<!--            <label for="one">Normal</label>-->
<!--            <input type="radio" id="two" value="2" v-model="type">-->
<!--            <label for="two">Thermal</label>-->
<!--            <br>-->
            <span>Type: {{ type === 1 ? 'Normal' : 'Thermal' }}</span>
          </div>
          <div class="form-group">
            <label>Overall Threshold</label>
            <input v-model="threshold" type="text" placeholder="overall threshold">
          </div>
          <div class="form-group">
            <label>Loop Video</label>
            <input type="checkbox" id="checkbox" v-model="loop">
          </div>

<!--          <h3>Radios block</h3>-->
<!--          <div class="form-group inline-group">-->
<!--            <div class="item-block">-->
<!--              <label>Radio</label>-->
<!--              <input type="radio" name="radio" placeholder="">-->
<!--            </div>-->
<!--            <div class="item-block">-->
<!--              <label>Radio</label>-->
<!--              <input type="radio" name="radio" placeholder="">-->
<!--            </div>-->
<!--            <div class="item-block">-->
<!--              <label>Radio</label>-->
<!--              <input type="radio" name="radio" placeholder="">-->
<!--            </div>-->
<!--          </div>-->

<!--          <h3>Checkbox block</h3>-->
<!--          <div class="form-group inline-group">-->
<!--            <div class="item-block">-->
<!--              <label>Checkbox</label>-->
<!--              <input type="checkbox" placeholder="">-->
<!--            </div>-->
<!--            <div class="item-block">-->
<!--              <label>Checkbox</label>-->
<!--              <input type="checkbox" placeholder="">-->
<!--            </div>-->
<!--            <div class="item-block">-->
<!--              <label>Checkbox</label>-->
<!--              <input type="checkbox" placeholder="">-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="footer">
          <button @click="submit" class="btn btn-info btn-flat">Save</button>
          <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    }
  },
  name: "v-options-modal",
  data() {
    return{
      name: '',
      device_id: '',
      source_id: '',
      loop: 1,
      type: 2,
      threshold: null,
      item: {},
    }
  },
  methods:{
    submit() {
      let data = {
        name: this.name,
      };
      if (!this.edit_mode) {
        data.device_id = this.device_id;
        data.source_id = this.source_id;
        data.threshold = this.threshold;
        data.loop = this.loop;
        data.type = this.type;
        this.$store.dispatch('device/add', data).then(data => {
          this.$toast.success(`Analyzer Created`, {
            position: 'top-right'
          });
          this.cancel();
        }, error => {
          this.$toast.error(`Analyzer Create Error`, {
            position: 'top-right'
          });
        });
      } else {
        data.id = this.item.id;
        this.$store.dispatch('device/edit', data).then(data => {
          this.cancel();
        });
      }
    },
    cancel(){
      this.$emit('cancel')
    }
  },
  computed: {
    devices () {
      return this.$store.state.device.all;
    },
    sources () {
      return this.$store.state.source.all;
    },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  mounted() {
    this.$store.dispatch('device/getAll');
    this.$store.dispatch('source/getAll');
    if (this.edit_mode) {
      console.log('edit analyzer: ', this.id)
      this.$store.dispatch('device/get', this.id).then(response => {
        this.item = response;
        this.name = response.name;
        this.device_id = response.device_id;
        this.source_id = response.source_id;
      });
    } else {
      console.log('add analyzer')
    }
  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 400px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}
.body{
  padding: 8px 35px;
}
.form-group > input[type='text']{
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > * {
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

</style>
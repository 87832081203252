<template>
  <section class="loginSection">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <img src="@/assets/img/full-color-logo-dark.png" alt="">
            </h3>
          </div>
        </div>
        <form @submit.prevent="handleSubmit">
        <div class="boxBody">
          <div class="row">
            <div v-show="registered"  class="col-xs-12">
              <span>please check your email {{ email }} for details</span>
            </div>
            <div v-show="!registered" class="col-xs-12">
              <div class="form-group">
                <label for="name">Company Name:</label>
                <input class="form-control" @keyup="validateField('name')" v-model="name" name="name" :class="{ 'is-invalid': error['name'] }" type="text" id="name"  />
                <div v-show="error['name']" class="invalid-feedback">{{error['name'] }}</div>
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input class="form-control" v-model="email" name="email" :class="{ 'is-invalid': error['email'] }" type="text" id="email"  />
                <div v-show="error['email']" class="invalid-feedback">{{ error['email'] }}</div>
              </div>
            </div>
            <div v-if="errors.length">
              <div>
                <ul>
                  <li v-for="error in errors" class="msg-err" v-bind:key="error?.id">
                    <div class="invalid-feedback red">{{ error }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="boxFooter">
          <button type="submit" v-show="!registered"  :disabled="loggingIn" class="btn btn-flat color-2-bg color-2-hover-bg color-text-white">Register</button>
          <button type="button" @click="() => {
            this.$router.push('/auth/login');
          }" v-show="registered" class="btn btn-flat color-2-bg color-2-hover-bg color-text-white">Login</button>
        </div>
        </form>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationMixin} from "@/mixins/validation.mixin";

export default {
    data () {
        return {
          name: '',
          email: '',
          registered: false,
          validationRules: {
            'name': {
              validate: () => {
                if (!this.name)
                  this.error['name'] = 'Company is required'
                return !!this.name;
              }
            },
            'email': {
              validate: () => {
                if (!this.email)
                  this.error['email'] = 'Email is required'
                return !!this.email;
              }
            }
          }
        }
    },
  mixins: [ValidationMixin],
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
      ...mapState({
        errors: state => state.authentication.errors,

      })
    },
    created () {
        // reset login status
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        handleSubmit (e) {
          const { name, email } = this;
          if (!this.error['name'] && !this.error['email']) {
            this.$store.dispatch('authentication/register', { name, email }).then((data) => {
              this.registered = true;
            }).catch((data)=>{

            })
          }
        }
    }
};
</script>
<style scoped>
.boxHeaderLeft > h3{
  display: flex;
  align-items: center;
}
.boxHeaderLeft > h3 > img{
 width: 100px;
  float: left;
}
.msg-err{
  list-style: none !important;
  font-size: 13px;
  color: darkred;
  margin-left: 10px;
}
</style>
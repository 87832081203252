<template>
  <section class="contentWrapper" style="min-height: 536px;">

    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopLeft">
            <h1>Charts Devices</h1>
          </div>
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-cube"></i>
                  <span>Home</span>
                </a>
              </li>
              <li><a href="#">Layouts</a></li>
              <li><a href="#">Box</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="contentWrap">
      <div class="row">
        <item/>
        <item/>
      </div>
    </section>

  </section>
</template>

<script>
import item from "./item"
export default {
  name: "chartDevicePage",
  components: {item}
}
</script>

<style scoped>

</style>
import {onBeforeUpdate, ref} from "vue";
import {mapState} from "vuex";

export const StreamsectionMixin =  {
    setup() {

        const liveVideos = ref([]);
        const liveVideosContainer = ref([]);

        // Make sure to reset the refs before each update.
        onBeforeUpdate(() => {
            liveVideos.value = [];
            liveVideosContainer.value = [];
        });

        return{
            liveVideos,
            liveVideosContainer,
        }
    },
    data(){
        return {
        };
    },
    created: function () {
    },
    methods: {
        deleteItem(item) {
            this.confirmModalCheck(true);
            this.confirmModalCallback = () => {
                this.$store.dispatch('device/remove',  [item.id]).then(result => {
                    this.confirmModalCheck(false);
                });
            }
        },
        filterModalCheck(item, open){
            this.modalFilter = open;
            if (!this.modalFilter) {
                this.$store.dispatch('device/getAll');
                this.edit_id = null;
            } else {
                this.edit_id = item.id;
            }
        },
        editItem(item) {
            this.edit_mode = true;
            this.edit_id = item.id;
            this.addModalCheck(true);
        },
        syncItem(item) {
            this.$store.dispatch('device/sync',  [item.id]).then(result => {
                this.$toast.success(`settings synced`, {
                    position: 'top-right'
                });
            });
        }
    },
    computed: {
        analyzers () {
            return this.$store.state.analyzer.all;
        },
        ...mapState({
            loggedIn: state => state.authentication.status.loggedIn,
        })
    },
}
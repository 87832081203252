<template>
<div class="analyzer-alert-modal">
  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Node Editor</h3>
      </div>
      <div class="d-flex h-100 p-15">
      <div class="video-container">
          <GeneratedAlertManager :analyzer-id="this.analyzerId" :video_id="this.alert?.videoFileId" :alertSpots="this.alert"/>
      </div>
      <div class="video-desc d-flex flex-column">
      <div class="flex-1 p-5">
        <div class="forms-block">
          <div class="options-block form-group">
            <label>Date: </label>
            <div class="form-group mbottom-5">
              <div class="alert-fields">{{this.date}}</div>
            </div>
          </div>

          <div class="options-block form-group">
            <label>Time: </label>
            <div class="form-group mbottom-5">
              <div class="alert-fields" style="width:80px">{{this.time}}</div>
            </div>
          </div>

          <div class="options-block form-group">
            <label>Polygon Name: </label>
            <div class="form-group mbottom-5">
              <div class="alert-fields">{{this.polygon?.Name}}</div>
            </div>
          </div>

          <div class="options-block form-group">
            <label>Estimated Temperature: </label>
            <div class="form-group mbottom-5">
              <div class="alert-fields">{{this.alert?.estimateTemp/10}}°C</div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <button @click="this.cancel" style="float:right; border-color: black; border-radius: 5px !important;" class="btn btn-close btn-flat">Close</button>
        <button class="btn btn-flat btn-flat close"  title="Close" @click="this.cancel">&#x2715;</button>
      </div>
      </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import GeneratedAlertManager from "./GeneratedAlertManager";
export default {
  props: {
    alert :{ 
      type:Object
    },
    id: {
      type: Number
    },
    analyzerId: {
      type: [Number,String],
      default: 1,
    }
  },
  name: "analyzer-alert-modal",
  data() {
    return{
        polygon:{},
        date: "",
        time: ""
    }
  },
  components:{ GeneratedAlertManager },

  watch: {
    analyzerId: function(){
      this.initAnalyzer();
    },
  },
  mounted() {
      this.dateformat();
      this.alertPolygon()
    },
  methods: {
        dateformat(){
          var now = new Date(this.alert?.creationDate);
          this.date = now.toLocaleDateString();
          this.time = now.toLocaleTimeString();
        },
        alertPolygon(){
            var a = this.alert.polygon.replace("\"\\[", "[").replace("\\]\"","]");
            this.polygon =  JSON.parse(a);
        },
    cancel(){
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  position: relative;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}

  .content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block {
  display: flex;
  justify-content: space-between;
  grid-template-columns: 1fr 3fr;
  /* grid-gap: 1em; */
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
  .btn-block{
    padding: 10px;
    display: flex;
    align-items: center;
    grid-gap: 1em;
  }
  .close{
    position: absolute;
    top: 12px;
    right: 15px;
  }
  .select-opt{
    height: 145px;
  }
  .alert-fields{
    padding: 5px !important;
    font-size: 13px !important;
    width: 80px !important;
  }
  .mbottom-5 {
    margin-bottom:5px !important;
  }
</style>
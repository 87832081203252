<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
          ></fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FusionChart4",
  data(){
    return{
      type: "spline",
      width: "100%",
      height: "400",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Average Monthly Temperature in Texas",
          yaxisname: "Average Monthly Temperature",
          anchorradius: "5",
          plottooltext: "Average temperature in $label is <b>$dataValue</b>",
          showhovereffect: "1",
          showvalues: "0",
          numbersuffix: "°C",
          theme: "gammel",
          anchorbgcolor: "#72D7B2",
          palettecolors: "#72D7B2"
        },
        data: [
          {
            label: "Jan",
            value: "1"
          },
          {
            label: "Feb",
            value: "5"
          },
          {
            label: "Mar",
            value: "10"
          },
          {
            label: "Apr",
            value: "12"
          },
          {
            label: "May",
            value: "14"
          },
          {
            label: "Jun",
            value: "16"
          },
          {
            label: "Jul",
            value: "20"
          },
          {
            label: "Aug",
            value: "22"
          },
          {
            label: "Sep",
            value: "20"
          },
          {
            label: "Oct",
            value: "16"
          },
          {
            label: "Nov",
            value: "7"
          },
          {
            label: "Dec",
            value: "2"
          }
        ]
      }
    }
  },
}
</script>

<style scoped>

</style>
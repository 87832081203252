<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>Cameras</h1>-->
<!--        </div>-->
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-folder"></i>
                <span>Cameras</span>
              </a>
            </li>
            <li><a href="#">Manager</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-shopping-bag"></i>
                  <span>Analyzer Records: <router-link :to="`/analyzer`" >{{analyzer.name}}</router-link></span>
                </h3>
              </div>
              <div class="boxHeaderRight">

              </div>
            </div>
            <div class="boxBody">
<!--              <ul class="nav nav-tabs">-->
<!--                <li class="active"><a href="#productsManagerGallery-tab" data-toggle="tab"></a></li>-->
<!--              </ul>-->
              <div class="tab-content">
                <div class="tab-pane fade in active" id="productsManagerGallery-tab">
                  <section class="productsGalleryWrap">
<!--                    <section class="productsGalleryWrapHeader">-->
<!--                      <div class="productsGalleryWrapHeaderLeft">-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="mtCheckbox">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label>&ndash;&gt;-->
<!--&lt;!&ndash;                              <input type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                              <span>Select All</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </label>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                        <div>-->
<!--                          <div class="btn-group">-->
<!--&lt;!&ndash;                            <button   @click="addItem" class="btn btn-info btn-flat" title="Add"><i class="fa fa-plus"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;                            <button class="btn btn-info btn-flat" title="Delete"><i class="fa fa-trash-o"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;                            <button class="btn btn-info btn-flat" title="Save"><i class="fa fa-save"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;                            <button class="btn btn-info btn-flat btn-save-for-all" title="Save For All"><i class="fa fa-save"></i></button>&ndash;&gt;-->
<!--                          </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="productsGalleryWrapHeaderSelect">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="productsGalleryLang">Language:</label>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                              <select id="productsGalleryLang" class="selectize">&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="">Select</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="en" selected>English</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="ru">Русский</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="fr">Français</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="de">Deutsch</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="it">Italiano</option>&ndash;&gt;-->
<!--&lt;!&ndash;                              </select>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="productsGalleryWrapHeaderSelect">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="productsGallerySort">Sort By:</label>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                              <select id="productsGallerySort" class="selectize">&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="">Select</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="order" selected>By Order</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="date">By Date</option>&ndash;&gt;-->
<!--&lt;!&ndash;                              </select>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="productsGalleryWrapHeaderSelect">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="productsGalleryFilter">Filter:</label>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                              <select id="productsGalleryFilter" class="selectize">&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="">Select</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="status/1">Enable</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="status/0">Disable</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="filter_fld/10/filter_val/1">Active</option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <option value="filter_fld/10/filter_val/0">Passive</option>&ndash;&gt;-->
<!--&lt;!&ndash;                              </select>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                      </div>-->
<!--                      <div class="productsGalleryWrapHeaderRight">-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="input-group">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="productsGallerySearch" hidden=""></label>&ndash;&gt;-->
<!--&lt;!&ndash;                            <input id="productsGallerySearch" type="text" class="form-control" placeholder="Search" />&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="input-group-btn">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <button id="btnGo" ~="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </section>-->
                    <section class="productsGalleryWrapBody">
                      <div class="row" id="productsGalleryWrapBodyRow">
                        <div v-for="item in sources">
                          <div class="productsGalleryItem col-xs-12 col-sm-4 col-md-3">
                            <div class="productsGalleryItemIn">
                              <div class="productsGalleryItemInside">
                                <div class="productsGalleryItemImg flex-center">
                                  <video width="280" controls>
                                    <source :src="(item.fileId ? $apiUrl + '/api/file/image/' + item.fileId: '/img/avatar.png')"  type="video/mp4">
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                                <div class="productsGalleryItemInfo">
                                  <div class="productsGalleryItemInfoLeft">
<!--                                    <div class="mtCheckbox">-->
<!--                                      <label>-->
<!--&lt;!&ndash;                                        <input type="checkbox" />&ndash;&gt;-->
<!--                                      </label>-->
<!--                                    </div>-->
                                    <span>Record Id: {{ item.id }}</span>
                                  </div>
                                  <div class="productsGalleryItemInfoRight">
                                    <div class="btn-group btn-group-sm">
                                      <button  @click="deleteItem(item)" class="btn btn-danger btn-flat" title="Delete"><i class="fa fa-trash-o"></i></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!--  Modal options -->
  <!--  Modal options -->
  <!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
export default {
  components: {VOptionsModal, VConfirmModal, VVideoModal},
  data() {
    return{
      sources: [],
      analyzer: {},
      modalAdd: false,
      modalOptions: false,
      modalConfirm: false,
      modalVideo: false,
      edit_mode: false,
      edit_id: null,
      sourceStatuses: [],
      confirmModalCallback: () => {

      }
    }
  },
  computed: {
    // sources () {
    //   return this.$store.state.analyzer.all;
    // },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  created () {
    // this.$questionHub.$on('source-info', this.onScoreChanged)
  },
  mounted() {
    this.$store.dispatch('device/get', this.$route.params.analyzerId).then((data) => {
      console.log('get analyer', data);
      if (data && data.analyzerResults) {
        this.sources = data.analyzerResults.reverse();
        this.analyzer = data;
      }
    }, (error) =>{

    })
  },
  methods: {
    // This is called from the server through SignalR
    onScoreChanged ({data }) {
      let source_info = JSON.parse(data);
      console.log('source-info:', data);
    },
    getSourceStatus(item) {
      let list = this.sourceStatuses.filter(r => r.id === item.id);
      if (list.length) {
        return list[0].status;
      }
      return false;
    },
    addModalCheck(open){
      this.modalAdd = open;
      if (!this.modalAdd) {
        this.$store.dispatch('source/getAll');
      }
    },
    optionsModalCheck(){
      this.modalOptions = !this.modalOptions;
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.$store.dispatch('source/getAll');
      }
    },
    videoModalCheck(){
      this.modalVideo = !this.modalVideo;
    },
    deleteItem(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('device/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
        });
      }
    },
    addItem() {
      this.edit_mode = false;
      this.addModalCheck(true);
    },
    editItem(item) {
      this.edit_mode = true;
      this.edit_id = item.id;
      this.addModalCheck(true);
    }
  }
};
</script>
<style scoped>
.btn-group > button {
    margin: 0 10px;
  }
.flex-center{
  display: flex !important;
  align-items: center;
}
</style>
  
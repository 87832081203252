<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
          ></fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "FusionChart3",
  data(){
    return{
      type: "scrollbar2d",
      width: "100%",
      height: "400",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Top 25 NPM Packages for Node.js Developers",
          subcaption: "March 2019 ",
          plottooltext: "$dataValue Downloads",
          yaxisname: "Number of Downloads",
          xaxisname: "Packages",
          theme: "gammel"
        },
        categories: [
          {
            category: [
              {
                label: "Commander.js"
              },
              {
                label: "Async.js"
              },
              {
                label: "Request"
              },
              {
                label: "Express"
              },
              {
                label: "WebPack"
              },
              {
                label: "Underscore"
              },
              {
                label: "React"
              },
              {
                label: "JSDom"
              },
              {
                label: "Cheerio"
              },
              {
                label: "Mocha"
              },
              {
                label: "Marked"
              },
              {
                label: "LESS"
              },
              {
                label: "Morgan"
              },
              {
                label: "Karma"
              },
              {
                label: "MongoDB Driver"
              },
              {
                label: "Nodemailer"
              },
              {
                label: "Passport"
              },
              {
                label: "Browserify"
              },
              {
                label: "Grunt"
              },
              {
                label: "JSHint"
              },
              {
                label: "Angular"
              },
              {
                label: "Bower"
              },
              {
                label: "Pug"
              },
              {
                label: "PM2"
              },
              {
                label: "Hapi"
              }
            ]
          }
        ],
        dataset: [
          {
            data: [
              {
                value: "97294205"
              },
              {
                value: "95482197"
              },
              {
                value: "60224172"
              },
              {
                value: "33018247"
              },
              {
                value: "31615028"
              },
              {
                value: "28984878"
              },
              {
                value: "25391784"
              },
              {
                value: "23581733"
              },
              {
                value: "12321215"
              },
              {
                value: "10838161"
              },
              {
                value: "7808888"
              },
              {
                value: "7127519"
              },
              {
                value: "6659395"
              },
              {
                value: "5731933"
              },
              {
                value: "4843888"
              },
              {
                value: "3264090"
              },
              {
                value: "2755188"
              },
              {
                value: "2661761"
              },
              {
                value: "2371272"
              },
              {
                value: "2201511"
              },
              {
                value: "1821149"
              },
              {
                value: "1683996"
              },
              {
                value: "1602832"
              },
              {
                value: "1267422"
              },
              {
                value: "1042206"
              }
            ]
          }
        ]
      }
    }
  },
  mounted: function() {
    Promise.all([dataFetch, schemaFetch]).then(res => {
      const data = res[0];
      const schema = res[1];
      const fusionTable = new FusionCharts.DataStore().createDataTable(
          data,
          schema
      );
      this.dataSource.data = fusionTable;
    });
  }
}
</script>

<style scoped>

</style>
export const ValidationMixin =  {
    data(){
        return {
            validationRules: {},
            error: {},
        };
    },
    created: function () {
    },
    methods: {
        validateField(fieldName) {
            if (this.validationRules[fieldName]) {
                if (this.validationRules[fieldName].validate()) {
                    delete this.error[fieldName];
                }
            }
        },
        resetErrors() {

        }
    }
}
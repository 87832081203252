<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-shopping-bag"></i>
                <span>Notifications</span>
              </a>
            </li>
            <li><a href="#">Options</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-shopping-bag"></i>
                  <span>Notifications</span>
                </h3>
              </div>
              <div class="boxHeaderRight">
              </div>
            </div>
            <div class="boxBody">
              <div class="tab-content">
                <div class="tab-pane fade in active" id="NotificationsOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            List
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM" aria-label="notification">
                            <thead>
                            <tr>
                              <th id="id">
                                <div class="btn-group">
                                </div>
                              </th>
                              <th  id="option" colspan="4">
                              </th>
                            </tr>
                            <tr>
                              <th id="ID">Id</th>
                              <th id="type">Type</th>
                              <th id="message">Message</th>
                              <th id="status">Status</th>
                              <th id="options" colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in notifications.results" v-bind:key="item?.id" :style="{'background-color': getColor(item) }">
                              <td>{{item.id}}</td>
                              <td>{{ getNotificationName(item) }}</td>
                              <td>{{item.message}}</td>
                              <td class="status">
                                <span v-if="item.read === 1"><i class="fa fa-bookmark-o"></i></span>
                                <span v-bind:style="{cursor: 'pointer'}" v-if="item.read === 0" @click="markReadItem(item)" ><i class="fa fa-bookmark"></i></span>
                              </td>
                              <td>
                                <div class="btn-group btn-group-sm">
                                  <button @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>


                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                      <span>Show</span>
                                  </span>
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control">
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <span class="input-group-addon">
                                    <span>Entries</span>
                                </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <!--  Modal options -->
  <NotificationModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import NotificationModel from "./NotificationModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
export default {
    components: {NotificationModel, VOptionsModal, VConfirmModal, VVideoModal},
    data() {
      return{
        modalAdd: false,
        modalConfirm: false,
        edit_mode: false,
        edit_id: null,
        notificationStatuses: [],
        confirmModalCallback: () => {

        }
      }
    },
    computed: {
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
        notifications: state => state.notification.all,

      })
    },
    created () {
        this.$store.dispatch('notification/getAll');
    },
    mounted() {
    },
    methods: {
      // This is called from the server through SignalR
      getNotificationName(item) {
        if (item.type === 1) {
          return 'new device';
        }
        if (item.type === 2) {
          return 'device status';
        }
        return 'notification';
      },
      getColor(item) {
        if (item.read === 1) {
          return 'white';
        }
        if (item.read === 0) {
          return '#e3e3e3';
        }
        return 'white';
      },
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch('notification/getAll');
          }
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch('notification/getAll');
        }
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('notification/remove',  [item.id]).then(result => {
            this.confirmModalCheck(false);

            this.$store.dispatch('notification/getAllUnread')
            this.$store.dispatch('notification/getAll')
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      },
      markReadItem(item) {
        this.$store.dispatch('notification/markRead', {Id: item.id, Read: 1}).then(result => {
          this.$store.dispatch('notification/getAll')
          this.$store.dispatch('notification/getAllUnread')
        });
      }
  }
};
</script>
<style scoped>
  .btn-group > button {
    margin: 0 10px;
  }
  td.status > span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
  }
  td.status > span.red{
    background-color: rgb(172, 32, 32);
  }
  td.status > span.green{
    background-color: rgb(38, 109, 16);
  }
  td.status > span.yellow{
    background-color: rgb(207, 167, 35);
  }
</style>
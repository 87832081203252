<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>DeviceKeys</h1>-->
<!--        </div>-->
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-shopping-bag"></i>
                <span>Device Keys</span>
              </a>
            </li>
            <li><a href="#">List</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-lock"></i>
                  <span>Device Keys</span>
                </h3>
              </div>
              <div class="boxHeaderRight d-flex align-items-center">
                <button id="deleteBtn" class="deleteBtn" style="display:none" @click="this.deleteMultiple()">Delete</button>
                <div
                  class="
                    dropdown
                    otx-multiselect
                    w-75
                    d-flex
                    flex-column
                    align-items-center
                  "
                >
                  <Multiselect
                    class="bg-green-500 text-white ot-multiselect-tag"
                    :options="companyData"
                    :multiselect="true"
                    mode="tags"
                    v-model="selectedCompanys"
                    placeholder="Company"
                    track-by="id"
                    closeOnSelect="false"
                    @change="changeCompany($event)"
                  />
                </div>
                  <input size="18" class="alerttitle" type="search" v-model="searchQuery" placeholder="Search by Device Name" aria-label="Search">
                  <div>
                    <button @click="addItem" class=""><i class="fa fa-plus-circle" aria-hidden="true"></i> Add Device</button>
                  </div>
                </div>
            </div>
            <div class="boxBody">
                <div class="tab-content">
                  <div class="tab-pane fade in active" id="productsManagerGallery-tab">
                    <section class="productsGalleryWrap">
                      <table id="datatable"  class="table table-hover table-bordered display nowrap">
                        <thead>
                          <tr>
                            <!-- <th><input type="checkbox" id="select-all" @change="toggleSelectAll" @click="selectAll()">   Select All</th> -->
                            <th><input type="checkbox" id="select-all" @change="toggleDelete" >   Select All</th>
                            <th>Device Key Value</th>
                            <th>Device Key Name</th>
                            <th>Company Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in this.searchDevices" :key="item?.id">
                            <td><input type="checkbox" class="checkbox"  @click="toggleDelete"  :id="item?.id"></td>
                            <td>{{item?.key}}</td>
                            <td>{{item?.name}}</td>
                            <td v-show="isAdmin">{{item?.company?.name}}</td>
                            <td class="eye-delete" style="align :center">
                              <div type="button" style="padding-right:5px; display: inline-block;" title="View" @click="this.showDetail(item)"><i class="fa fa-eye"></i></div>
                              <div type="button" style="padding-right:40px; display: inline-block;" title="Delete" @click="this.deleteItem(item)"><i class="fa fa-trash"></i></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <section class="productsGalleryWrapBody">
                        <div class="row" id="productsGalleryWrapBodyRow">
                        </div>
                      </section>
                    </section>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <!--  Modal options -->
  <DeviceKeyModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <DeviceKeyDetailModal v-if="modalDeviceKeyDetail" :item="this.currentDevice" @cancel="cancelModal(false)"/>
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" checkMessage="Are you sure you want to delete the device key ?" @cancel="confirmModalCheck(false)"/>
  <!--  Modal Video -->
  </div>
</template>

<script>
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables"
import $ from "jquery"; 

import { mapState } from 'vuex'
import Environment from "../../environment/env.js";
import Multiselect from "@vueform/multiselect";
import DeviceKeyModel from "./DeviceKeyModal"
import DeviceKeyDetailModal from "./DeviceKeyDetailModal.vue"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import setActive from "../../mixins/globalFunction.js";
export default {
    components: {DeviceKeyModel, DeviceKeyDetailModal, VOptionsModal, VConfirmModal,Multiselect},
    mixins: [setActive],
    data() {
      return{
        allSelectedCompanyIds:[],
        deviceKeyTableData: [],
        tableData: [],
        currentDevice: {},
        modalAdd: false,
        modalOptions: false,
        modalConfirm: false,
        modalVideo: false,
        edit_mode: false,
        edit_id: null,
        searchQuery:null,
        selectedCompanys: [],
        modalDeviceKeyDetail: false,
        companyData: [],
        confirmModalCallback: () => {

        }
      }
    },
    computed: {
        companies () {
            return this.$store.state.company.all;
        },
        deviceKeys () {
            return this.$store.state.deviceKey.all;
        },
        searchDevices() {
          this.tableData = this.deviceKeyTableData.slice();
            if (this.searchQuery) {
              return this.deviceKeys.results.filter(deviceKey => (deviceKey?.name).toLowerCase().includes(this.searchQuery.toLowerCase()))
            }
            else if(this.allSelectedCompanyIds.length > 0){
              return this.deviceKeys.results.filter(company => this.allSelectedCompanyIds.includes(company.companyId))
            }
            else {
              return this.deviceKeys.results
            }
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
        isAdmin: state => state.authentication.isAdmin,
      })
    },
    created () {
        this.$store.dispatch(Environment.GET_ALL_COMPANY_URL);
    },
    mounted() {
      this.loadDevices();
      this.setActive("DeviceKeys");
        console.log(this.$store.state)
        this.$store.dispatch(Environment.GET_ALL_COMPANY_URL)
          .then(() => {
            this.getCompanies();
          })
          .catch((error) => {
            console.error('Error getting devices:', error);
          });
    },
    methods: {
      toggleDelete(e){
        const checkboxes = document.querySelectorAll('.checkbox');
        const selectAllCheckbox = document.getElementById('select-all');
        let deleteButton = document.getElementById('deleteBtn');
        selectAllCheckbox.addEventListener('change', () => {
        checkboxes.forEach((checkbox) => {
            checkbox.checked = selectAllCheckbox.checked;
         });
        updateDeleteButton();
      });

      checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        updateDeleteButton();
        });
      });

      deleteButton.addEventListener('click', () => {
      const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');
      checkedCheckboxes.forEach((checkbox) => {
        checkbox.parentNode.removeChild(checkbox);
        });
      updateDeleteButton();
      });

      function updateDeleteButton() {
        const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');
        if (checkedCheckboxes.length > 0) {
          deleteButton.style.display="block";
        } else {
          deleteButton.style.display="none";
        }
  
        if (checkedCheckboxes.length === checkboxes.length) {
          selectAllCheckbox.checked = true;
        } else {
          selectAllCheckbox.checked = false;
        }
      }
    },
      showDetail(item){
        this.currentDevice = item;
        this.modalDeviceKeyDetail = true;
      },
      changeCompany(e){
        this.allSelectedCompanyIds= e
      },
      toggleSelectAll(e){
        let button = document.getElementById("deleteBtn")
        if (e.target.checked) {
            button.style.display = "block";
          } else {
            button.style.display = "none";
          }
      },
      cancelModal(){
        this.modalDeviceKeyDetail = !this.modalDeviceKeyDetail;
      },
      getCompanies() {
          this.$store.dispatch('company/getAll')
          for(let i = 0; i < this.companies.results.length; i++) {
            let company = {
               label: this.companies.results[i]?.name,
               value: this.companies.results[i]?.id, 
            };
            this.companyData.push(company);
          }
      },
    //  async loadDevices() {
    //   try {
    //     await this.$store.dispatch(Environment.GET_ALL_DEVICEKEY_URL);
    //     $('#datatable').dataTable().fnDestroy();
    //     this.deviceKeyTableData = this.deviceKeys.results.map(device => ({
    //       deviceKeyId: device.id,
    //       name: device.name,
    //       key: device.key,
    //       companyId: device.companyId,
    //       companyName: device.company?.name || '',
    //       actions: '',
    //     }));
    //     $('#datatable').DataTable({
    //       "sScrollY": $(window).height() - 340,
    //       "searching": false,
    //       "ordering": false,
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
     async loadDevices() {
      try {
        await this.$store.dispatch(Environment.GET_ALL_DEVICEKEY_URL);
        $('#datatable').dataTable().fnDestroy();
        this.deviceKeyTableData = this.deviceKeys.results.map(device => ({
          deviceKeyId: device.id,
          name: device.name,
          key: device.key,
          companyId: device.companyId,
          companyName: device.company?.name || '',
          actions: '',
        }));
        $('#datatable').DataTable({
          // "sScrollY": $(window).height() - 340,
          "searching": false,
          "ordering": false,
        });
      } catch (error) {
        console.error(error);
      }
    },
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch(Environment.GET_ALL_DEVICEKEY_URL);
          }
      },
      optionsModalCheck(){
          this.modalOptions = !this.modalOptions;
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch(Environment.GET_ALL_DEVICEKEY_URL);
        }
      },
      videoModalCheck(){
        this.modalVideo = !this.modalVideo;
      },
      deleteMultiple() {
        let data = [];
        let table = document.getElementById("datatable");
          let checkboxes = table.getElementsByClassName("checkbox");
          for (let i = checkboxes.length - 1; i >= 0; i--) {
            if (checkboxes[i].type === "checkbox" && checkboxes[i].checked && checkboxes[i].id != null) {
              data.push(parseInt(checkboxes[i].id))
            }
          }
          if(data.length > 0 ){
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
        this.$store.dispatch('deviceKey/remove', data).then(result => {
              this.confirmModalCheck(false);
              document.getElementById('select-all').checked = false;
              this.$toast.error(`The device key has been deleted successfully!`, {
              position: 'top-right'
            });
          });
        }
        }
        else {
          this.confirmModalCheck(false)
          this.$toast.error(`You need to select at least one device key from records`, {
            position: 'top-right'
          });
        }
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
        if(item?.id){
          this.$store.dispatch('deviceKey/remove',  [item?.id]).then(result => {
            this.confirmModalCheck(false);
            this.$toast.success(`The device key has been deleted successfully !`, {
              position: 'top-right'
            });
            this.loadDevices();
          });
        }
        else {
          this.confirmModalCheck(false)
          this.$toast.error(`You need to select at least one device key from records`, {
            position: 'top-right'
          });
        }
        }
      },
      addItem() {
        this.edit_mode = false;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      },
      copyItem(item) {
        const text = item.key;
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
  }
}
</script>
<style scoped>
.multiselect {
  --ms-font-size: 16px;
  --ms-line-height: 1.275;
  --ms-bg: #ffffff;
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: #d1d5db;
  --ms-border-width: 1px;
  --ms-radius: 4px;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 3px;
  --ms-ring-color: #f1f3f5;
  --ms-placeholder-color: #9ca3af;
  --ms-max-height: 10rem;
}

.w-75 {
  width : 200px;
}

.multiselect-search {
  --ms-tag-font-size: 0.15rem;
  --ms-tag-line-height: 1.25rem;
  --ms-tag-bg-disabled: #9ca3af;
  --ms-tag-color: #ffffff;
  --ms-tag-color-disabled: #ffffff;
  --ms-tag-radius: 4px;
  --ms-tag-py: 0.125rem;
  --ms-tag-px: 0.5rem;
  --ms-tag-my: 0.25rem;
  --ms-tag-mx: 0.25rem;
}

.multiselect-option {
  --ms-option-font-size: 1rem;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #f1f3f5;
  --ms-option-color-selected: #ffffff !important;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-bg-selected-pointed: #f1f3f5;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;
}

.multiselect-option.is-selected.is-pointed {
  --ms-option-bg-selected-pointed: #f1f3f5 !important;
}

.multiselect-option.is-selected {
  --ms-option-color-selected: #ffffff !important;
}

.bg-green-500 {
  --ms-tag-bg: #ecf0f3;
}

.text-white {
  --ms-tag-color: #262626;
}

.btn-group > button {
  margin: 0 10px;
}
.flex-center{
display: flex !important;
align-items: center;
}
thead th {
  background-color: #f2f3f4;
  color: black;
}

.alerttitle{
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 2px;
  background-color: transparent;
  margin-left: 3px;
  font-size: 0.875em;
  padding-left: 10px;
}
.eye-delete{
display: flex;
justify-content: space-evenly;
align-items: center;
cursor: pointer;
}

table thead tr th{
background-color: #2C3E50;
color: #fff;
}
  .btn-group > button {
    margin: 0 10px;
  }
  td.status > span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
  }
  td.status > span.red{
    background-color: rgb(172, 32, 32);
  }
  td.status > span.green{
    background-color: rgb(38, 109, 16);
  }
  td.status > span.yellow{
    background-color: rgb(207, 167, 35);
  }
  .rounded-button {
    display: inline-block;
    padding: 3px 15px;
    border: none; 
    border-radius: 8px;
    background-color: #404040;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .rounded-button:hover {
    background-color: #1e282c;
  }
  
  table.dataTable tr th.select-checkbox.selected::after {
      content: "✔";
      margin-top: -11px;
      margin-left: -4px;
      text-align: center;
      text-shadow: rgb(176, 190, 217) 1px 1px, rgb(176, 190, 217) -1px -1px, rgb(176, 190, 217) 1px -1px, rgb(176, 190, 217) -1px 1px;
  }


  .multiselect .multiselect-tags .multiselect-tag {
    white-space: normal;
    overflow-y: scroll;
    max-height: 90px;
    background-color: #ecf0f3 !important;
  }
  .multiselect .multiselect-tags .multiselect-tag::-webkit-scrollbar {
    width: 10px;
  }
  .multiselect {
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
  }
  .multiselect-tag{
    /* padding-top: 20px; */
    background: white!important;
  }
  
  
  .multiselect .multiselect-search {
    width: calc(100% - 30px) !important;
  }

  .multiselect__input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  /* Style for the multiselect dropdown */
  .multiselect__dropdown {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    margin-top: 5px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  
  /* Style for the multiselect options */
  .multiselect__option {
    padding: 8px;
    cursor: pointer;
  }
  
  /* Style for the multiselect options when hovered */
  .multiselect__option:hover {
    background-color: #f2f2f2;
  }
  
  /* Style for the multiselect selected tags */
  .multiselect__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Style for the multiselect selected tag */
  .multiselect__tag {
    margin-right: 5px;
    background-color: #e0e0e0;
    color: #333;
    padding: 3px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  /* Style for the multiselect selected tag close button */
  .multiselect__tag-icon {
    margin-left: 5px;
    cursor: pointer;
  }
</style>

<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>Devices</h1>-->
<!--        </div>-->
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-shopping-bag"></i>
                <span>Devices</span>
              </a>
            </li>
            <li><a href="#">List</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-laptop"></i>
                  <span>Devices</span>
                </h3>
              </div>
              <div class="boxHeaderRight">

              </div>
            </div>
            <div class="boxBody">
<!--              <ul class="nav nav-tabs">-->
<!--                <li class="active"><a href="#DevicesOptionsFields-tab" data-toggle="tab">Analyzer Devices</a></li>-->
<!--              </ul>-->
              <div class="tab-content">
                <div class="tab-pane fade in active" id="DevicesOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
<!--                          data-toggle="collapse"-->
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            List
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM">
                            <thead>
                            <tr>
                              <th></th>
                              <th>
                                <div class="btn-group">
<!--                                  <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>-->
<!--                                  <button type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>-->
<!--                                  <button @click="this.polygonModalCheck" type="button" class="btn btn-info btn-flat">Palygon</button>-->
<!--                                  <button @click="this.confirmModalCheck" type="button" class="btn btn-info btn-flat">Confirm</button>-->
<!--                                  <button @click="this.videoModalCheck" type="button" class="btn btn-info btn-flat">Video</button>-->
                                </div>
                              </th>
                              <th colspan="4">
<!--                                <div class="pull-right">-->
<!--                                  <div class="input-group">-->
<!--                                    <label for="tableSearch" hidden=""></label>-->
<!--                                    <input id="tableSearch" type="text" class="form-control" placeholder="Search" />-->
<!--                                    <span class="input-group-btn">-->
<!--                                                                            <button id="btnGo" type="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>-->
<!--                                                                        </span>-->
<!--                                  </div>-->
<!--                                </div>-->
                              </th>
                            </tr>
                            <tr>
<!--                              <th>-->
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
<!--                              </th>-->
                              <th>Name</th>
                              <th>Hostname</th>
                              <th>Memory Usage</th>
                              <th>Status</th>
                              <th colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, i) in devices.results">
<!--                              <td>-->
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
<!--                              </td>-->
                              <td>{{ item.name }}</td>
                              <td  :ref="el => { if (el) el.key = item.key.key; deviceHostname[i] = el }">N/A</td>
                              <td :ref="el => { if (el) el.key = item.key.key; deviceMemory[i] = el }">N/A</td>
                              <td class="status">
                                <span :ref="el => {
                                  if (el) el.key = item.key.key; deviceState[i] = el }" class="yellow"></span>
                              </td>
                              <td>
                                <div class="btn-group btn-group-sm">
                                  <button @click="editItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>


                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                                                <span class="input-group-addon">
                                                                    <span>Show</span>
                                                                </span>
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control">
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <span class="input-group-addon">
                                                                    <span>Entries</span>
                                                                </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <!--  Modal options -->
  <DeviceModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <!--  Modal options -->
<!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
  <!--  Modal Video -->
<!--  <VVideoModal v-if="modalVideo" @cancel="this.videoModalCheck"/>-->
  <VPolygonModal v-if="modalPolygon" @confirm="confirmModalCallback" @cancel="polygonModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import DeviceModel from "./DeviceModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
import VPolygonModal from "../../components/v-polygon-modal"
import {
  onMounted,
  onBeforeUpdate,
  ref,
} from 'vue';
export default {
    components: {DeviceModel, VOptionsModal, VConfirmModal, VVideoModal, VPolygonModal},
    setup() {

      const deviceHostname = ref([]);
      const deviceState = ref([]);
      const deviceMemory = ref([]);

      // Make sure to reset the refs before each update.
      onBeforeUpdate(() => {
        deviceHostname.value = [];
        deviceState.value = [];
        deviceMemory.value = [];
      });

      return{
        deviceHostname,
        deviceState,
        deviceMemory,
      }
    },
    data() {


      return{
        modalAdd: false,
        modalOptions: false,
        modalConfirm: false,
        modalVideo: false,
        modalPolygon: false,
        edit_mode: false,
        edit_id: null,
        deviceStatuses: [],
        confirmModalCallback: () => {

        },
      }
    },
    computed: {
        devices () {
            return this.$store.state.device.all;
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
        deviceInfoUpdated: state => state.device.deviceInfoUpdated,
        deviceInfos: state => state.device.deviceInfos,
      })
    },
    created () {
        this.$store.dispatch('device/getAll');
    },
    mounted() {
    },
  unmounted() {
  },
  watch: {
    deviceInfoUpdated: function(val, old) {
      let self = this;
      this.deviceInfos.forEach((device_info) => {
// console.log(device_info.timestamp)
        let colorRef =  self.deviceState.filter(r => r && r.key === device_info.key);
        if (colorRef && colorRef.length) {
          colorRef[0].className = device_info.status === 1 ? 'green' : 'red';
        }
        let hostnameRef = self.deviceHostname.filter(r => r && r.key === device_info.key);
        if (hostnameRef && hostnameRef.length) {
          hostnameRef[0].innerHTML = device_info.hostname;
        }
        let deviceMemoryRef = self.deviceMemory.filter(r => r && r.key === device_info.key);
        if (deviceMemoryRef && deviceMemoryRef.length) {
          let used = (device_info.used_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          let total = (device_info.total_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          deviceMemoryRef[0].innerHTML = `${used}/${total} GB`;
        }
      })

    }
  },
  methods: {
      // This is called from the server through SignalR
      onScoreChanged ({data }) {
        let device_info = JSON.parse(data);


        let colorRef =  this.deviceState.filter(r => r && r.key === device_info.key);
        if (colorRef && colorRef.length) {
          colorRef[0].className = device_info.status === 1 ? 'green' : 'red';
        }
        let hostnameRef = this.deviceHostname.filter(r => r && r.key === device_info.key);
        if (hostnameRef && hostnameRef.length) {
          hostnameRef[0].innerHTML = device_info.hostname;
        }
        let deviceMemoryRef = this.deviceMemory.filter(r => r && r.key === device_info.key);
        if (deviceMemoryRef && deviceMemoryRef.length) {
          let used = (device_info.used_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          let total = (device_info.total_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          deviceMemoryRef[0].innerHTML = `${used}/${total} GB`;
        }
        console.log('device-info:', data);
      },
      getDeviceStatus(item) {
        return {
          color: status ? 'green' : 'red',
          name: status ? 'active' : 'inactive'
        };
      },
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch('device/getAll');
          }
      },
      polygonModalCheck(){
        this.modalPolygon = !this.modalPolygon;
      },
      optionsModalCheck(){
          this.modalOptions = !this.modalOptions;
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch('device/getAll');
        }
      },
      videoModalCheck(){
        this.modalVideo = !this.modalVideo;
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('device/remove',  [item.id]).then(result => {
            this.confirmModalCheck(false);
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      }
  }
};
</script>
<style scoped>
  .btn-group > button {
    margin: 0 10px;
  }
  td.status > span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
  }
  td.status > span.red{
    background-color: rgb(172, 32, 32);
  }
  td.status > span.green{
    background-color: rgb(38, 109, 16);
  }
  td.status > span.yellow{
    background-color: rgb(207, 167, 35);
  }
</style>
<template>

  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Source <i class="fa fa-television"></i><i/></h3>
      </div>
      <video v-if="!live || result " width="640"  controls :srcObject.prop="sourceObject" autoplay id="video">
        Your browser does not support the video tag.
      </video>
      <img v-if="live" :style="{
        width: 640,
        height: 480
      }" :src="url">

      <div class="footer">
        <div class="list">
        </div>
        <!--        <button class="btn btn-info btn-flat" @click="undo">Undo</button>-->
        <!--        <button class="btn btn-info btn-flat" @click="clear">Clear</button>-->

        <button v-if="!live" class="btn btn-info btn-flat" @click="startLive">{{ started ? 'Stop' : 'Start' }}</button>
<!--        <button v-if="live && recordStarted" class="btn btn-info btn-flat" @click="stopRecord">Stop</button>-->
        <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: false,
    },
    live: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
    analyzerId: {
      type: String,
      default: null,
    },
  },
  name: "v-options-modal",
  data() {
    return{
      sourceObject: null,
      recordStarted: false,
      videoSource: null,
      started: false,
    }
  },
  methods:{
    cancel() {
      this.$emit('cancel')
    },
    onIceCandidate(event) {
      console.log(`local candidate: ${JSON.stringify(event.candidate)}`);

      if (event.candidate == null) {
        console.log('null candidate');
        return;
      }

      var message = {
        id: this.analyzerId.toString(),
        sessionid: this.analyzerId.toString(),
        candidate: event.candidate.candidate
      }

      this.$sendCommand('VideoCandidate', message);
    },
    onLocalOfferCreated(err, sdpOffer) {
      if(err){
        console.error(err);
      }

      //
      // remove all other rtp profiles and request only h264
      //
      let split = sdpOffer.sdp.split('a=rtpmap:96 VP8/90000');
      var h264Sdp = split[0] + "a=rtpmap:96 H264/90000\n";
      sdpOffer.sdp = h264Sdp;

      this.webRtcPeer.setLocalDescription(sdpOffer);

      var message = {
        id: this.analyzerId.toString(),
        sessionid: this.analyzerId.toString(),
        sdpOffer: sdpOffer.sdp
      }

      console.log('sending local sdp offer');
      this.$sendCommand('VideoData', message)
    },
    stopLive() {
      this.webRtcPeer = null;
      this.$sendCommand('VideoStop', '')
    },
    startLive() {
      if (this.started) {
        this.webRtcPeer = null;
      } else {
        console.log('Creating WebRtcPeer and generating local sdp offer ...');

        //
        // Create the webRtcPeer instance
        //
        this.webRtcPeer = new RTCPeerConnection();

        this.webRtcPeer.onicecandidate = this.onIceCandidate;
        this.webRtcPeer.onopen = () => console.log('real time connection has established');
        this.webRtcPeer.onerror = (err) => console.log(`real time connection error ${err}`);
        this.webRtcPeer.onaddstream = (event) => {
          console.log('source object arrived', event)
          this.sourceObject = event.stream;
        }

        this.webRtcPeer.createOffer((offer) => this.onLocalOfferCreated(null, offer), (err) => this.onLocalOfferCreated(err, null), { offerToReceiveAudio: 0, offerToReceiveVideo: 1 });
        // this.$emit('cancel')
      }

      this.started = !this.started;
    },
    sdpOfferArrived(received) {
      console.log(`sdp received ${typeof received}`);
      this.webRtcPeer.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: received.data }));
    },
    iceCandidateArrived(received) {
      console.log(`ice can received ${typeof received}`);
      let candidate = JSON.parse(received.data);
      this.webRtcPeer.addIceCandidate(candidate, function(err){
        if(err){
          console.error('ERROR: ' + err);
        }
      });
    }
  },
  mounted() {
    if (!this.live)
      this.videoSource = this.$apiUrl + '/api/file/image/' + this.source?.file?.id;

    this.$questionHub.$on('sdp-offer', this.sdpOfferArrived)
    this.$questionHub.$on('ice-candidate', this.iceCandidateArrived)
  },
  unmounted() {
    this.$questionHub.$off('sdp-offer', this.sdpOfferArrived)
    this.$questionHub.$off('ice-candidate', this.iceCandidateArrived)
  },
  watch: {
    source : function(a) {
      if (!this.live)
        this.videoSource = this.$apiUrl + '/api/file/image/' + this.source?.file?.id;
    }
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > canvas{
  width: 640px;
  height: 480px;
}
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
</style>
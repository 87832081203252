<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>Analyzers</h1>-->
<!--        </div>-->
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-shopping-bag"></i>
                <span>Analyzers</span>
              </a>
            </li>
            <li><a href="#">List</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-shopping-table"></i>
                  <span>Analyzers</span>
                </h3>
              </div>
              <div class="boxHeaderRight">

              </div>
            </div>
            <div class="boxBody">
              <ul class="nav nav-tabs">
                <li class="active"><a href="#AnalyzersOptionsFields-tab" data-toggle="tab">Video Analyzers</a></li>
<!--                <li class=""><a href="#AnalyzersResultOptionsFields-tab" data-toggle="tab">Results</a></li>-->
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade in active" id="AnalyzersOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            List
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM">
                            <thead>
                            <tr>
                              <th>
                                <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>
                              </th>
                              <th>
                                <div class="btn-group">
<!--                                  <button type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>-->
<!--                                  <button @click="this.confirmModalCheck" type="button" class="btn btn-info btn-flat">Confirm</button>-->
<!--                                  <button @click="this.videoModalCheck" type="button" class="btn btn-info btn-flat">Video</button>-->
                                </div>
                              </th>
                              <th colspan="6">
<!--                                <div class="pull-right">-->
<!--                                  <div class="input-group">-->
<!--                                    <label for="tableSearch" hidden=""></label>-->
<!--                                    <input id="tableSearch" type="text" class="form-control" placeholder="Search" />-->
<!--                                    <span class="input-group-btn">-->
<!--                                                                            <button id="btnGo" type="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>-->
<!--                                                                        </span>-->
<!--                                  </div>-->
<!--                                </div>-->
                              </th>
                            </tr>
                            <tr>
<!--                              <th>-->
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
<!--                              </th>-->
                              <th>Name</th>
                              <th>Device Name</th>
                              <th>Source Name</th>
                              <th>Type</th>
                              <th>Results</th>
                              <th>Actions</th>
                              <th colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in analyzers.results">
<!--                              <td>-->
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
<!--                              </td>-->
                              <td>{{ item.name }}</td>
                              <td>{{item.device?.name}} (<span v-if="item.device">{{ getDeviceState(item, deviceInfoUpdated) === 0 ? 'Offline' : 'Online' }}</span>)</td>
                              <td>{{item.source?.name}}
                                <span v-bind:style="{cursor: 'pointer'}" @click="viewSourceOpen(item.source)"><i class="fa fa-television"></i></span>
                              </td>
<!--                              <td>-->
<!--                                <router-link  :to=>-->
<!--                                   Filters-->
<!--                                </router-link></td>-->
                              <td>{{item.type === 1 ? 'Normal' : 'Thermal'}}</td>
                              <td>
                                <span v-if="!(getAnalyzerResults(item, analyzerInfoUpdated)).length">No Results Yet</span>
                                <span v-if="getState(item, analyzerInfoUpdated) === 2" v-bind:style="{cursor: 'pointer'}" @click="viewLiveOpen(item)"><img src="/img/live.png" width="30" height="30"/></span>
                              </td>
                              <td>
<!--                                <button v-if="getState(item, analyzerInfoUpdated) === 3" @click="analyzerResultPress(item)" type="button" class="btn btn-info btn-flat">-->
<!--                                  Results</button>-->

<!--                                <span >state:{{getState(item, analyzerInfoUpdated)}}</span>-->
<!--                                <button @click="resultModalCheck(true, item.id)" type="button" class="btn btn-info btn-flat">-->
<!--                                  Results</button>-->
<!--                                <span  v-if="getState(item, analyzerInfoUpdated) === 2">{{getProgress(item, analyzerInfoUpdated)}} - {{getDuration(item, analyzerInfoUpdated)}}</span>-->
          <progress v-if="getState(item, analyzerInfoUpdated) === 2" :max="getDuration(item, analyzerInfoUpdated)" :value.prop="getProgress(item, analyzerInfoUpdated)"></progress>

                                <button v-if="getState(item, analyzerInfoUpdated) === 0 || getState(item, analyzerInfoUpdated) === 1  || getState(item, analyzerInfoUpdated) === 3" @click="analyzerBtnPress(item)" type="button" class="btn btn-success btn-flat">
                                  <i class="fa" :class="{
                                    'fa-play-circle': getState(item, analyzerInfoUpdated) !== 2,
                                    'fa-stop-circle': getState(item, analyzerInfoUpdated) === 2,
                                  }"></i>
                                </button>
                              </td>
                              <td>
                                <div class="btn-group btn-group-sm">
                                  <button @click="goFilter(item)" type="button" title="Filters" class="btn btn-warning btn-flat"><i class="fa fa-filter"></i></button>
                                  <button @click="editItem(item)" type="button" title="Edit" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteItem(item)" type="button" title="Delete" class="btn btn-danger btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                                                <span class="input-group-addon">
                                                                    <span>Show</span>
                                                                </span>
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control">
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <span class="input-group-addon">
                                                                    <span>Entries</span>
                                                                </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <!--  Modal options -->
  <AnalyzerModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <ViewSourceModal :source="viewSource" v-if="viewSourceShow" @cancel="viewSourceClose()"/>
  <ViewSourceModal :source="viewLiveSource" :live="true" :url="viewLiveSource" v-if="viewLiveShow" @cancel="viewLiveClose()"/>
  <!--  Modal options -->
  <ResultModal :edit_mode="false" :id="edit_id" v-if="modalResult" @cancel="resultModalCheck(false)"/>
  <!--  Modal options -->
<!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
  <!--  Modal Video -->
<!--  <VVideoModal v-if="modalVideo" @cancel="this.videoModalCheck"/>-->
</div>
</template>

<script>
import { mapState } from 'vuex'
import AnalyzerModel from "./AnalyzerModal"
import ResultModal from "./ResultModal"
import ViewSourceModal from "./ViewSourceModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
export default {
    components: {AnalyzerModel, ResultModal, VOptionsModal, VConfirmModal, VVideoModal, ViewSourceModal},
    data() {
      return{
        modalAdd: false,
        modalOptions: false,
        modalConfirm: false,
        modalVideo: false,
        modalResult: false,
        edit_mode: false,
        edit_id: null,
        viewSourceShow: false,
        viewSource: null,
        viewLiveShow: false,
        viewLiveSource: null,
        analyzerStatuses: [],
        confirmModalCallback: () => {

        }
      }
    },
    computed: {
        analyzers () {
            return this.$store.state.analyzer.all;
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
        analyzerInfoUpdated: state => state.analyzer.analyzerInfoUpdated,
        deviceInfoUpdated: state => state.device.deviceInfoUpdated,
        analyzerInfos: state => state.analyzer.analyzerInfos,
        deviceInfos: state => state.device.deviceInfos,
      })
    },
    created () {
        this.$store.dispatch('device/getAll');
    },
    mounted() {
    },
  unmounted() {
  },
  watch: {
    deviceInfoUpdated: function(val) {
    }
  },
    methods: {
      getProgress(item, update) {
        for(let i in this.analyzerInfos) {
          let cur = this.analyzerInfos[i];
          if (cur.analyzer.Id === item.id){
            return cur.progress;
          }
        }
        return 0;
      },
      getDuration(item, update) {
        for(let i in this.analyzerInfos) {
          let cur = this.analyzerInfos[i];
          if (cur.analyzer.Id === item.id){
            return cur.duration;
          }
        }
        return 0;
      },
      getState(item, update) {
        for(let i in this.analyzerInfos) {
          let cur = this.analyzerInfos[i];
          if (cur.analyzer.Id === item.id){
            return cur.analyzer.State;
          }
        }
        return item.state;
      },
      getDeviceState(item, updated) {
        for(let i in this.deviceInfos) {
          if (this.deviceInfos[i].key === item.device.key.key) {
            return this.deviceInfos[i].status;
          }
        }
        return 0;
      },
      openResultModal() {

      },
      startAnalyzer(start, item) {
        // this.$questionHub.sendCommand(start ? "Start" : "Stop", item.id)
      },
      goFilter(item) {
        this.$router.push({ path: '/alertFilter/'+item.id }) // -> /user/123
      },
      analyzerBtnPress(item) {
        let action = 'start';
        this.$store.dispatch(`device/${action}`, {id: item.id}).then(data => {
          this.$toast.success(`Analyzer added to ${action} queue`, {
            position: 'top-right'
          });
        }, error => {
          this.$toast.error(`Analyzer failed to ${action} queue`, {
            position: 'top-right'
          });
        })
        // this.$questionHub.sendCommand(start ? "Start" : "Stop", item.id)
      },
      // This is called from the server through SignalR
      onScoreChanged ({data }) {
        let device_info = JSON.parse(data);


        let colorRef =  this.analyzerState.filter(r => r && r.key === device_info.key);
        if (colorRef && colorRef.length) {
          colorRef[0].className = device_info.status === 1 ? 'green' : 'red';
        }
        let hostnameRef = this.analyzerProgress.filter(r => r && r.key === device_info.key);
        if (hostnameRef && hostnameRef.length) {
          hostnameRef[0].innerHTML = device_info.hostname;
        }
        let deviceMemoryRef = this.deviceMemory.filter(r => r && r.key === device_info.key);
        if (deviceMemoryRef && deviceMemoryRef.length) {
          let used = (device_info.used_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          let total = (device_info.total_memory * (1/1024) * (1/1024) * (1/1024)).toFixed(2)
          deviceMemoryRef[0].innerHTML = `${used}/${total} GB`;
        }
        // console.log('device-info:', data);
      },
      getAnalyzerStatus(item) {
        let list = this.analyzerStatuses.filter(r => r.id === item.id);
        if (list.length) {
          return list[0].status;
        }
        return false;
      },
      getAnalyzerResults(item, updated) {
        let list = this.analyzerInfos.filter(r => r.analyzer.Id === item.id);
        if (list.length) {
          return list[0].analyzer.AnalyzerResults;
        }
        return item.analyzerResults;
      },
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch('device/getAll');
          }
      },
      analyzerResultPress(item){
        if (item.analyzerResults.length) {
          let last = item.analyzerResults[item.analyzerResults.length - 1];
          console.log(last)
        }
          // this.modalResult = true;
          // this.currentResult = item;
          //
          // if (!this.modalAdd) {
          //   this.$store.dispatch('analyzer/getAll');
          // }
      },
      getButtonName(item) {
      },
      resultModalCheck(open, id){
          if (open) {
            this.edit_mode = true;
            this.edit_id = id;
          }
          this.modalResult = open;
          // if (!this.modalAdd) {
          //   this.$store.dispatch('analyzer/getAll');
          // }
      },
      optionsModalCheck(){
          this.modalOptions = !this.modalOptions;
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch('device/getAll');
        }
      },
      videoModalCheck(){
        this.modalVideo = !this.modalVideo;
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('device/remove',  [item.id]).then(result => {
            this.confirmModalCheck(false);
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.addModalCheck(true);
      },
      viewSourceOpen(source) {
        this.viewSourceShow = true;
        this.viewSource = source;
      },
      viewSourceClose() {
        this.viewSourceShow = false;
        this.viewSource = null;
      },
      viewLiveOpen(source) {
        this.viewLiveShow = true;
        this.viewLiveSource = 'http://localhost:9090/live'
      },
      viewLiveClose() {
        this.viewLiveShow = false;
        this.viewLiveSource = null;
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      }
  }
};
</script>
<style scoped>
  .btn-group > button {
    margin: 0 10px;
  }
    td.status > span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
  }
  td.status > span.red{
    background-color: rgb(172, 32, 32);
  }
  td.status > span.green{
    background-color: rgb(38, 109, 16);
  }
  td.status > span.yellow{
    background-color: rgb(207, 167, 35);
  }
</style>
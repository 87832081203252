<template>
  <div>
    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-folder"></i>
                  <span>Alerts Page</span>
                </a>
              </li>
              <li><a href="#">Manager</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">
        <div class="col-xs-12">
        </div>
        <div class="col-xs-12">
          <section class="boxWrap">
            <div class="box">
              <div class="boxHeader">
                <div class="boxHeaderLeft">
                  <h3>
                    <i class="fa fa-shopping-bag"></i>
                    <span>Alerts Page
                    </span>
                  </h3>
                </div>
                <div class="boxHeaderRight d-flex align-items-flex-start">
                    <input size="18" class="alerttitle" type="search" v-model="searchQuery" placeholder="Search By Alert Title" aria-label="Search">
                <router-link to="/alert-calendar-view" title="Calendar View"><span style="display: inline-block; padding-left:10px; margin-top:3px;"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-calendar-date" viewBox="0 0 16 16">
                  <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
              </svg></span></router-link>
                </div>
              </div>
              <div class="boxBody">
  
                <div id="highcharts-1"></div>
                <div class="tab-content">
                  <div class="tab-pane fade in active" id="productsManagerGallery-tab">
                    <section class="productsGalleryWrap">
                      <table class="table table-hover table-bordered display nowrap" id="datatable" aria-label="datatable">
                        <thead>
                          <tr>
                            <th>Polygon Name</th>
                            <th>Alert Title</th>
                            <th>Threshold</th>
                            <th>Timestamp</th>
                            <th>Days</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="alert in searchNode" :key="alert?.alertId">
                            <td>{{alert?.polygonName}}</td>
                            <td>{{alert?.alertTitle}}</td>
                            <td>{{alert?.threshold2}}</td>
                            <td>{{alert?.timestamp}}</td>
                            <td :title="alert?.days">{{alert?.days}}</td>
                            <td class="eye-delete">
                              <div type="button" style="padding-right:10px; display: inline-block;" title="View" @click="this.showDetail(alert)"><i class="fa fa-eye"></i></div>
                              <div type="button" style="padding-right:40px; display: inline-block;" title="Delete" @click="this.deleteItem(alert)"><i class="fa fa-trash"></i></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <section class="productsGalleryWrapBody">
                        <div class="row" id="productsGalleryWrapBodyRow">
                        </div>
                      </section>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div v-for="item in alertFilters" v-bind:key="item?.id">
            <AlertItem :item="item" />
          </div>
        </div>
      </div>
    </section>
    <AlertManageModal v-if="modalAlertManage" @cancel="addAlertModalCheck(false)"/>
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" checkMessage="Are you sure you want to delete the alert ?" @cancel="confirmModalCheck(false)"/>
    <AlertDetailModal v-if="modalAlertDetail" :alert="this.currentAlert" @cancel="cancelModal(false)"/>
  </div>
  </template>
  
  <script>
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "datatables.net-dt/js/dataTables.dataTables"
  import $ from 'jquery'; 
  import { mapState } from 'vuex'
  import VConfirmModal from "../../components/v-confirm-modal"
  import AlertManageModal from "./AlertManageModal"
  import AlertItem from "./AlertItem"
  import AlertDetailModal from "./AlertDetailModal"
  import setActive from "../../mixins/globalFunction.js";
  export default {
    components: {VConfirmModal, AlertManageModal, AlertItem ,AlertDetailModal},
    mixins: [setActive],
    data() {
      return{
        alertTableData: [],
        tableData: [],
        analyzer: {},
        currentAlert: {},
        searchQuery:null,
        modalAdd: false,
        modalConfirm: false,
        modalAlertManage: false,
        modalAlertDetail: false,
        edit_mode: false,
        edit_id: null,
        value: [],
        sourceStatuses: [],
        confirmModalCallback: () => {
  
        }
      }
    },
    computed: {
      searchNode() {
        this.tableData = this.alertTableData.slice();
        let query = this.searchQuery;
        if (query) {
                return this.tableData.filter(alert => ((alert.alertTitle).toLowerCase().includes(query.replace(/ +/g, "").toLowerCase())) )
            } else {
                return this.tableData
            }
      },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
        alertFilters: state => state.alertFilter.all,
      })
    },
    created () {
    },
    mounted() {
      this.setActive("AlertSchedule");
      this.getAlerts();
    },
    methods: {
      showDetail(alert){
        this.currentAlert = alert;
        this.modalAlertDetail = true;
      },
      cancelModal(){
        this.modalAlertDetail = !this.modalAlertDetail;
      },
      addAlertModalCheck(open){
        this.modalAlertManage = open;
        if (!this.modalAlertManage) {
          this.loadingList = true;
          this.getAlerts();
        }
      },
      getAlerts() {
        this.$store.dispatch('alertFilter/getAllAlerts', this.analyzerId).then((response => {
          this.alertTableData = [];
          $('#datatable').dataTable().fnDestroy();
          for (var i = 0; i < response.length; i++) {
            var alert = {
              date: response[i]?.creationDate,
              alertId: response[i]?.id,
              deviceName: response[i]?.device?.name,
              polygonName: response[i]?.polygon?.name,
              alertTitle: response[i]?.name,
              companyId: response[i]?.device?.companyId,
              userName: response[i]?.user?.userName,
              threshold: response[i]?.type1Threshold,
              threshold2: response[i]?.type2Threshold/10+"°C",
              timestamp: response[i]?.start+" - "+response[i]?.end,
              days: this.getDay(response[i]?.days),
              actions: ""
            };
            this.alertTableData.push(alert);
          }
          setTimeout(() => {
            $('#datatable').DataTable({
              // "sScrollY": ($(window).height() - 340),
              "searching": false,
              "oredering": false,
            });
          }, 100);
        }).bind(this));
      },
      getDay (date) {
      let daysArr = JSON.parse(date.replaceAll('"', ""));
    
      let dayValue = "";
      let days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday",];
      for (let i = 0; i < daysArr.length; i++) {
        if(i == 0 ){
          if(daysArr[i]== '7'){
            dayValue= "Alldays"
            break;
          }
          else{
            dayValue = dayValue + days[daysArr[i]];
          }
        }
        else {
        dayValue = dayValue +", "+ days[daysArr[i]];
        }
      }
      return dayValue;
    },
      watch: {
        alertFilters: function() {
        }
      },
      // This is called from the server through SignalR
      addModalCheck(open){
        this.modalAdd = open;
        if (!this.modalAdd) {
        }
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {
  
          }
        }
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('alertFilter/remove',[item.alertId]).then(result => {
            this.confirmModalCheck(false);
            this.getAlerts();
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      },
      initChart() {
  
  
        if (this.analyzer.analyzerAlerts) {
          let drawList = [];
          for(let i in this.analyzer.analyzerAlerts) {
            let alert = this.analyzer.analyzerAlerts[i];
  
            let data = [];
            for(let j in this.alerts) {
              let curAlert = this.alerts[j];
  
              if (curAlert.key === alert.id.toString()) {
                data.push(parseInt((parseInt(curAlert.temp) - 32)/1.8));
              }
            }
            let meterObject = {
              name: alert.name,
              data: data.reverse(),
              pointStart: alert.type2Threshold,
              pointInterval: 3600 * 1000 * 24 // one hour
            };
            drawList.push(meterObject)
  
  
            Highcharts.chart({
  
              chart: {
                renderTo: "highcharts-1"
              },
  
              title: {
                text: "Client Statistics"
              },
  
              subtitle: {
                text: ""
              },
  
              xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                  day: '%H:%M'
                }
              },
              yAxis: {
                title: {
                  text: "Metric"
                }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle"
              },
              useUTC: true,
  
              plotOptions: {
                series: {
                  pointStart: 2010
                }
              },
  
              series: drawList
            });
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  .btn-group > button {
      margin: 0 10px;
    }
  .flex-center{
    display: flex !important;
    align-items: center;
  }
  thead th {
      background-color: #f2f3f4;
      color: black;
  }
  
  .alerttitle{
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 2px;
  background-color: transparent;
  margin-left: 3px;
  font-size: 0.875em;
  padding-left: 10px;
}
  .eye-delete{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }
  table thead tr th{
    background-color: #2C3E50;
    color: #fff;
  }
  table.dataTable.display>tbody>tr.odd>.sorting_1{
    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023) !important;
  }
  </style>
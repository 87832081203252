import axios from "axios";

const UserService = (function() {
    var _service;

    function _getService() {
        if (!_service) {
            _service = this;
            return _service;
        }
        return _service;
    }

    async function getUser(apiUrl) {

        return await axios
            .get(
                apiUrl
            )
            .then((response) => {
                return response
            })
            .catch(() => {
                return;
            })
    }

    async function editUser(apiUrl,userObj) {

        return await axios
            .put(
                apiUrl,
                userObj,
            )
            .then((response) => {
                return response
            })
            .catch(() => {
                return;
            })
    }

    async function editPassword(apiUrl,passwordObj) {

        return await axios
            .post(
                apiUrl,
                passwordObj,
            )
            .then((response) => {
                return response
            })
            .catch(() => {
                return;
            })
    }

    return {
        getService: _getService,
        getUser: getUser,
        editUser: editUser,
        editPassword: editPassword
    };
})();

    export default UserService;
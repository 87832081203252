<template>
    <div class="modal-options">
    <div class="content">
      <div class="header panel-heading">
        <h5 style="text-transform:capitalize; padding-left:10px; color: #C8C9CE;">Device Key Details </h5>
      </div>
      <div class="footer" style="position: relative;">
      <table id="datatable" class="table table-hover table-bordered display nowrap" aria-label="devicekey">
        <tbody>
        <th></th>
          <tr>
          <td class="border-0">Device Key Value :</td>
            <td class="border-0">{{item?.key}}</td>
          </tr>
          <tr><td class="border-0">Device Key Name :</td>
            <td class="border-0">{{item?.name}}</td>
          </tr>
          <tr>
          <td class="border-0">Company Name :</td>
            <td class="border-0">{{item?.company?.name}}</td>
          </tr>
        </tbody>
      </table>
        <div><button type="button" style="border-radius: 5px; color: #C8C9CE; background-color: #272c33; float: right; position: absolute; bottom: 20px; right: 20px;" class="closeBtn" title="Close" @click="this.cancel">close</button></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeviceKeyDetailModal",
  props:{
    item :{ 
      type:Object
    },
    modalKeyDeviceDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    cancel(){
      this.$emit('cancel')
    },
  }
}
</script>
<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right:0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 450px;
  border-radius: 0;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #2C3E50;
  color: black;
  font-weight: 600;
  font-size: 20px;
  border: 1px solid black;
}
.content > .header>*{
  margin-bottom: 0 !important;
  text-align:left;
  text-transform: capitalize;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: left;
  border: 1px solid black;
  height: 175px;
}
.content > .footer > *{
  margin-left: 0.5rem;
  text-transform: capitalize;
}

.deviceModal{
  padding: 10px;
  list-style-type:none;
  vertical-align: middle;
  display: flex;
}
.textdata{
  font-size: 18px;
  padding-left: 10px;
}
</style>
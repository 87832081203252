<template>
  <div id="app">
    <section id="wrap">
      <header v-show="loggedIn" class="color-white-bg">
        <section class="headerLeft color-sidebar-bg overflow-hidden">
          <div class="mtadminLogo">
            <a href="#">
              <img src="@/assets/img/full-colour-logo-light.png" style="margin-left: 6px;" alt="" />
<!--              <span>Zero Parralax</span>-->
            </a>
          </div>
        </section>
        <section class="headerRight">
          <div class="headerRight-Left">
            <div>
              <a href="#" class="sideMenuToggle color-white-hover-bg" title="Toggle Sidebar">
                <i class="fa fa-bars"></i>
              </a>
            </div>
          </div>
          <div class="headerRight-Right">
            <!-- <div class="hdrLng dropdown">
              <a id="hdrLngToggle" class="hdrLngToggle color-white-hover-bg" title="Language" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>En</span>
              </a>
              <ul id="hdrLngWrap" class="dropdown-menu dropdown-menu-custom hdrDropdown lngDropdown" aria-labelledby="hdrLngToggle">
                <li class="dropdown-menu-body">
                  <div>
                    <ul class="hdrTasksMenu">
                      <li><a href="#">En</a></li>
                      <li><a href="#">Ru</a></li>
                      <li><a href="#">Hy</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div> -->
            <!-- <div class="hdrMsg dropdown">
              <a id="hdrMsgToggle" class="hdrMsgToggle color-white-hover-bg" title="Messages" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-envelope"></i>
                <span>12</span>
              </a>
              <ul id="hdrMsgWrap" class="dropdown-menu dropdown-menu-custom hdrDropdown" aria-labelledby="hdrMsgToggle">
                <li class="dropdown-menu-header">
                  <h2>4 new messages</h2>
                </li>
                <li class="dropdown-menu-body">
                  <div>
                    <ul>
                      <li>
                        <a href="#">
                          <img src="img/users/user-1.jpg" alt=""/>
                          <h2>Mher Sargsyan</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Now</span>
                          </h3>
                          <p>Gordz ara!</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="img/users/user-6.jpg" alt=""/>
                          <h2>Grzegorz Brzeczyszczykiewicz</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Today 15:30</span>
                          </h3>
                          <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="img/users/user-8.jpg" alt=""/>
                          <h2>Artavazd Yeghiazaryan</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Today 15:30</span>
                          </h3>
                          <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="img/users/user-2.jpg" alt=""/>
                          <h2>Artavazd Yeghiazaryan</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Today 15:30</span>
                          </h3>
                          <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="img/users/user-6.jpg" alt=""/>
                          <h2>Grzegorz Brzeczyszczykiewicz</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Today 15:30</span>
                          </h3>
                          <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="img/users/user-8.jpg" alt=""/>
                          <h2>Artavazd Yeghiazaryan</h2>
                          <h3>
                            <i class="fa fa-clock-o"></i>
                            <span>Today 15:30</span>
                          </h3>
                          <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="dropdown-menu-footer">
                  <a href="#">See All</a>
                </li>
              </ul>
            </div> -->
            <div class="hdrNtf hdrIcons">
              <div>
                <a href="#" class="color-white-hover-bg" title="Settings" style="display: flex; align-items: center;">
                  <span v-if="hubState === 'Connected'"><img src="/img/wifi-connected.png" width="18" height="18"/></span>
                  <span v-if="hubState === 'Disconnected'"><img src="/img/wifi-disconnected.png" width="18" height="18"/></span>
                </a>
              </div>
            </div>
            <div class="hdrNtf dropdown hdrIcons">
              <a id="hdrNtfToggle" class="hdrNtfToggle color-white-hover-bg" title="Notifications" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-bell"></i>
                <span>{{ notifications.length }}</span>
              </a>
              <ul id="hdrNtfWrap" class="dropdown-menu dropdown-menu-custom hdrDropdown not-center" aria-labelledby="hdrNtfToggle">
                <li class="dropdown-menu-header">
                  <h2>You have {{ notifications.length }} notifications</h2>
                </li>
                <li class="dropdown-menu-body">
                  <div>
                    <ul class="hdrNtfMenu" v-for="note in notifications" v-bind:key="note.id">
<!--                      <li><a href="#"><i class="fa fa-users color-2"></i> 5 new members joined today</a></li>-->
<!--                      <li><a href="#"><i class="fa fa-warning color-orange"></i> Very long description here that may not fit-->
<!--                        into the page and may cause design problems</a></li>-->
<!--                      <li><a href="#"><i class="fa fa-users color-red"></i> 5 new members joined</a></li>-->
                      <li @click="addDeviceModalOpen(note)"><a href="#"><i class="fa fa-shopping-cart" :class="{ 'color-green': note.type === 1}"></i>  {{ note.message }}</a></li>
<!--                      <li><a href="#"><i class="fa fa-user color-red"></i> You changed your username</a></li>-->
                    </ul>
                  </div>
                </li>
                <li class="dropdown-menu-footer">
                <router-link tag="a" to="/notifications" >View all</router-link></li>
              </ul>
            </div>
            <div class="hdrUser dropdown">
              <a id="hdrUSerToggle" class="hdrUserToggle color-white-hover-bg hdrIcons" title="User" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img :src="(user && user.user && user.user.avatar ? $apiUrl + '/api/file/image/' + user.user.avatar : '/img/avatar.png')" width="18" height="18" alt=""/>
                <h2><span>{{ (user && user.user ? user.user.userName : null) }}</span></h2>
              </a>
              <ul id="hdrUserWrap" class="dropdown-menu dropdown-menu-custom dropdown-menu-right userDropdown">
                <li class="dropdown-menu-header color-1-bg">
                  <img  :src="(user && user.user && user.user.avatar ? $apiUrl + '/api/file/image/' + user.user.avatar : '/img/avatar.png')" class="img-circle" alt=""/>
                  <p>{{(user && user.user ? user.user.userName : null)}}<br/>{{ (user && user.user ? user.user.email : null) }}
<!--                    <br/>Member since Nov. 2012-->
                  </p>
                </li>
                <li class="dropdown-menu-body userMenuBody">
                  <div>
<!--                    <ul class="hdrUserMenu">-->
<!--                      <li>-->
<!--                        <div class="row">-->
<!--                          <div class="col-xs-12 col-sm-4 text-center">-->
<!--                            <a href="#" class="color-black-50 color-info-hover">Link 1</a>-->
<!--                          </div>-->
<!--                          <div class="col-xs-12 col-sm-4 text-center">-->
<!--                            <a href="#" class="color-black-50 color-info-hover">Link 2</a>-->
<!--                          </div>-->
<!--                          <div class="col-xs-12 col-sm-4 text-center">-->
<!--                            <a href="#" class="color-black-50 color-info-hover">Link 3</a>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
                  </div>
                </li>
                <li class="dropdown-menu-footer userMenuFooter">
                  <!-- <div class="pull-left">
                    <button type="button" class="btn btn-sm btn-flat color-2-bg color-2-hover-bg color-text-white">Profile
                    </button>
                  </div> -->
                  <div class="pull-right">
                    <router-link to="/profile-setting"><button  class="btn btn-sm btn-flat color-info-bg color-info-hover-bg color-text-white">Profile Setting</button></router-link>
                    <button  class="btn btn-sm btn-flat color-info-bg color-info-hover-bg color-text-white" @click="logout()">Logout</button>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <a href="#" class="sideMenuToggle color-white-hover-bg" title="Settings">
                <i class="fa fa-cog" aria-hidden="true" width="18" height="18"></i>
              </a>
            </div>
          </div>
        </section>
      </header>
      <section v-show="loggedIn" class="mtSidebar">
        <div class="sidebar-menu">
          <div class="panel-group menubar" role="tablist" aria-multiselectable="true">
          <ul class="container-fluid nav" id="nav">
            <li class="nav-item" id="Dashboard" @click="setActive('Dashboard')">
            <div class="panel panel-default panel-bg">
              <div class="panel-heading" role="tab" id="heading-dashboard">
                <h4 class="panel-title">
                  <router-link tag="a" to="/" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-dashboard"></i>
                      <span>Dashboard</span>
                    </div>
                    <!--                    <div class="panel-title-right">-->
                    <!--                      <span class="label color-success-bg">4</span>-->
                    <!--                    </div>-->
                  </router-link>
                </h4>
              </div>
              <div id="collapse-dashboard" aria-labelledby="heading-dashboard" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/">Dashboard</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </li>
<!--            <div class="panel panel-default panel-bg">-->
<!--              <div class="panel-heading" role="tab" id="heading-3">-->
<!--                <h4 class="panel-title sidebar-title">-->
<!--                  <a class="collapsed" href="#collapse-3" aria-controls="collapse-3" data-parent="#accordion" role="button" data-toggle="collapse" aria-expanded="true">-->
<!--                    <div class="panel-title-left">-->
<!--                      <i class="fa fa-pie-chart"></i>-->
<!--                      <span>Charts</span>-->
<!--                    </div>-->
<!--                    <div class="panel-title-right"></div>-->
<!--                  </a>-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div id="collapse-3" aria-labelledby="heading-3" class="panel-collapse collapse" role="tabpanel">-->
<!--                <div class="panel-body">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <router-link to="/charts">-->
<!--                        <span>ChartsJS</span>-->
<!--                      </router-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="panel panel-default panel-bg">-->
<!--              <div class="panel-heading" role="tab" id="heading-device">-->
<!--                <h4 class="panel-title">-->
<!--                  <router-link tag="a" to="/device" role="button" aria-expanded="false">-->
<!--                    <div class="panel-title-left">-->
<!--                      <i class="fa fa-laptop"></i>-->
<!--                      <span>Devices</span>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="panel-title-right">&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="label color-success-bg">4</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div id="collapse-device" aria-labelledby="heading-device" class="panel-collapse collapse" role="tabpanel">-->
<!--                <div class="panel-body">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <router-link to="/device">Device</router-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
           <li class="nav-item" id="DeviceKeys" @click="setActive('DeviceKeys')">
           <div class="panel panel-default panel-bg">
             <div class="panel-heading" role="tab" id="heading-deviceKey">
               <h4 class="panel-title">
                 <router-link tag="a" to="/deviceKey" role="button" aria-expanded="false">
                   <div class="panel-title-left">
                     <i class="fa fa-lock"></i>
                     <span>Devices Keys</span>
                   </div>
                   <!-- &lt;!&ndash;                    <div class="panel-title-right">&ndash;&gt;
                   &lt;!&ndash;                      <span class="label color-success-bg">4</span>&ndash;&gt;
                   &lt;!&ndash;                    </div>&ndash;&gt; -->
                 </router-link>
               </h4>
             </div>
             <div id="collapse-deviceKey" aria-labelledby="heading-deviceKey" class="panel-collapse collapse" role="tabpanel">
               <div class="panel-body">
                 <ul>
                   <li>
                     <router-link to="/deviceKey">Devices Keys</router-link>
                   </li>
                 </ul>
               </div>
             </div>
           </div>
           </li>
<!--            <div class="panel panel-default panel-bg">-->
<!--              <div class="panel-heading" role="tab" id="heading-stream">-->
<!--                <h4 class="panel-title">-->
<!--                  <router-link tag="a" to="/stream" role="button" aria-expanded="false">-->
<!--                    <div class="panel-title-left">-->
<!--                      <i class="fa fa-folder"></i>-->
<!--                      <span>Streams</span>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="panel-title-right">&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="label color-success-bg">4</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div id="collapse-stream" aria-labelledby="heading-stream" class="panel-collapse collapse" role="tabpanel">-->
<!--                <div class="panel-body">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <router-link to="/stream">Stream</router-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <li class="nav-item" id="AlertSchedule" @click="setActive('AlertSchedule')">
            <div class="panel panel-default panel-bg">
              <div class="panel-heading" role="tab" id="heading-alert">
                <h4 class="panel-title">
                  <router-link tag="a" to="/alerts" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-clock-o"></i>
                      <span>Alert Schedule</span>
                    </div>
                   <!-- <div class="panel-title-right">
                   <span class="label color-success-bg">4</span>
                    </div> -->
                  </router-link>
                </h4>
              </div>
              <div id="collapse-alert" aria-labelledby="heading-alert" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/alerts">Alert Schedule</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </li>
            <li class="nav-item" id="Alerts" @click="setActive('Alerts')">
            <div class="panel panel-default panel-bg">
              <div class="panel-heading" role="tab" id="heading-alert">
                <h4 class="panel-title">
                  <router-link tag="a" to="/generated-alerts" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span>Alerts</span>
                    </div>
                   <!-- <div class="panel-title-right">
                   <span class="label color-success-bg">4</span>
                    </div> -->
                  </router-link>
                </h4>
              </div>
              <div id="collapse-alert" aria-labelledby="heading-alert" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/generated-alerts">Alerts</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </li>
<!--            <div class="panel panel-default panel-bg">-->
<!--              <div class="panel-heading" role="tab" id="heading-analyzer">-->
<!--                <h4 class="panel-title">-->
<!--                  <router-link tag="a" to="/analyzer" role="button" aria-expanded="false">-->
<!--                    <div class="panel-title-left">-->
<!--                      <i class="fa fa-table"></i>-->
<!--                      <span>Analyzers</span>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="panel-title-right">&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="label color-success-bg">4</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div id="collapse-analyzer" aria-labelledby="heading-analyzer" class="panel-collapse collapse" role="tabpanel">-->
<!--                <div class="panel-body">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <router-link to="/analyzer">Analyzer</router-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <!-- <div class="panel panel-default panel-bg">
              <div class="panel-heading" role="tab" id="heading-notification">
                <h4 class="panel-title">
                  <router-link tag="a" to="/notifications" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-thumb-tack"></i>
                      <span>Notifications</span>
                    </div>
                    <div class="panel-title-right" v-show="notifications.length">
                      <span class="label color-success-bg">{{ notifications.length }}</span>
                    </div>
                  </router-link>
                </h4>
              </div>
              <div id="collapse-notification" aria-labelledby="heading-notification" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/notification">Notification</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <li class="nav-item" id="Companies" @click="setActive('Companies')">
            <div class="panel panel-default panel-bg" v-show="isAdmin">
              <div class="panel-heading" role="tab" id="heading-company">
                <h4 class="panel-title">
                  <router-link tag="a" to="/company" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-list-alt"></i>
                      <span>Companies</span>
                    </div>
                  </router-link>
                </h4>
              </div>
              <div id="collapse-company" aria-labelledby="heading-company" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/company">Company</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </li>
            <li class="nav-item" id="Users" @click="setActive('Users')">
            <div class="panel panel-default panel-bg">
              <div class="panel-heading" role="tab" id="heading-user">
                <h4 class="panel-title">
                  <router-link tag="a" to="/user" role="button" aria-expanded="false">
                    <div class="panel-title-left">
                      <i class="fa fa-users"></i>
                      <span>Users</span>
                    </div>
                  </router-link>
                </h4>
              </div>
              <div id="collapse-user" aria-labelledby="heading-user" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                  <ul>
                    <li>
                      <router-link to="/user">User</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="contentWrapper">
        <router-view/>
      </section>
    </section>
    <footer v-show="false" class="color-1-bg ">
      <div class="footerIn">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12">
              <div class="clearfix">
                <div class="footerLeft">
                  &copy; 2021, Zero Parallax Team
                </div>
                <div class="footerRight"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--  Modal options -->
    <DeviceModel :externalKey="addDeviceKey" v-if="addDeviceModalOpened" @cancel="addDeviceModalClose"/>
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
  </div>
</template>
<style lang="scss">
</style>
<script>
import "@/assets/sass/main.scss"
import { mapState } from 'vuex'
import { reactive } from 'vue'
import {notifications} from "./_store/notification.module";
import DeviceModel from "@/views/device/DeviceModal"
export default {
  components: {
    DeviceModel,
  },
  name: 'app',
  data: () => {
    const handler = {
      get(target, property, receiver) {
        const value = Reflect.get(...arguments)
        if (typeof value === 'Object') {
          // Wrap the nested object in its own reactive proxy
          return reactive(value)
        } else {
          return value
        }
      }
      // ...
    }
    return {
      deviceInf: new Proxy([], handler),
      addDeviceModalOpened: false,
      addDeviceKey: null,
    };
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
      hubState: state => state.app.hubState,
      isAdmin: state => state.authentication.isAdmin,
      user: state =>state.authentication.user,
      notifications: state => state.notification.unreadAll,
      deviceInfos: state => state.device.deviceInfos,
      deviceInfoUpdated: state => state.device.deviceInfoUpdated,
    })
  },
  mounted() {
    this.$questionHub.$on('notification-info', this.onNotification);
    this.$questionHub.$on('device-info-hub', this.onDeviceInfoHub)
    this.$questionHub.$on('device-info', this.onDeviceInfo)
    this.$questionHub.$on('alert-info', this.onAlertInfo);
    if (this.loggedIn) {
      this.$connectHub(this.user);
      this.$store.dispatch('notification/getAllUnread').then(success => this.notificationToastCallback(success));
    }
    if (typeof $ !== 'undefined') {
      let sideMenuToggle = $(".sideMenuToggle");
      sideMenuToggle.click(function (e) {
        e.preventDefault();
        $("body").toggleClass("mtSidebarOpen");
      });
      let notificationsToggle = $(".notificationsToggle");
      notificationsToggle.click(function (e) {
        e.preventDefault();
        body.toggleClass("mtNotificationsOpen");
      });
      let sidebarHeadingCollapseLink = $(".mtSidebar .panel-heading a[data-toggle='collapse']");
      sidebarHeadingCollapseLink.click(function () {
        if (!$("body").hasClass("mtSidebarOpen")) {
          return false;
        }
      });
    }
  },
  unmounted() {
    this.$questionHub.$off('notification-info', this.onNotification);
    this.$questionHub.$off('device-info', this.onDeviceInfo);
    this.$questionHub.$off('device-info-hub', this.onDeviceInfoHub);
    this.$questionHub.$off('alert-info', this.onAlertInfo);
  },
  methods: {
    setActive(element) {
      var list = document.querySelectorAll("#nav > li");
      for (var i = 0; i < list.length; i++) {
        if (list[i].classList.contains("active")) {
          list[i].classList.remove("active");
        }
        document.getElementById(element).classList.add("active");
      }
    },
    logout() {
      this.$store.dispatch('authentication/logout').then(() => {
        this.$router.push('/auth/login');
        this.$disconnectHub();
      });
    },
    onDeviceInfoHub: function(deviceInfoHub) {
      var deviceHub = deviceInfoHub.data;
      this.$store.dispatch('device/addInfo', deviceHub).then(data => {
        if (data.changed) {
          if (deviceHub.device.State === 2) {
            this.$toast.info(`<p>Analyzer ${deviceHub.device.Name} going Live </p>
</br>
 <img width="480" height="auto" src="http://localhost:9090/live"/>`, {
              position: 'bottom-right',
              duration: 5000,
            });
          }
          if (deviceHub.device.State === 3) {
            this.$toast.success(`<p>Device ${deviceHub.device.Name} Ready.</p>
<span> click <router-link to="/analyzerResult/${deviceHub.device.Id}">here</router-link> to see results</span>
`, {
              position: 'top-right',
              duration: 5000,
            });
          }
        }
      }, error => {
      });
      // this.$store.dispatch('notification/arrive', notification).then(success => {
      //   this.$toast.info(`Notification Arrived`, {
      //     position: 'top-right',
      //     click: () => {
      //       let info = JSON.parse(notification);
      //       this.addDeviceModalOpen(info.key)
      //     }
      //   });
      // }, error => {
      //
      // });
      // this.$store.dispatch('notification/getAllUnread').then(success => this.notificationToastCallback(success));
    },
    onAlertInfo: function(alertInfo) {
      var alert = alertInfo.data;
      this.$store.dispatch('alert/addInfo', alert);
      if (alert.changed) {
        if (alert.state === 1) {
          this.$toast.info(`<p>Analyzer ${alert.Alert.Name} going Live </p>
</br>
 <img width="480" height="auto" src="http://localhost:9090/live"/>`, {
            position: 'bottom-right',
            duration: 5000,
          });
        }
        if (alert.state === 2) {
          this.$toast.success(`<p>Analyzer ${alert.Alert.Name} Ready.</p>
<span> click <router-link to="/analyzerResult/${alert.Alert.Id}">here</router-link> to see results</span>
`, {
            position: 'top-right',
            duration: 5000,
          });
        }
      }
      // this.$store.dispatch('notification/arrive', notification).then(success => {
      //   this.$toast.info(`Notification Arrived`, {
      //     position: 'top-right',
      //     click: () => {
      //       let info = JSON.parse(notification);
      //       this.addDeviceModalOpen(info.key)
      //     }
      //   });
      // }, error => {
      //
      // });
      // this.$store.dispatch('notification/getAllUnread').then(success => this.notificationToastCallback(success));
    },
    onNotification: function(notification) {
      this.$store.dispatch('notification/arrive', notification).then(success => {
      }, error => {
      });
      if(notification.type === "NewAnalyzer") {
        this.$toast.warning(notification.message, {
          position: 'top-right',
          click: () => {
            // let info = JSON.parse(notification);
            // this.addDeviceModalOpen(info.key)
          }
        });
        this.$store.dispatch('device/getAll');
      } else if (notification.type === "Event") {
        this.$toast.info(`Notification Arrived`, {
          position: 'top-right',
          click: () => {
            let info = JSON.parse(notification);
            this.addDeviceModalOpen(info.key)
          }
        });
      }
      this.$store.dispatch('notification/getAllUnread').then(success => this.notificationToastCallback(success));
    },
    notificationToastCallback: function (success) {
        for(let i in success.list) {
          let notification = success.list[i];
          this.$toast.info(`Notification Arrived`, {
            position: 'top-right',
            click: () => {
              let info = JSON.parse(notification);
              this.addDeviceModalOpen(info.key)
            }
          });
        }
    },
    onDeviceInfo: function(deviceInfo) {
      this.$store.dispatch('device/addInfo', JSON.parse(deviceInfo.data));
      // this.$toast.info(`Notification Arrived`, {
      //   position: 'top-right',
      //   click: () => {
      //     let info = JSON.parse(notification);
      //     this.addDeviceModalOpen(info.key)
      //   }
      // });
      // console.log('notification arrive', notification);
      // this.$store.dispatch('notification/getAllUnread');
      // this.$store.dispatch('notification/getAll');
    },
    addDeviceModalOpen(note){
      this.addDeviceKey = note.deviceKey;
      this.addDeviceModalOpened = true;
    },
    addDeviceModalClose(){
      this.addDeviceModalOpened = false;
      this.addDeviceKey = null;
      this.$store.dispatch('device/getAll')
    },
    getAnalyzerPercent(item) {
      if (item.progress !== null && item.duration !== null && item.progress !== 0 && item.duration !== 0) {
        return parseInt(100 / (item.duration / item.progress));
      }
      return 0;
    }
  },
  watch: {
    loggedIn: function(val, old) {
      if (val) {
        this.$connectHub(this.user);
        this.$store.dispatch('notification/getAllUnread');
      }
    },
    user: function(val, old) {
      if (val) {
      }
    },
    notifications: function(val, old) {
    },
    analyzerInfoUpdated: function(val, old) {
      this.analyzes =  this.analyzerInfos;
    },
    deviceInfoUpdated: function(val,old) {
      this.deviceInf =  this.deviceInfos;
    }
  }
}
</script>
<style scoped>
.mtadminLogo img{
  width: 200px;
}
.hdrIcons {
  display: flex; 
  align-items: center;
}
</style>
<style>
.ot-multiselect-tag .multiselect-tags {
    min-height: 20px !important;
    max-height: 32px !important;
    overflow-y: auto !important;
    padding-right: 5px;
  }
    .ot-multiselect-tag .multiselect-tags-search-copy {
    position: absolute;
  }
  .ot-multiselect-tag .multiselect-clear {
    position: absolute;
    right: 23px;
  }
  .ot-multiselect-tag .multiselect-caret {
    position: absolute;
    right: 10px;
  }
  .ot-multiselect-tag .multiselect-tag {
    align-items: start;
  }
  .ot-multiselect-tag .multiselect-tag-remove {
    align-self: center;
  }
  .not-center {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .menubar .nav-item.active{
  border-bottom: 3px solid #FF5858;
}
.menubar .nav-item.active ~ a{
  background: #1e282c !important;
}
body.mtSidebarOpen .mtSidebar .panel-group .nav-item.active .panel-heading .panel-title > a{
  background: #1e282c !important; 
}
</style>
<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-folder"></i>
                <span>Cameras</span>
              </a>
            </li>
            <li><a href="#">Manager</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-shopping-bag"></i>
                  <span>Streams</span>
                </h3>
              </div>
              <div class="boxHeaderRight">
              </div>
            </div>
            <div class="boxBody">
              <div class="tab-content">
                <div class="tab-pane fade in active" id="productsManagerGallery-tab">
                  <section class="productsGalleryWrap">
                    <section class="productsGalleryWrapHeader">
                      <div class="productsGalleryWrapHeaderLeft">
                        <span>active streams: {{analyzers && analyzers.results ? analyzers.results.length : 0}}</span>
                        <div>
                        </div>
                      </div>
                    </section>
                    <section class="productsGalleryWrapBody">
                      <div class="row" id="productsGalleryWrapBodyRow">
                        <div v-for="(item, i) in analyzers.results" v-bind:key="item?.id">
                          <div class="productsGalleryItem col-xs-12 col-sm-4 col-md-4">
                            <div class="productsGalleryItemIn">
                              <div class="productsGalleryItemInside">
                                <div class="productsGalleryItemImg flex-center" :ref="el => {  liveVideosContainer[i] = el }">
                                  <LiveVideo :session-id="item.id + 'streamPage'" :id="item.id"  :ref="el => { if (el) el.key = item.id; liveVideos[i] = el }" />
                                </div>
                                <div class="productsGalleryItemInfo">
                                  <div class="productsGalleryItemInfoLeft">
                                  </div>
                                  <div class="productsGalleryItemInfoRight">
                                    <div class="btn-group btn-group-sm">
                                      <router-link tag="button" :to="`/analyzerAlerts/${item.id}`"  data-target="#productEditModal"  class="btn btn-default btn-flat" ><i class="fa fa-list-alt"></i></router-link>
                                      <button @click="syncItem(item)" data-target="#productEditModal" class="btn btn-default btn-flat" title="Sync"><i class="fa fa-refresh"></i></button>
                                      <button @click="editItem(item)" data-target="#productEditModal" class="btn btn-default btn-flat" title="Edit"><i class="fa fa-pencil"></i></button>
                                      <button @click="filterModalCheck(item,true)" data-target="#productEditModal" class="btn btn-default btn-flat" title="Delete"><i class="fa fa-cut"></i></button>
                                      <button  @click="deleteItem(item)" class="btn btn-danger btn-flat" title="Delete"><i class="fa fa-trash-o"></i></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <AnalyzerModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <FilterModal :id="edit_id" v-if="modalFilter" @cancel="filterModalCheck(false)"/>
  <!--  Modal options -->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import AnalyzerModel from "./AnalyzerModal"
import VConfirmModal from "../../components/v-confirm-modal"
import LiveVideo from "./LiveVideo";
import FilterModal from "./FilterModal";
import {onBeforeUpdate, ref} from "vue";
export default {
  components: { VConfirmModal, LiveVideo, AnalyzerModel, FilterModal},
  data() {
    return{
      modalAdd: false,
      modalFilter: false,
      sourceObjects: {},
      modalConfirm: false,
      edit_mode: false,
      edit_id: null,
      confirmModalCallback: () => {

      }
    }
  },
  setup() {

    const liveVideos = ref([]);
    const liveVideosContainer = ref([]);

    // Make sure to reset the refs before each update.
    onBeforeUpdate(() => {
      liveVideos.value = [];
      liveVideosContainer.value = [];
    });

    return{
      liveVideos,
      liveVideosContainer,
    }
  },
  computed: {
    analyzers () {
      return this.$store.state.analyzer.all;
    },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  created () {
    this.$store.dispatch('device/getAll');
  },
  mounted() {
    console.log(this.$store.state)
  },
  methods: {
    addModalCheck(open){
      this.modalAdd = open;
      if (!this.modalAdd) {
        this.$store.dispatch('device/getAll');
      }
    },
    filterModalCheck(item, open){
      this.modalFilter = open;
      if (!this.modalFilter) {
        this.$store.dispatch('device/getAll');
        this.edit_id = null;
      } else {
        this.edit_id = item.id;
      }
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.$store.dispatch('device/getAll');
      }
    },
    deleteItem(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('device/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
        });
      }
    },
    addItem() {
      this.edit_mode = false;
      this.addModalCheck(true);
    },
    editItem(item) {
      this.edit_mode = true;
      this.edit_id = item.id;
      this.addModalCheck(true);
    },
    syncItem(item) {
      this.$store.dispatch('device/sync',  [item.id]).then(result => {
        this.$toast.success(`settings synced`, {
          position: 'top-right'
        });
      });
    }
  },
  watch: {
  },
};
</script>
<style scoped>
.btn-group > button {
    margin: 0 10px;
  }
.flex-center{
  display: flex !important;
  align-items: center;
}
</style>

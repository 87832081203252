import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import eventbus from "./eventbus";
import Environment from "@/environment/env.js";
const hubUrl = Environment.APP_BASE_URL;
let connection = new HubConnectionBuilder();
const questionHub = eventbus;
export const Hub = {
    install(app, options) {


        app.config.globalProperties.$sendCommand = (method, data) => {
            return connection.invoke(method, data);
        };
        app.config.globalProperties.$disconnectHub = () => {
            connection.stop();
            app._context.provides.store.commit("app/setHubState", connection.state);
        };

        app.config.globalProperties.$connectHub = (authStore) => {
            connection = new HubConnectionBuilder()
                .withUrl(hubUrl, { accessTokenFactory: () => authStore?.jwt_token })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

            connection.start()
                .then(() => {
                    try {
                        app._context.provides.store.commit("app/setHubState",connection.state);
                    } catch (e) {
                        console.error(e);
                    }
                });
            connection.onreconnecting(() =>
                questionHub.$emit("state", connection.state));

// Forward server side SignalR events through $questionHub, where components will listen to them
            connection.on("DeviceInfo", (data) => {
                questionHub.$emit("device-info", { data });
            });
            connection.on("AutoGeneratedAlertInfo", (data) => {
                questionHub.$emit("AutoGeneratedAlertInfo", { data });
            });
            connection.on("Notifications", (data) => {
                const pass = JSON.parse(data);
                questionHub.$emit("notification-info", pass);
            });
            connection.on("Alerts", (data) => {
                 questionHub.$emit("alert-info", { data });
            });
            connection.on("DeviceInfoHubResponse", (data) => {
                questionHub.$emit("device-info-hub", { data });
            });
            connection.on("AnalyzerData", (data) => {
                questionHub.$emit("analyzer-data", { data });
            });
            connection.on("SdpOffer", (data) => {
                questionHub.$emit("sdp-offer", { data });
            });
            connection.on("IceCandidate", (data) => {
                 questionHub.$emit("ice-candidate", { data });
            });
            connection.on("AlertInfo", (data) => {
                questionHub.$emit("alert-info", { data });
            });
        }

        app.config.globalProperties.$getHubState = (method, data) => {
            return connection.state;
        }
// every component will use this.$questionHub to access the event bus

        app.config.globalProperties.$questionHub = questionHub;
    },
}

const Environment = {
    APP_BASE_URL: "/hub/",
    GET_USER: "/api/User",
    UPDATE_PROFILE_URL:"/api/User",
    UPDATE_PASSWORD_URL:"/api/User/updatePassword",
    AUTO_GENERATED_ALERT : "/api/alert",
    ADD_DEVICE_KEY: "/api/deviceKey/add",
    GET_ALL_DEVICE_KEY: "/api/deviceKey",
    DELETE_DEVICEKEY: "/api/deviceKey/delete",
    GET_ALL_COMPANY_URL: "company/getAll",
    GET_ALL_DEVICEKEY_URL: "deviceKey/getAll",
    GET_ALERT_VIDEO_IMAGE : "/api/File/image/",
    GET_LIVE_HEAT_MAP: "/api/LiveHeatMap/sample",
    DELETE_GENERATED_ALERTS : "/api/Alert/delete"
}

export default Environment;
<template>
  <div>
    <video :width="width" :height="height" Id="alertVid" :controls="true" @loadeddata="loadVideo" autoplay @play="sourcePlay" @playing="sourcePlaying" @loadedmetadata="metadataLoaded" >
            <source type="video/webm" :src="this.sourceObject">
            <track label="English" kind="captions" srclang="en" default>
    </video>
    </div>
</template>

<script>
import Environment from "../environment/env.js";
import AnalyzerAlertService from "../_services/AnalyzerAlert";
export default {
  props: {
    Id: {
      type: Number
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    video_id: {
      type: Number,
    }
  },
  name: "v-options-modal",
  data() {
    return{
      sourceObject: null,
    }
  },
  mounted() {
    this.getVideoSource();
  },
  methods:{
    sourcePlay: function(val) {
      this.$emit('play', val);
    },
    loadVideo: function(val) {
    },
    sourcePlaying: function(val) {
      this.$emit('playing', val);
    },
    metadataLoaded: function(val) {
      this.$emit('loadedmetadata', val);
    },
    getVideoSource() {
        const API_URL = Environment.GET_ALERT_VIDEO_IMAGE + this.video_id;
        const response = AnalyzerAlertService.getGenAlertsVideoImage(API_URL);
        response.then(async (res) => {
          this.sourceObject = "data:video/webm;base64," + res?.data;
          document.getElementById("alertVid").load();
        });
      },
  },
  }
</script>


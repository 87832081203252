<template>
  <div class="modal-options" v-on:keyup.enter="submit" >
      <div class="content">
        <div class="header panel-heading">
          <h3>Add Device Key</h3>
        </div>
        <div class="body">
          <div class="form-group">
            <label>Device-Key Value</label>
            <input v-model="key" maxlength="39" :ref="el => { keyInput = el }" v-focus type="text" @input="validateKey" placeholder="Enter device key value">
          </div>
          <div class="form-group">
            <label>Device-Key Name</label>
            <input v-model="name" maxlength="39" type="text" placeholder="Enter device key name">
          </div>

          <div class="form-group">
            <label>Company Name</label>
          <select class="form-group" v-model="CompanyId" style="padding: 8px;width: 33rem">
              <option v-for="option in companies.results" :key="option?.id" v-bind:value="option.id">
                  {{ option.name }}
            </option>
          </select>
          </div>
        </div>
        <div class="footer">
          <button @click="submit" class="btn btn-info btn-flat">Add</button>
          <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>
import Environment from "../../environment/env.js";
import {onBeforeUpdate, ref} from "vue";

export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    }
  },
  name: "v-options-modal",
  data() {
    return{
      key: '',
      name: "",
      msg: [],
      CompanyId: "",
      selectCompany:{},
      host: '',
      item: {},
    }
  },
  setup() {

    const keyInput = ref({});

    // Make sure to reset the refs before each update.
    onBeforeUpdate(() => {
      keyInput.value = {};
    });

    return{
      keyInput,
    }
  },
  created () {
    this.$store.dispatch(Environment.GET_ALL_COMPANY_URL);
    },
  computed: {
      companies () {
            return this.$store.state.company.all;
        },
      },
  methods:{
    validateKey(e) {
      let phoneValue = e.target.value.replace(/\W/g, '');
        // Add dashes every 4 characters
        phoneValue = phoneValue.replace(/([a-z0-9]{1,4})/g, '$1-').slice(0, -1);
        // Set the value of the phone input to the formatted phone number
        e.target.value = phoneValue;
        this.key = e.target.value
        if (e.target.value.length<39)
          {
            this.msg['key'] = '';
          } else{
            this.msg['key'] = 'Invalid Key Value';
          } 
      },
    submit() {
      let data = {
        key: this.key,
        name: this.name,
        CompanyId: this.CompanyId
      };
      if((this.key.length == 39)&&(this.name !== "") && (this.CompanyId !== "")){
      // if (!this.edit_mode) {
        this.$store.dispatch('deviceKey/add', data).then(data => {
          this.$toast.success(`The new device key has been added successfully !`, {
            position: 'top-right'
          })
          this.cancel();
        }, 
        
        error => {
          this.$toast.error(`Existing DeviceKey`, {
            position: 'top-right'
          });
          this.cancel();
        });
      // } else {
      //   data.id = this.item.id;
      //   this.$store.dispatch('deviceKey/edit', data).then(data => {
      //     this.cancel();
      //   });
      // }
      }
    else if(this.name == "" ){
      debugger
      this.$toast.error(`Enter name of the device`, {
            position: 'top-right'
      })
      //  this.cancel();
    }
    else if(this.CompanyId == ""){
      debugger
      this.$toast.error(`Enter companyId for the device`,{
        position:'top-right'
      });
      //  this.cancel();
    }
      else{
        if(this.key.length !== 39)
        this.$toast.error(`Please enter valid Key`, {
            position: 'top-right'
          });
          // this.cancel();
      }
    },
    cancel(){
      this.$emit('cancel')
    }
  },
  mounted() {
    if (this.edit_mode) {
      console.log('edit deviceKey: ', this.id)
      this.$store.dispatch('deviceKey/get', this.id).then(response => {
        this.item = response;
        this.name = response.name;
      });
    } else {
      // console.log(this.keyInput.$el.focus())
      console.log('add deviceKey')
    }

  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 400px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}

.body{
  padding: 8px 35px;
}
.form-group > input[type='text']{
  width: 100%;
  border: 1px solid #ccc;
    padding: 8px;

}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > * {
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

</style>
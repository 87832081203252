<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
          ></fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FusionChart5",
  data(){
    return{
      type: "msspline",
      width: "100%",
      height: "400",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Support Tickets : Received vs Resolved",
          yaxisname: "# of Tickets",
          subcaption: "Last week",
          numdivlines: "3",
          showvalues: "0",
          legenditemfontsize: "15",
          legenditemfontbold: "1",
          plottooltext: "<b>$dataValue</b> Tickets $seriesName on $label",
          theme: "gammel"
        },
        categories: [
          {
            category: [
              {
                label: "Jan 1"
              },
              {
                label: "Jan 2"
              },
              {
                label: "Jan 3"
              },
              {
                label: "Jan 4"
              },
              {
                label: "Jan 5"
              },
              {
                label: "Jan 6"
              },
              {
                label: "Jan 7"
              }
            ]
          }
        ],
        dataset: [
          {
            seriesname: "Received",
            data: [
              {
                value: "55"
              },
              {
                value: "45"
              },
              {
                value: "52"
              },
              {
                value: "29"
              },
              {
                value: "48"
              },
              {
                value: "28"
              },
              {
                value: "32"
              }
            ]
          },
          {
            seriesname: "Resolved",
            data: [
              {
                value: "50"
              },
              {
                value: "30"
              },
              {
                value: "49"
              },
              {
                value: "22"
              },
              {
                value: "43"
              },
              {
                value: "14"
              },
              {
                value: "31"
              }
            ]
          }
        ]
      }
    }
  },
}
</script>

<style scoped>

</style>
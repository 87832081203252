<template>
<div class="pad-body">
    <div class="pad-btn"><router-link class="btn btn-info btn-flat" to="/alerts">Back</router-link></div>
        <FullCalendar :options="calendarOptions" />
</div>
<AlertDetailModal :style="{ 'left': left + 'px', 'top': top + 'px' }" v-if="modalAlertDetail" :alert="this.alertObject" @cancel="cancelModal(false)"/>
</template>

<script>

import AlertDetailModal from "../alerts/AlertDetailModal"
import '@fullcalendar/core/vdom';
    import FullCalendar, {
        CalendarOptions,
        EventApi,
        DateSelectArg,
        EventClickArg,
    } from '@fullcalendar/vue3'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar,
    AlertDetailModal
  },
  data() {
    return {
      currentAlert: {},
      companyName: "",
      aid: "",
      left: "",
      top:"",
      alertTable:[],
      alertObject:{},
      modalAlertDetail: false,
      calendarOptions: {
        plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin // needed for dateClick
                    ],
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        right: 'timeGridDay,timeGridWeek,dayGridMonth'
                    },
                    views: {
                        dayGridMonth: { 
                            displayEventTime: true,
                            displayEventEnd: true,
                        }
                    },
        events: [],
        firstDay: 1,
        allDay:true,
        initialView: 'dayGridMonth',
        navLinks: true, 
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventClick: this.handleEventClick,
      }
    }
  },
  mounted() {
    this.getAlerts();
    this.$store.dispatch('company/get', this.alert?.companyId).then(response => {
        this.companyName = response.name;
      });
  },
    methods: {
      showDetail(alert){
        this.currentAlert = alert;
        this.modalAlertDetail = true;
      },
      cancelModal(){
        this.modalAlertDetail = !this.modalAlertDetail;
      },
      handleEventClick(e) {
        debugger
              this.aid = e.event._def.extendedProps.alertId
              let filteredAlert = this.alertTable.filter(alert => { return alert?.id === this.aid});
              var alert = {
              alertId: filteredAlert[0]?.id,
              polygonName: filteredAlert[0]?.polygon?.name,
              alertTitle: filteredAlert[0]?.name,
              deviceName: filteredAlert[0]?.device?.name,
              date: filteredAlert[0]?.creationDate,
              companyId: filteredAlert[0]?.device?.companyId,
              threshold2: filteredAlert[0]?.type2Threshold/10+"°C",
              timestamp: filteredAlert[0]?.start+" - "+filteredAlert[0]?.end,
              days: this.getDay(filteredAlert[0]?.days)
            };
            this.modalAlertDetail = true;
            this.findEventPosition(e);
            this.alertObject = alert;
          },
      findEventPosition(e) {
        // var rect1 = document.getElementById("alertDetail").getBoundingClientRect();
        var rect = e.el.getBoundingClientRect();
        var midScreen = screen.width / 2;
        var midheight = screen.height / 2;
        if (rect.x >= midScreen) {
          this.left = rect.x - (midScreen + 150);
        } else {
          this.left = rect.x + rect.width - (midScreen - 200);
        }
        if (rect.y >= midheight) {
          this.top = 200
        } else {
          this.top = ( rect.y - midheight) + 280;
        }
        
      },
      getDay(date) {
        var daysArr = JSON.parse(date.replaceAll('"', ""));
        var dayValue = "";
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",];
        for (let i = 0; i < daysArr.length; i++) {
          if (i == 0) {
            if (daysArr[i] == '7') {
              dayValue = "Alldays"
              break;
            }
            else {
              dayValue = dayValue + days[daysArr[i]];
            }
          }
          else {
            dayValue = dayValue + ", " + days[daysArr[i]];
          }
        }
        return dayValue;
      },
      getAlerts() {
        this.$store.dispatch('alertFilter/getAllAlerts', this.analyzerId).then((response => {
          const tem = [];
          this.alertTable = response;
          for (var i = 0; i < response.length; i++) {
              console.log("Alerts :: getAllAlerts :: response: " + response[i]?.id)
              tem.push({
                  "classNames": ['selected-event'],
                  "alertId": response[i]?.id,
                  "title": response[i]?.name,
                  "startTime": response[i]?.start,
                  "endTime": response[i]?.end,
                  "color":response[i]?.polygon?.colour,
                  "daysOfWeek": response[i]?.days == '["7"]' ? ['0','1','2','3','4','5','6'] : response[i]?.days
              })
          }
          this.calendarOptions.events = tem;
          this.eventsData = tem;
          let calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.refetchEvents();
  }).bind(this));
}
}
}
</script>

<style>
    .fc-view-harness {
        height: calc(100vh - 21.29rem) !important;
    }

    .fc-scroller-liquid-absolute::-webkit-scrollbar {
        width: 3px !important;
    }
    .fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
        padding:0 10px !important;
        border: 5px solid lightgray;
        border-radius: 50px;
    }
    .fc-scroller-liquid-absolute::-webkit-scrollbar-track {
        background-color: white;
    }
      .fc-scroller-harness .fc-scroller .fc-col-header {
      background-color: #2C3E50 !important;
    } 
    .fc-day .fc-col-header-cell-cushion {
      color: #fff !important;
    }
    .pad-body{
      padding: 20px 20px 0px 20px;
    }
    .pad-btn{
    padding-bottom: 20px;
    }
    .fc .fc-button{
      text-transform: capitalize !important;
    }
    .fc {
      text-transform: capitalize !important;
    }
</style>
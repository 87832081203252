import {deviceRepository} from '@/repository';

const initialState = {
    all: [],
    loading: false,
    deviceInfos: [],
    deviceInfoUpdated: false,
}

export const device = {
    namespaced: true,
    state: initialState,
    actions: {
        add({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                deviceRepository.add(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming);
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        addInfo({ dispatch, commit }, data) {
            commit('addDeviceInfo', data);
            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                resolve(data);
            });
        },
        edit({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                deviceRepository.edit(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming);
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        remove({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                deviceRepository.remove(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        getAll({ commit }) {
            commit('getAllRequest');

            return deviceRepository.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
        get({ commit }, data) {

            return deviceRepository.get(data)
                .then(
                    response => {
                        return response;
                    }
                    // data => commit('getAllSuccess', data),
                    // error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        addRequest(state) {
            state.loading = true;
        },
        addDeviceInfo(state, data) {
            if (!state.deviceInfos.filter(r=>r.key === data.key).length) {
                state.deviceInfos.push(data);
            } else {
                for(let i in state.deviceInfos) {
                    if (state.deviceInfos[i].key ===data.key) {
                        state.deviceInfos[i] = data;
                    }
                }
            }
            //this is for vue reaction
            state.deviceInfoUpdated = !state.deviceInfoUpdated;
        },
        addSuccess(state) {
            state.loading = false;
        },
        addFailure(state) {
            state.loading = false;
        },
        getAllRequest(state) {
            state.loading = true;
        },
        getAllFailure(state) {
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data;
        },
    }
}

import axios from "axios";

const AnalyzerAlertService = (function() {
    var _service;

    function _getService() {
        if (!_service) {
            _service = this;
            return _service;
        }
        return _service;
    }

    async function getGenAlerts(apiUrl) {
         return await axios
             .get(
                 apiUrl
             )
             .then((response) => {
               return response
             })
             .catch(() => {
                 return;
             })

     }

     async function getGenAlertsVideoImage(apiUrl) {
        return await axios
            .get(
                apiUrl
            )
            .then((response) => {
              return response
            })
            .catch(() => {
                return;
            })

    }

    async function getLiveHeatMap(apiUrl) {
        return await axios
            .get(
                apiUrl
            )
            .then((response) => {
              return response
            })
            .catch(() => {
                return;
            })

    }

    async function deleteGeneratedAlert(apiUrl,alert) {
        return await axios
            .post(
                apiUrl,alert
            )
            .then((response) => {
                return response;
            })
            .catch(() => {
                return;
            })
    }

    return {
        getService: _getService,
        getGenAlerts: getGenAlerts,
        getGenAlertsVideoImage:getGenAlertsVideoImage,
        getLiveHeatMap:getLiveHeatMap,
        deleteGeneratedAlert:deleteGeneratedAlert,
    };
})();

    export default AnalyzerAlertService;
export default {
    data() {
        return {

        }
    },
    methods: {
        setActive(element) {
            var list = document.querySelectorAll("#nav > li")
            for (var i = 0; i < list.length; i++) {
                if (list[i].classList.contains("active")) {
                    list[i].classList.remove("active");
                }
                document.getElementById(element).classList.add("active")
            }
        },
    }
}
<template>
  <section class="contentWrapper" style="min-height: 536px;">

    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopLeft">
            <h1>Charts</h1>
          </div>
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-cube"></i>
                  <span>Home</span>
                </a>
              </li>
              <li><a href="#">Layouts</a></li>
              <li><a href="#">Box</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">
        <FusionChart3/>
        <FusionChart1/>
        <FusionChart4/>
        <FusionChart5/>
        <FusionChart2/>
        <FusionChart6/>
      </div>
    </section>
  </section>
</template>

<script>
import FusionChart1 from "./FusionChart1"
import FusionChart2 from "./FusionChart2";
import FusionChart3 from "./FusionChart3";
import FusionChart4 from "./FusionChart4";
import FusionChart5 from "./FusionChart5";
import FusionChart6 from "./FusionChart6";
export default {
  name: "chartsPage",
  components: {FusionChart6, FusionChart5, FusionChart4, FusionChart3, FusionChart1,FusionChart2}
}
</script>

<style scoped>

</style>
<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
        <div class="contentWrapTopLeft">
          <h1>Users</h1>
        </div>
<!--        <div class="contentWrapTopRight">-->
<!--          <ul>-->
<!--            <li>-->
<!--              <a href="#">-->
<!--                <i class="fa fa-shopping-bag"></i>-->
<!--                <span>Users</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li><a href="#">List</a></li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
<!--              <div class="boxHeaderLeft">-->
<!--                <h3>-->
<!--                  <i class="fa fa-shopping-bag"></i>-->
<!--                  <span>List</span>-->
<!--                </h3>-->
<!--              </div>-->
              <div class="boxHeaderRight">

              </div>
            </div>
            <div class="boxBody">
              <ul class="nav nav-tabs">
                <li class="active"><a href="#UsersOptionsFields-tab" data-toggle="tab">System Users</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade in active" id="UsersOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            List
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM">
                            <thead>
                            <tr>
                              <th>                                  <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>
                              </th>
                              <th>
                                <div class="btn-group">
<!--                                  <button type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>-->
<!--                                  <button @click="this.polygonModalCheck" type="button" class="btn btn-info btn-flat">Palygon</button>-->
<!--                                  <button @click="this.confirmModalCheck" type="button" class="btn btn-info btn-flat">Confirm</button>-->
<!--                                  <button @click="this.videoModalCheck" type="button" class="btn btn-info btn-flat">Video</button>-->
                                </div>
                              </th>
                              <th colspan="4">
                                <div class="pull-right">
                                  <div class="input-group">
<!--                                    <label for="tableSearch" hidden=""></label>-->
<!--                                    <input id="tableSearch" type="text" class="form-control" placeholder="Search" />-->
<!--                                    <span class="input-group-btn">-->
<!--                                                                            <button id="btnGo" type="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>-->
<!--                                                                        </span>-->
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th>
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
                              </th>
                              <!-- <th>Image</th> -->

                              <th>Name</th>

                              <th>Email</th>
                              <th colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in users.results" v-bind:key="item.id">
                              <td class="img">
<!--                                <div class="mtCheckbox">-->
<!--                                  <label>-->
<!--                                    <input type="checkbox" />-->
<!--                                    <span></span>-->
<!--                                  </label>-->
<!--                                </div>-->
                                <img :src="(item.avatar ? $apiUrl + '/api/file/image/' + item.avatar : 'img/avatar.png')" alt="">
                              </td>
                              <td>{{ item.userName }}</td>
                              <td>{{item.email}}</td>
                              <td>
                                <div class="btn-group btn-group-sm">
<!--                                  <button @click="imageUploadModalCheck(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-user"></i></button>-->

                                  <button @click="editItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>

                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                      <span>Show</span>
                                  </span>
                                  <div class="d-flex align-items-center color-drop">
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control border-0 select-50" >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <i class="fa fa-angle-down idrop-colour" aria-hidden="true"></i>
                                  </div>
                                  <span class="input-group-addon">
                                    <span>Entries</span>
                                </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!-- user image modal -->
  <UserImageUploadModal v-if="imageUploadModel" @cancel="imageUploadModalCheck(false)"/>
  <!--  Modal options -->
  <UserModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <!--  Modal options -->
<!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
  <!--  Modal Video -->
<!--  <VVideoModal v-if="modalVideo" @cancel="this.videoModalCheck"/>-->
  <VPolygonModal v-if="modalPolygon" @confirm="confirmModalCallback" @cancel="polygonModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import UserImageUploadModal from "./UserImageUploadModal.vue"
import UserModel from "./UserModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
import VPolygonModal from "../../components/v-polygon-modal"
import setActive from "../../mixins/globalFunction.js";
export default {
    components: {UserImageUploadModal, UserModel, VOptionsModal, VConfirmModal, VVideoModal, VPolygonModal},
    mixins: [setActive],
    data() {
      return{
        modalAdd: false,
        modalOptions: false,
        modalConfirm: false,
        modalVideo: false,
        modalPolygon: false,
        edit_mode: false,
        edit_id: null,
        imageUploadModel: false,
        userStatuses: [],
        confirmModalCallback: () => {

        }
      }
    },
    computed: {
        users () {
            return this.$store.state.user.all;
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
      })
    },
    created () {
        this.$store.dispatch('user/getAll');
       // this.$questionHub.$on('user-info', this.onScoreChanged)
    },
    mounted() {
      this.setActive("Users");
    },
    methods: {
      imageUploadModalCheck(){
        this.imageUploadModel = !this.imageUploadModel;
      },
      // This is called from the server through SignalR
      onScoreChanged ({data }) {
        let user_info = JSON.parse(data);
        console.log('user-info:', data);
      },
      getUserStatus(item) {
        let list = this.userStatuses.filter(r => r.id === item.id);
        if (list.length) {
          return list[0].status;
        }
        return false;
      },
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch('user/getAll');
          }
      },
      polygonModalCheck(){
        this.modalPolygon = !this.modalPolygon;
      },
      optionsModalCheck(){
          this.modalOptions = !this.modalOptions;
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch('user/getAll');
        }
      },
      videoModalCheck(){
        this.modalVideo = !this.modalVideo;
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('user/remove',  [item.id]).then(result => {
            this.confirmModalCheck(false);
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.edit_id = null;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      }
  }
};
</script>
<style scoped>
  .btn-group > button {
    margin: 0 10px;
  }
  td.img{
    display: flex;
    align-items: center;
  }
  td > img{
    width: 40px;
    height: auto;
    border-radius: 50%;
    margin-left: 15px;
  }
</style>
<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
        <div class="contentWrapTopLeft">
          <h1>Companies</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderRight">
              </div>
            </div>
            <div class="boxBody">
              <ul class="nav nav-tabs">
                <li class="active"><a href="#CompaniesOptionsFields-tab" data-toggle="tab">System Companies</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade in active" id="CompaniesOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            List
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM" aria-label="company">
                            <thead>
                            <tr>
                              <th id="logo">                                  <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>
                              </th>
                              <th id="name">
                                <div class="btn-group">
                                </div>
                              </th>
                              <th id="option" colspan="4">
                                <div class="pull-right">
                                  <div class="input-group">
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th id="log">
                                Logo
                              </th>
                              <th id="name">Name</th>

                              <th id="options" colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in companies.results" v-bind:key="item.id">
                              <td class="img">

                                <img :src="(item.avatar ? $apiUrl + '/api/file/image/' + item.avatar : 'img/avatar.png')" alt="">
                              </td>
                              <td>{{ item.name }}</td>
                              <td>
                                <div class="btn-group btn-group-sm">
                                  <button @click="editItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>

                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                      <span>Show</span>
                                  </span>
                                  <div class="d-flex align-items-center color-drop">
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control border-0 select-50" >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <i class="fa fa-angle-down idrop-colour" aria-hidden="true"></i>
                                  </div>
                                  <span class="input-group-addon">
                                      <span>Entries</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!-- company image modal -->
  <CompanyImageUploadModal v-if="imageUploadModel" @cancel="imageUploadModalCheck(false)"/>
  <!--  Modal options -->
  <CompanyModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
  <!--  Modal options -->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
</div>
</template>

<script>
import {mapState} from 'vuex'
import CompanyImageUploadModal from "../auth/CompanyImageUploadModal.vue"
import CompanyModel from "./CompanyModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
import VPolygonModal from "../../components/v-polygon-modal"
import setActive from "../../mixins/globalFunction.js";

export default {
    components: {CompanyImageUploadModal, CompanyModel, VOptionsModal, VConfirmModal, VVideoModal, VPolygonModal},
    mixins: [setActive],
    data() {
      return{
        modalAdd: false,
        modalConfirm: false,
        edit_mode: false,
        edit_id: null,
        imageUploadModel: false,
        confirmModalCallback: () => {

        }
      }
    },
    computed: {
        companies () {
            return this.$store.state.company.all;
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
      })
    },
    created () {
        this.$store.dispatch('company/getAll');
    },
    mounted() {
      this.setActive("Companies");
    },
    methods: {
      imageUploadModalCheck(){
        this.imageUploadModel = !this.imageUploadModel;
      },
      // This is called from the server through SignalR
      addModalCheck(open){
          this.modalAdd = open;
          if (!this.modalAdd) {
            this.$store.dispatch('company/getAll');
          }
      },
      confirmModalCheck(open){
        this.modalConfirm = open;
        if (!open) {
          this.confirmModalCallback = () => {

          }
          this.$store.dispatch('company/getAll');
        }
      },
      deleteItem(item) {
        this.confirmModalCheck(true);
        this.confirmModalCallback = () => {
          this.$store.dispatch('company/remove',  [item.id]).then(result => {
            this.confirmModalCheck(false);
          });
        }
      },
      addItem() {
        this.edit_mode = false;
        this.edit_id = null;
        this.addModalCheck(true);
      },
      editItem(item) {
        this.edit_mode = true;
        this.edit_id = item.id;
        this.addModalCheck(true);
      }
  }
};
</script>
<style scoped>
  .btn-group > button {
    margin: 0 10px;
  }
  td.img{
    display: flex;
    align-items: center;
  }
  td > img{
    width: 40px;
    height: auto;
    border-radius: 50%;
    margin-left: 15px;
  }
</style>
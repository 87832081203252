<template>
<div>
  <section class="contentWrapTop">
    <div class="row">
      <div class="col-xs-12">
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>Polygons</h1>-->
<!--        </div>-->
        <div class="contentWrapTopRight">
          <ul>
            <li>
              <a href="#">
                <i class="fa fa-shopping-bag"></i>
                <span>Polygons</span>
              </a>
            </li>
            <li><a href="#">Manager</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="contentWrap">
    <div class="row">
      <div class="col-xs-12">
        <section class="boxWrap">
          <div class="box">
            <div class="boxHeader">
              <div class="boxHeaderLeft">
                <h3>
                  <i class="fa fa-shopping-bag"></i>
                  <span>Manager</span>
                </h3>
              </div>
              <div class="boxHeaderRight">

              </div>
            </div>
            <div class="boxBody">
              <ul class="nav nav-tabs">
                <li class="active"><a href="#AnalyzersOptionsFields-tab" data-toggle="tab">Polygons</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade in active" id="AnalyzersOptionsFields-tab">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Field Type
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                          <table class="table table-bordered table-valignM">
                            <thead>
                            <tr>
                              <th></th>
                              <th>
                                <div class="btn-group">
                                  <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>
                                  <button type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
<!--                                  <button @click="polygonModal2Check(true)" type="button" class="btn btn-info btn-flat">polygon2</button>-->
                                  <!--                                  <button @click="this.confirmModalCheck" type="button" class="btn btn-info btn-flat">Confirm</button>-->
                                  <!--                                  <button @click="this.videoModalCheck" type="button" class="btn btn-info btn-flat">Video</button>-->
                                </div>
                              </th>
                              <th colspan="4">
                                <div class="pull-right">
                                  <div class="input-group">
                                    <label for="tableSearch" hidden=""></label>
                                    <input id="tableSearch" type="text" class="form-control" placeholder="Search" />
                                    <span class="input-group-btn">
                                                                            <button id="btnGo" type="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>
                                                                        </span>
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th>
                                <div class="mtCheckbox">
                                  <label>
                                    <input type="checkbox" />
                                    <span></span>
                                  </label>
                                </div>
                              </th>
                              <th>Name</th>
                              <th>Creation Date</th>
                              <th colspan="2">Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in polygons.results">
                              <td>
                                <div class="mtCheckbox">
                                  <label>
                                    <input type="checkbox" />
                                    <span></span>
                                  </label>
                                </div>
                              </td>
                              <td>{{ item.name }}</td>
                              <td>{{item.creationDate}}</td>
                              <td>
                                <div class="btn-group btn-group-sm">
                                  <button @click="editItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="tableTools mt-10">
                            <div class="tableToolsLeft">
                              <div class="tableToolsCountSelect">
                                <div class="input-group">
                                                                <span class="input-group-addon">
                                                                    <span>Show</span>
                                                                </span>
                                  <label for="showEntriesCount" hidden=""></label>
                                  <select id="showEntriesCount" class="form-control">
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10" selected>10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                  <span class="input-group-addon">
                                                                    <span>Entries</span>
                                                                </span>
                                </div>
                              </div>
                            </div>
                            <div class="tableToolsRight">
                              <ul class="pagination">
                                <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!--  Modal options -->
  <PolygonModel :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
<!--  <PolygonModal2 :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="polygonModal2Check(false)"/>-->
  <!--  Modal options -->
  <!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
  <!--  Modal Confirm -->
  <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
</div>
</template>

<script>
import { mapState } from 'vuex'
import PolygonModal2 from "./PolygonModal2"
import PolygonModel from "./PolygonModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
export default {
  components: {PolygonModal2, PolygonModel, VOptionsModal, VConfirmModal, VVideoModal},
  data() {
    return{
      polygonModal2: false,
      modalAdd: false,
      modalOptions: false,
      modalConfirm: false,
      modalVideo: false,
      edit_mode: false,
      edit_id: null,
      polygonStatuses: [],
      confirmModalCallback: () => {

      }
    }
  },
  computed: {
    polygons () {
      return this.$store.state.polygon.all;
    },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  created () {
    this.$store.dispatch('polygon/getAll', this.$route.params.deviceId);
    // this.$questionHub.$on('polygon-info', this.onScoreChanged)
  },
  mounted() {
  },
  methods: {
    // This is called from the server through SignalR
    onScoreChanged ({data }) {
      let polygon_info = JSON.parse(data);
      console.log('polygon-info:', data);
    },
    getPolygonStatus(item) {
      let list = this.polygonStatuses.filter(r => r.id === item.id);
      if (list.length) {
        return list[0].status;
      }
      return false;
    },
    addModalCheck(open){
      this.modalAdd = open;
      if (!this.modalAdd) {
        this.$store.dispatch('polygon/getAll', this.$route.params.deviceId);
      }
    },
    optionsModalCheck(){
      this.modalOptions = !this.modalOptions;
    },
    polygonModal2Check(open){
      this.polygonModal2 = open;
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.$store.dispatch('polygon/getAll', this.$route.params.deviceId);
      }
    },
    videoModalCheck(){
      this.modalVideo = !this.modalVideo;
    },
    deleteItem(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('polygon/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
        });
      }
    },
    addItem() {
      this.edit_mode = false;
      this.addModalCheck(true);
    },
    editItem(item) {
      this.edit_mode = true;
      this.edit_id = item.id;
      this.addModalCheck(true);
    }
  }
};
</script>
<style scoped>
.flex-center{
  display: flex !important;
  align-items: center;
}
</style>
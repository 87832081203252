import { authHeader } from '@/_helpers';
import handleResponse from '../_services/shared';

export const deviceRepository = {
    add,
    getAll,
    get,
    edit,
    remove,
};

function add(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/device/add`, requestOptions)
        .then(handleResponse);
}

function edit(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/device/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/device/delete`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/device/${data}`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/device`, requestOptions).then(handleResponse);
}

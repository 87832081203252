<template>
  <div>
    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <!--        <div class="contentWrapTopLeft">-->
          <!--          <h1>AlertFilters</h1>-->
          <!--        </div>-->
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-shopping-bag"></i>
                  <span>AlertFilters</span>
                </a>
              </li>
              <li><a href="#">Options</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">
        <div class="col-xs-12">
          <section class="boxWrap">
            <div class="box">
              <div class="boxHeader">
                <div class="boxHeaderLeft">
                  <h3>
                    <i class="fa fa-shopping-bag"></i>
                    <span>AlertFilters</span>
                  </h3>
                </div>
                <div class="boxHeaderRight">

                </div>
              </div>
              <div class="boxBody">
                <!--              <ul class="nav nav-tabs">-->
                <!--                <li class="active"><a href="#AlertFiltersOptionsFields-tab" data-toggle="tab">AlertFilters</a></li>-->
                <!--              </ul>-->
                <div class="tab-content">
                  <div class="tab-pane fade in active" id="AlertFiltersOptionsFields-tab">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                          <h4 class="panel-title">
                            <a role="button" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              List
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                          <div class="panel-body">
                            <table class="table table-bordered table-valignM">
                              <thead>
                              <tr>
                                <!--                              <th>-->
<!--                                                                <button type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>-->


                                <!--                              </th>-->
                                <th>
                                  <div class="btn-group">
                                                                      <button  @click="addItem" type="button" class="btn btn-info btn-flat"><i class="fa fa-plus"></i></button>
                                    <!--                                  <button @click="this.confirmModalCheck" type="button" class="btn btn-info btn-flat">Confirm</button>-->
                                    <!--                                  <button @click="this.videoModalCheck" type="button" class="btn btn-info btn-flat">Video</button>-->
                                  </div>
                                </th>
                                <th :colspan="(isAdmin ? 6 : 5)">
                                  <!--                                <div class="pull-right">-->
                                  <!--                                  <div class="input-group">-->
                                  <!--                                    <label for="tableSearch" hidden=""></label>-->
                                  <!--                                    <input id="tableSearch" type="text" class="form-control" placeholder="Search" />-->
                                  <!--                                    <span class="input-group-btn">-->
                                  <!--                                                                            <button id="btnGo" type="button" class="btn btn-info btn-flat"><i class="fa fa-search"></i></button>-->
                                  <!--                                                                        </span>-->
                                  <!--                                  </div>-->
                                  <!--                                </div>-->
                                </th>
                              </tr>
                              <tr>
                                <!--                              <th>-->
                                <!--                                <div class="mtCheckbox">-->
                                <!--                                  <label>-->
                                <!--                                    <input type="checkbox" />-->
                                <!--                                    <span></span>-->
                                <!--                                  </label>-->
                                <!--                                </div>-->
                                <!--                              </th>-->
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Polygon</th>
                                <th>Threshold</th>
                                <th colspan="2">Options</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="item in alertFilters" :style="{'background-color': getColor(item) }">
                                <!--                              <td>-->
                                <!--                                <div class="mtCheckbox">-->
                                <!--                                  <label>-->
                                <!--                                    <input type="checkbox" />-->
                                <!--                                    <span></span>-->
                                <!--                                  </label>-->
                                <!--                                </div>-->
                                <!--                              </td>-->
                                <td>{{item.id}}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.type === 1 ? 'Normal' : 'Thermal' }}</td>
                                <td>{{ item.polygon?.name }}</td>
                                <td>{{ item.type === 1 ? item.type1Threshold : item.type2Threshold }}</td>
                                <td>
                                  <div class="btn-group btn-group-sm">
<!--                                    <button  v-if="isAdmin" @click="editItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-pencil"></i></button>-->
                                    <button v-if="isAdmin" @click="deleteItem(item)" type="button" class="btn btn-info btn-flat"><i class="fa fa-trash-o"></i></button>
                                  </div>
                                </td>
                              </tr>


                              </tbody>
                            </table>
                            <div class="tableTools mt-10">
                              <div class="tableToolsLeft">
                                <div class="tableToolsCountSelect">
                                  <div class="input-group">
                                                                <span class="input-group-addon">
                                                                    <span>Show</span>
                                                                </span>
                                    <label for="showEntriesCount" hidden=""></label>
                                    <select id="showEntriesCount" class="form-control">
                                      <option value="1">1</option>
                                      <option value="5">5</option>
                                      <option value="10" selected>10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                    <span class="input-group-addon">
                                                                    <span>Entries</span>
                                                                </span>
                                  </div>
                                </div>
                              </div>
                              <div class="tableToolsRight">
                                <ul class="pagination">
                                  <li><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                  <li><a href="#">1</a></li>
                                  <li><a href="#"><i class="fa fa-angle-double-right"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>

    <!--  Modal options -->
    <AlertFilterModel :analyzerId="$route.params.analyzerId" :edit_mode="edit_mode" :id="edit_id" v-if="modalAdd" @cancel="addModalCheck(false)"/>
    <!--  Modal options -->
    <!--  <VOptionsModal v-if="modalOptions" @cancel="this.optionsModalCheck"/>-->
    <!--  Modal Confirm -->
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
    <!--  Modal Video -->
    <!--  <VVideoModal v-if="modalVideo" @cancel="this.videoModalCheck"/>-->
    <VPolygonModal v-if="modalPolygon" @confirm="confirmModalCallback" @cancel="polygonModalCheck(false)"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AlertFilterModel from "./AlertFilterModal"
import VOptionsModal from "../../components/v-options-modal"
import VConfirmModal from "../../components/v-confirm-modal"
import VVideoModal from "../../components/v-video-modal"
import VPolygonModal from "../../components/v-polygon-modal"
export default {
  components: {AlertFilterModel, VOptionsModal, VConfirmModal, VVideoModal, VPolygonModal},
  data() {
    return{
      modalAdd: false,
      modalOptions: false,
      modalConfirm: false,
      modalVideo: false,
      modalPolygon: false,
      edit_mode: false,
      edit_id: null,
      alertFilterStatuses: [],
      confirmModalCallback: () => {

      }
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
      alertFilters: state => state.alertFilter.all,
      isAdmin: state => state.authentication.isAdmin,

    })
  },
  created () {
  },
  watch: {
    alertFilters: function(val) {
    }
  },
  mounted() {
    this.$store.dispatch('alertFilter/getAll', this.$route.params.analyzerId);
    // this.$questionHub.$on('alertFilter-info', this.onScoreChanged)
  },
  methods: {
    // This is called from the server through SignalR
    onScoreChanged ({data }) {
      let alertFilter_info = JSON.parse(data);
      console.log('alertFilter-info:', data);
    },
    getAlertFilterStatus(item) {
      let list = this.alertFilterStatuses.filter(r => r.id === item.id);
      if (list.length) {
        return list[0].status;
      }
      return false;
    },
    getAlertFilterName(item) {
      if (item.type === 1) {
        return 'new device';
      }
      if (item.type === 2) {
        return 'device status';
      }
      return 'alertFilter';
    },
    getColor(item) {
      if (item.read === 1) {
        return 'white';
      }
      if (item.read === 0) {
        return '#e3e3e3';
      }
      return 'white';
    },
    getStateName(item) {
      if (item.state === 2) {
        return 'In Progress';
      }
      if (item.state === 1) {
        return 'Resolved';
      }
      return 'Closed';
    },
    addModalCheck(open){
      this.modalAdd = open;
      if (!this.modalAdd) {
        this.$store.dispatch('alertFilter/getAll', this.$route.params.analyzerId);
      }
    },
    polygonModalCheck(){
      this.modalPolygon = !this.modalPolygon;
    },
    optionsModalCheck(){
      this.modalOptions = !this.modalOptions;
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.$store.dispatch('alertFilter/getAll', this.$route.params.analyzerId);
      }
    },
    videoModalCheck(){
      this.modalVideo = !this.modalVideo;
    },
    deleteItem(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('alertFilter/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);

          this.$store.dispatch('alertFilter/getAll', this.$route.params.analyzerId)
        });
      }
    },
    addItem() {
      this.edit_mode = false;
      this.addModalCheck(true);
    },
    editItem(item) {
      this.edit_mode = true;
      this.edit_id = item.id;
      this.addModalCheck(true);
    },
    markReadItem(item) {
      this.$store.dispatch('alertFilter/markRead', {Id: item.id, Read: 1}).then(result => {
        // this.confirmModalCheck(false);
        this.$store.dispatch('alertFilter/getAll', this.$route.params.analyzerId)
      });
    }
  }
};
</script>
<style scoped>
.btn-group > button {
  margin: 0 10px;
}
td.status > span{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}
td.status > span.red{
  background-color: rgb(172, 32, 32);
}
td.status > span.green{
  background-color: rgb(38, 109, 16);
}
td.status > span.yellow{
  background-color: rgb(207, 167, 35);
}
</style>
<template>

  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Polygons And Alerts</h3>
      </div>
<!--      <video autoplay width="0" height="0" controls  :src="videoSource" @play="sourcePlay" @playing="sourcePlaying" @loadedmetadata="metadataLoaded" id="video">-->
<!--        Your browser does not support the video tag.-->
<!--      </video>-->

      <LiveVideo :session-id="id + 'filterModal'" @play="sourcePlay" @playing="sourcePlaying" @loadedmetadata="metadataLoaded" :id="id" :width="0" :height:="0"/>

      <div class="forms-block" v-if="mode===1">
        <h3>Heading Text</h3>
        <textarea name="" id="" cols="30" rows="10">{{JSON.stringify(perimeter)}}</textarea>

        <div class="options-block">
          <label>name:</label>
          <input class="form-control" v-model="name">
        </div>
      </div>
      <div class="forms-block" v-if="mode===2">
        <div class="options-block form-group">
          <label>name: </label>
          <div class="form-group">
            <input v-model="alertName" type="text" placeholder="Alert Name">
          </div>
          <label>polygon: </label>
          <select  class="form-select form-control" v-model="currentPolygon">
            <option v-for="(option, index) in polygons.results" v-bind:value="option">
              {{ option.name }}
            </option>
          </select>

          <!--          <label>object: </label>-->
          <!--          <select class="form-select form-control" v-model="currentlabel">-->
          <!--            <option v-for="option in labels" v-bind:value="option.val">-->
          <!--              {{ option.name }}-->
          <!--            </option>-->
          <!--          </select>-->
          <!--          <label>object: </label>-->
          <!--          <div class="form-group">-->
          <!--            <input type="radio" id="one" value="1" v-model="type">-->
          <!--            <label for="one">Normal</label>-->
          <!--            <input type="radio" id="two" value="2" v-model="type">-->
          <!--            <label for="two">Thermal</label>-->
          <!--            <br>-->
          <!--            <span>Type: {{ type === 1 ? 'Normal' : 'Thermal' }}</span>-->
          <!--          </div>-->
          <label>threshold: </label>
          <div class="form-group">
            <input v-model="threshold2" type="text" placeholder="Thermal Threshold...">
          </div>
          <label>From: </label>
          <div class="form-group">
            <vue-timepicker format="HH:mm:ss" v-model="startTime"></vue-timepicker>
          </div>

          <label>To: </label>
          <div class="form-group">
            <vue-timepicker format="HH:mm:ss" v-model="endTime"></vue-timepicker>
          </div>

<!--          <div class="timepicker-block">-->
<!--          </div>-->
          <label>days: </label>
          <select class="form-select form-control" multiple="" v-model="days">
            <option value="0">Mondays</option>
            <option value="1">Tuesdays</option>
            <option value="2">Wednesdays</option>
            <option value="3">Thursdays</option>
            <option value="4">Fridays</option>
            <option value="5">Saturdays</option>
            <option value="6">Sundays</option>
            <option value="7">All</option>
          </select>
        </div>
      </div>

      <canvas :width="sourceCanvasWidth" :height="sourceCanvasHeight"  style="cursor:crosshair" @mousedown="point_it($event)" @mousemove="move_it($event)" @contextmenu="contMenu" ref="canRef" id="canvas"></canvas>



      <div class="footer">
        <div class="list">
<!--          <div v-if="mode === 2">-->
<!--            <select v-model="currentAnalyzerFilter">-->
<!--              <option v-for="(option, index) in alertFilters.results" v-bind:value="option">-->
<!--                {{ option.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
          <div v-if="mode === 0">
            <span>alerts: </span>
            <select v-model="currentEditAnalyzerFilter">
              <option v-for="(option, index) in alertFilters" v-bind:value="option">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="list">
          <div v-if="mode === 0">
            <span>polygons: </span>
            <select v-model="currentEditPolygon">
              <option v-for="(option, index) in polygons.results" v-bind:value="option">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <button @click="deleteFilter(currentEditAnalyzerFilter)" v-if="currentEditAnalyzerFilter !== null" class="btn btn-danger btn-flat">Delete Filter</button>
        <button @click="deletePolygon(currentEditPolygon)" v-if="currentEditPolygon !== null" class="btn btn-danger btn-flat">Delete Polygon</button>
        <button @click="setMode(1)" v-show="mode === 0" class="btn btn-success btn-flat">Create Polygon</button>
        <button @click="setMode(2)" v-show="mode === 0" class="btn btn-success btn-flat">Create Filter</button>
        <button @click="setMode(0)" v-show="mode === 1 || mode === 2" class="btn btn-warning btn-flat">Cancel {{mode === 1 ? 'Polygon' : 'Filter'}}</button>
        <button  @click="undo" class="btn btn-warning btn-flat" v-if="perimeter.length > 0 && mode === 1">{{ (perimeter.length > 0 ? 'Undo' : '') }}</button>
        <button @click="startSelectPolygon" v-show="!polygonSelectStarted && perimeter.length === 0 &&  mode === 1" class="btn btn-success btn-flat">Start Selecting</button>
        <button @click="stopSelectPolygon" v-show="polygonSelectStarted &&  mode === 1" class="btn btn-danger btn-flat">Stop Selecting</button>
        <button @click="submitPolygon" v-show="!polygonSelectStarted && (end || complete) && mode === 1" class="btn btn-success btn-flat">{{ (item.id ? 'Edit' : 'Add') }}</button>
        <button @click="submitAlert" v-show="mode === 2" class="btn btn-success btn-flat">{{ (item.id ? 'Edit' : 'Add') }}</button>
        <!--        <button class="btn btn-info btn-flat" @click="undo">Undo</button>-->
<!--        <button class="btn btn-info btn-flat" @click="clear">Clear</button>-->
        <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
      </div>
    </div>
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
  </div>
</template>

<script>
import LiveVideo from "./LiveVideo";
import VConfirmModal from "../../components/v-confirm-modal"
import {mapState} from "vuex";
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'

export default {
  props: {
    id: {
      type: Number
    }
  },
  components: {LiveVideo, VueTimepicker, VConfirmModal},
  name: "v-options-modal",
  data() {
    return{
      currentPolygon: null,
      currentAnalyzerFilter: null,
      currentEditPolygon: null,
      currentEditAnalyzerFilter: null,
      threshold1: null,
      modalConfirm: false,
      threshold2: null,
      mode: 0,
      days: null,
      endTime: null,
      startTime: null,
      alertName: '',
      confirmModalCallback: () => {

      },
      analyzer: null,
      edit_mode: true,
      polygonSelectStarted: false,
      xPos: 0,
      yPos: 0,
      sourceCanvasWidth: 1024,
      sourceCanvasHeight: 576,
      videoWidth: 640,
      videoHeight: 480,
      name: '',
      url: '',
      iter: -1,
      item: {},
      perimeter: [],
      video_draw: false,
      sourcePlayed: false,
      complete: false,
      ctx: {},
      end: true,
      canvas: document.createElement("canvas"),
      sourceItem: {},
      videoSource: '',
      dataUrl: null,
      items: [
        { id: 1, src: 'https://raw.githubusercontent.com/matteomattei/jPolygon/master/image.jpg' },
      ]
    }
  },
  methods:{
    setMode(mode) {
      this.mode = mode;
      if (this.mode === 0 || this.mode === 2) {
        this.end = true;
      }
    },
    startSelectPolygon() {
      this.polygonSelectStarted = true;
      this.end = false;
    },
    stopSelectPolygon() {
      if(this.perimeter.length <= 2) {
        alert('You need at least three points for a polygon');
        return false;
      }

      this.polygonSelectStarted = false;
      this.end = true;
      this.draw(this.end);
    },
    sourcePlay(e) {
      let self = this;
      this.sourcePlayed = true;
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      var ctx = this.ctx;
      var draw = this.draw;
      var end = this.end;
      var iter = this.iter;
      var video_draw = this.video_draw;
      var $this = e.target; //cache
      (function loop() {
        if (!$this.paused && !$this.ended) {
          // if (!video_draw)
          ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);

          draw();
          video_draw = true;
          iter = iter + 1;
          setTimeout(loop, 1000 / 30); // drawing at 30fps
        }
      })();
      console.log('source playing', e)
    },
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.getAlerts();
        this.getPolygons();
      }
    },
    deletePolygon(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('polygon/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
          this.currentEditPolygon = null;
        });
      }
    },
    deleteFilter(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('alertFilter/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
          this.currentEditAnalyzerFilter = null;
        });
      }
    },
    sourcePlaying(e) {
      console.log('source playing', e)
    },
    metadataLoaded(e) {
      this.videoWidth = e.target.videoWidth;
      this.videoHeight = e.target.videoHeight;
      this.sourceCanvasHeight = (this.sourceCanvasWidth * this.videoHeight) / this.videoWidth;
    },
    contMenu() {
      return false;
    },
    point_it(event) {
      if (!this.polygonSelectStarted)
        return;

      if(this.complete){
        // alert('Polygon already created');
        return false;
      }
      var rect, x, y;
     {
        rect = this.canvas.getBoundingClientRect();
       x = (event.clientX - rect.left - 8); // + rect.left
       y = (event.clientY - rect.top - 8); // + rect.top
        if (this.perimeter.length>0 && x === this.perimeter[this.perimeter.length-1]['x'] && y === this.perimeter[this.perimeter.length-1]['y']){
          // same point - double click
          return false;
        }
        if(this.check_intersect(x,y)){
          alert('The line you are drowing intersect another line');
          return false;
        }
        this.perimeter.push({'x':x,'y':y});
        this.draw(false);
        return false;
      }
    },
    move_it(event) {
      if (!this.canvas)
        return;
        let rect = this.canvas.getBoundingClientRect();
      this.xPos = (event.clientX - rect.left - 8); // + rect.left
       this.yPos = (event.clientY - rect.top - 8); // + rect.top

    },
    undo() {
      this.perimeter.pop();

      this.end = false;
      this.complete = false;
      if (this.perimeter.length) {
        this.polygonSelectStarted = true;
      }
      // if (this.perimeter.length <= 2) {
      // }
      // this.polygonSelectStarted = false;
      // this.start(true);
    },
    clear() {
      this.ctx = undefined;
      this.perimeter = new Array();
      this.complete = false;
      //document.getElementById('coordinates').value = '';
      this.start();
    },
    start(with_draw) {

      const canvas = document.createElement("canvas");
      this.ctx = canvas.getContext('2d');
      // this.ctx = this.$refs.canRef;
      if (with_draw)
        this.draw(false);

    },
    cancel(){
      this.$emit('cancel')
    },
    draw(end){
      if (typeof end === 'undefined')
        end = this.end;
      this.ctx.beginPath();
      this.ctx.lineWidth = 3;
      this.ctx.strokeStyle = "white";
      this.ctx.lineCap = "square";
      if (this.polygons.results) {
        for(const pol of this.polygons.results) {
          const periList = JSON.parse(pol.lines)
          for(let i=0; i<periList.length; i++){
            if(i===0){
              this.ctx.moveTo(periList[i]['x'],periList[i]['y']);
              end || this.point(periList[i]['x'],periList[i]['y']);
            } else {
              this.ctx.lineTo(periList[i]['x'],periList[i]['y']);
              end || this.point(periList[i]['x'],periList[i]['y']);
            }
          }

          this.ctx.lineTo(periList[0]['x'],periList[0]['y']);
        }
        this.ctx.closePath();
        this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        this.ctx.fill();
        this.ctx.strokeStyle = 'green';
        this.ctx.stroke();
      }
      if (this.perimeter.length) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "white";
        this.ctx.lineCap = "square";
        for(let i=0; i<this.perimeter.length; i++){
          if(i===0){
            this.ctx.moveTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
            end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
          } else {
            this.ctx.lineTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
            end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
          }
        }
        if(end ){
          this.complete = true;
        }
        if (end || this.complete) {
          if (this.perimeter[0]) {
            this.ctx.lineTo(this.perimeter[0]['x'],this.perimeter[0]['y']);
          }
          this.ctx.closePath();
          this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
          this.ctx.fill();
          this.ctx.strokeStyle = 'blue';
        }
        this.ctx.stroke();

        // print coordinates
        if(this.perimeter.length === 0){
          // document.getElementById('coordinates').value = '';
        } else {
          //document.getElementById('coordinates').value = JSON.stringify(perimeter);
        }
      }
    },
    check_intersect(x,y){
      if(this.perimeter.length < 4){
        return false;
      }
      var p0 = new Array();
      var p1 = new Array();
      var p2 = new Array();
      var p3 = new Array();

      p2['x'] = this.perimeter[this.perimeter.length-1]['x'];
      p2['y'] = this.perimeter[this.perimeter.length-1]['y'];
      p3['x'] = x;
      p3['y'] = y;

      for(let i=0; i<this.perimeter.length-1; i++){
        p0['x'] = this.perimeter[i]['x'];
        p0['y'] = this.perimeter[i]['y'];
        p1['x'] = this.perimeter[i+1]['x'];
        p1['y'] = this.perimeter[i+1]['y'];
        if(p1['x'] === p2['x'] && p1['y'] === p2['y']){ continue; }
        if(p0['x'] === p3['x'] && p0['y'] === p3['y']){ continue; }
        if(this.line_intersects(p0,p1,p2,p3)===true){
          return true;
        }
      }
      return false;
    },

    line_intersects(p0, p1, p2, p3) {
      let s1_x, s1_y, s2_x, s2_y;
      s1_x = p1['x'] - p0['x'];
      s1_y = p1['y'] - p0['y'];
      s2_x = p3['x'] - p2['x'];
      s2_y = p3['y'] - p2['y'];

      let s, t;
      s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
      t = ( s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);

      if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
      {
        // CollisSelectingion detected
        return true;
      }
      return false; // No collision
    },
    point(x, y){
      if (!this.complete) {
        this.ctx.fillStyle="white";
        this.ctx.strokeStyle = "white";
      } else {
      }
      this.ctx.fillRect(x-2,y-2,4,4);
      this.ctx.moveTo(x,y);
    },
    drawTest() {
      var video = document.getElementById('video');

      if (!video)
        return;
// set canvas size = video size when known
      video.addEventListener('loadedmetadata', () => {
        // canvas.width = video.videoWidth;
        // canvas.height = video.videoHeight;
        // this.start(false);
      });

      // video.addEventListener('play', function() {
      //   var $this = this; //cache
      //   (function loop() {
      //     if (!$this.paused && !$this.ended) {
      //       // if (!video_draw)
      //         ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);
      //
      //       draw(end);
      //       video_draw = true;
      //       iter = iter + 1;
      //       setTimeout(loop, 1000 / 30); // drawing at 30fps
      //     }
      //   })();
      // }, 0);
    },
    submitPolygon() {
      let data = {
        deviceId: this.analyzer.deviceId,
        lines: JSON.stringify(this.perimeter),
        name: this.name
      };
      // if (!this.edit_mode) {
        this.$store.dispatch('polygon/add', data).then(data => {
          this.mode = 0;
          this.getPolygons();
        });
      // } else {
      //   data.id = this.item.id;
      //   this.$store.dispatch('polygon/edit', data).then(data => {
      //     this.mode = 0;
      //     this.getPolygons();
      //   });
      // }
    },
    submitAlert() {
      let data = {
        name: this.alertName,
        analyzer_id: this.analyzer.id,
        polygon_id: this.currentPolygon?.id,
        type: 2,
        start: `${this.startTime.HH}:${this.startTime.mm}:${this.startTime.ss}`,
        end: `${this.endTime.HH}:${this.endTime.mm}:${this.endTime.ss}`,
        days: JSON.stringify(this.days),
        type1_threshold: this.threshold1,
        type2_threshold: this.threshold2,
        label: this.label,
      };
        data.device_id = this.analyzer.device_id;
        data.source_id = this.analyzer.source_id;
        data.type = this.type;
        this.$store.dispatch('alertFilter/add', data).then(val => {
          this.$toast.success(`Alert Filter Created`, {
            position: 'top-right'
          });
          this.mode = 0;
          this.getAlerts();
          this.$store.dispatch('device/sync',  [this.analyzer.id]).then(result => {
            this.$toast.success(`settings synced`, {
              position: 'top-right'
            });
          });
        }, error => {
          this.$toast.error(`Alert Filter Create Error`, {
            position: 'top-right'
          });
        });
    },
    getPolygons() {
      this.$store.dispatch('polygon/getAll', this.analyzer.deviceId).then(response => {
        // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
        // self.drawTest();
      });
    },
    getAlerts() {
      this.$store.dispatch('alertFilter/getAll', this.analyzer.id).then(response => {
        // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
        // self.drawTest();
      });
    },
   drawCircle(ctx, circle, progress) {
      ctx.beginPath();
      var start = this.accelerateInterpolator(progress) * circle.speed;
      var end = decelerateInterpolator(progress) * circle.speed;
      ctx.arc(circle.center.x, circle.center.y, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "white";
      ctx.fill();
      ctx.stroke();
},

accelerateInterpolator(x) {
  return x * x;
},

decelerateInterpolator(x) {
  return 1 - ((1 - x) * (1 - x));
},
  },
  computed: {
    ...mapState({
      polygons: state => state.polygon.all,
      alertFilters: state => state.alertFilter.all,
    })
  },
  mounted() {
    let self = this;

    // this.canvas = document.getElementById('canvas');
    // this.ctx = this.canvas.getContext('2d');

    this.$store.dispatch('device/get', this.id).then(response => {
      self.analyzer = response;
      this.getPolygons();
      this.getAlerts();
      // self.videoSource = self.$apiUrl + '/api/file/image/' + self.sourceItem.file.id;
      // self.drawTest();
    })
  },
  watch: {
    videoSource : (a) => {
      console.log(a)
    },
    alertFilters : function(val) {
    },
    sourcePlayed : function(val) {

    },
    currentEditAnalyzerFilter : function(val) {
      console.log('current alert edit', val)
    },
    currentEditPolygon : function(val) {
      console.log('current polygon edit', val)
    },
    perimeter: function (val) {
      if (this.perimeter.length) {
        // this.polygonSelectStarted = true;
      }
    }
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
/*.content > canvas{*/
/*  width: 640px;*/
/*  height: 320px;*/
/*}*/
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
</style>
import { companyRepository, userRepository } from '../../repository';
import { router } from '../../_helpers';

const loggedIn = localStorage.getItem('user');
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? user
    : { status: { loggedIn: false }, errors: [], user: { user: {}}, isAdmin: false };
    // ? { status: { loggedIn: !!loggedIn }, user }
    // : { status: { loggedIn: !!loggedIn }, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit('loginRequest', { username });

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource

                userRepository.login(username, password)
                    .then(
                        res => {
                            commit('loginSuccess', res);
                            resolve(user)
                            router.push('/');
                        },
                        error => {
                            commit('loginFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        register({ dispatch, commit }, { name, email }) {
            commit('registerRequest', { name, email });

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource

                userRepository.register(name, email)
                    .then(
                        res => {
                            commit('registerSuccess', res);
                            resolve(res)
                            // router.push('/');
                        },
                        error => {
                            commit('registerFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        logout({ commit }) {
            userRepository.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = null;
        },
        registerRequest(state, user) {
        },
        loginSuccess(state, res) {
            state.status = { loggedIn: true };
            state.user = res;
            state.errors = [];
            state.isAdmin = res.user.email === 'super@puper.com';//res.user.roles?.filter(r=> r === 'Admin').length;
            localStorage.setItem('user', JSON.stringify(state))
        },
        registerSuccess(state, res) {
            state.errors = [];
        },
        loginFailure(state, data) {
            state.status = {};
            state.user = null;
            state.errors = [data.message];
            state.isAdmin = false;
        },
        registerFailure(state, data) {
            state.errors = [data.message];
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.isAdmin = false;
        }
    }
}

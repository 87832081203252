<template>
  <div class="col-xs-12 col-sm-6">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataFormat="dataFormat"
              :dataSource="dataSource"
              @dataPlotClick="onSliceClick"
              ref="fc"
          ></fusioncharts>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "FusionChart2",
  data(){
    return {
      type: 'pie2d',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        "chart": {
          "caption": "Market Share of Web Servers",
          "plottooltext": "<b>$percentValue</b> of web servers run on $label servers",
          "showLegend": "1",
          "showPercentValues": "1",
          "legendPosition": "bottom",
          "useDataPlotColorForLabels": "1",
          "enableMultiSlicing": "0",
          "theme": "gammel",
          "showlegend": "0"
        },
        "data": [
          {
            "label": "Apache",
            "value": "32647479"
          },
          {
            "label": "Microsoft",
            "value": "22100932"
          },
          {
            "label": "Zeus",
            "value": "14376"
          },
          {
            "label": "Other",
            "value": "18674221"
          }
        ]
      },
      radioValue: 'none',
      lastActive: 'none'
    }
  },
  methods: {
    // function to slice items when radio buttons are clicked using the slicePlotItems api
    onChangeItem: function () {
      const chart = this.$refs.fc.chartObj,
          lastActive = this.lastActive,
          value = this.radioValue;
      this.lastActive = value;
      if (value === 'none') {
        chart.slicePlotItem(lastActive, false);
      } else {
        chart.slicePlotItem(value, true);
      }
    },
    // function to actiavte radio buttons when plots are clicked
    onSliceClick: function (e) {
      var isSliced = e.data.isSliced;
      if (isSliced) {
        this.lastActive = this.radioValue = 'none'
      } else {
        this.lastActive = this.radioValue = e.data.index
      }
    }
  }
}
</script>

<style scoped>

</style>
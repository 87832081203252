<template>
  <section class="contentWrapper" style="min-height: 536px;">

    <section class="contentWrapTop">
      <div class="row">
        <div class="col-xs-12">
          <div class="contentWrapTopLeft">
            <h1>Dashboard</h1>
          </div>
          <div class="contentWrapTopRight">
            <ul>
              <li>
                <a href="#">
                  <i class="fa fa-cube"></i>
                  <span>Home</span>
                </a>
              </li>
              <li><a href="#">Layouts</a></li>
              <li><a href="#">Box</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="contentWrap">
      <div class="row">
        <StreamSection/>
      </div>
      <div class="row">
        <div class="dashboard_section">
<!--          asd,mads.1-->
        </div>
      </div>
    </section>
    <section class="contentWrap">
      <div class="row">
<!--        No Active Actions Yet-->
<!--        <widget/>-->
<!--        <widget color="mt-widget-color-success"/>-->
<!--        <widget color="mt-widget-color-warning"/>-->
<!--        <widget color="mt-widget-color-error"/>-->


<!--        <widget color="mt-widget-color-invert"/>-->
<!--        <widget color="mt-widget-color-invert-success"/>-->
<!--        <widget color="mt-widget-color-invert-warning"/>-->
<!--        <widget color="mt-widget-color-invert-error"/>-->

<!--        <widgetBig/>-->
<!--        <widgetBig color="mt-widget-lg-color-success"/>-->
<!--        <widgetBig color="mt-widget-lg-color-warning"/>-->
<!--        <widgetBig color="mt-widget-lg-color-error"/>-->
      </div>
    </section>

  </section>
<!--  <section class="contentWrapTop">-->
<!--    <div class="row">-->
<!--      <div class="col-xs-12">-->
<!--        <div class="contentWrapTopLeft">-->
<!--          <h1>General Form Elements-->
<!--            <small>Preview</small>-->
<!--          </h1>-->
<!--        </div>-->
<!--        <div class="contentWrapTopRight">-->
<!--          <ul>-->
<!--            <li>-->
<!--              <a href="#">-->
<!--                <i class="fa fa-edit"></i>-->
<!--                <span>Home</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li><a href="#">Forms</a></li>-->
<!--            <li><a href="#">General Elements</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--    <div>-->
<!--      <span>{{loggedIn}}</span>-->
<!--        <h1>Hi {{user.firstName}}!</h1>-->
<!--        <p>You're logged in with Vue + Vuex & JWT!!</p>-->
<!--        <h3>Users from secure api end point:</h3>-->
<!--        <em v-if="users.loading">Loading users...</em>-->
<!--        <span v-if="users.error" class="text-danger">ERROR: {{users.error}}</span>-->
<!--        <ul v-if="users.items">-->
<!--            <li v-for="user in users.items" :key="user.id">-->
<!--                {{user.id + ' ' + user.name}}-->
<!--            </li>-->
<!--        </ul>-->
<!--        <p>-->
<!--            <router-link to="/login">Logout</router-link>-->
<!--        </p>-->
<!--    </div>-->
</template>

<script>
import StreamSection from "@/views/stream/StreamSection";
import widget from "./widget";
import widgetBig from "./widgetBig";
import { mapState } from 'vuex'
import LiveVideo from "../stream/LiveVideo";
import setActive from "../../mixins/globalFunction.js";
export default {
  components: {widget, LiveVideo, StreamSection},
  mixins: [setActive],
    computed: {
        user () {
            return this.$store.state.authentication.user;
        },
        users () {
            return this.$store.state.user.all;
        },
      ...mapState({
        loggedIn: state => state.authentication.status.loggedIn,
      })
    },
    created () {
    },
  mounted() {
    this.setActive("Dashboard");
  }
};
</script>
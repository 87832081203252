import axios from "axios";
import { authHeader } from '@/_helpers';
import handleResponse from '../_services/shared';

export const fileRepository = {
    add,
    getAll,
    get,
    edit,
    remove,
    upload,
    uploadVideo,
};

function add(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/file/add`, requestOptions)
        .then(handleResponse);
}
function upload(data, uploadPercentage) {
    const formData = new FormData();
    formData.append("files", data);
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'multipart/form-data',...authHeader()  },
    //     body: formData
    // };

    return axios.post(`/api/file/uploadFile`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',...authHeader()
            },
            onUploadProgress: function(progressEvent) {
                let perc = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (typeof uploadPercentage === 'function') {
                    uploadPercentage(perc)
                }
            }.bind(this)
        }
    )
    // return fetch(`/api/file/uploadFile`, requestOptions)
    //     .then(handleResponse);
}
function uploadVideo(data, uploadPercentage) {
    const formData = new FormData();
    formData.append("files", data);
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'multipart/form-data',...authHeader()  },
    //     body: formData
    // };

    return axios.post(`/api/file/uploadVideo`,
        formData, 
        {
            headers: {
                'Content-Type': 'multipart/form-data',...authHeader()
            },
            onUploadProgress: function(progressEvent) {
                let perc = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (typeof uploadPercentage === 'function') {
                    uploadPercentage(perc)
                }
            }.bind(this)
        }
    )
    // return fetch(`/api/file/uploadFile`, requestOptions)
    //     .then(handleResponse);
}

function edit(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/file/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/file/delete`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/file/${data}`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/file`, requestOptions).then(handleResponse);
}

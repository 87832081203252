import HomePage from '../views/home/HomePage'
import LoginPage from '../views/auth/LoginPage'
import DevicePage from '../views/device/DevicePage'
import DeviceKeyPage from '../views/deviceKey/DeviceKeyPage'
import NotificationPage from '../views/notification/NotificationPage'
import UserPage from '../views/user/UserPage'
import StreamPage from '../views/stream/StreamPage'
import AlertFilterPage from '../views/alertFilter/AlertFilterPage'
import AnalyzerPage from '../views/analyzer/AnalyzerPage'
import AnalyzerResultPage from '../views/analyzer/AnalyzerResultPage'
import AnalyzerAlertsPage from '../views/analyzerAlerts/AnalyzerAlertsPage'
import AnalyzerRecordsPage from '../views/analyzerRecords/AnalyzerRecordsPage'
import PolygonPage from '../views/polygon/PolygonPage'
import ChartPage from '../views/charts/chartsPage'
import CompanyPage from '../views/company/CompanyPage'
import AlertCalendarView from '../views/calendar/AlertCalendarView'
import ChartDevicePage from '../views/chart_device/chartDevicePage'
import AnalyzerPageById from '../views/analyzer/ResultModal'
import AlertsPage from '../views/alerts/AlertsPage'
import GeneratedAlertList from '../views/alerts/GeneratedAlertList'
import DemoRegisterPage from '../views/auth/DemoRegisterPage'
import NotFound from '../views/shared/NotFound'
import ProfileSettings from '../views/user/ProfileSettings'

import { createWebHistory, createRouter } from "vue-router";

export const router = new createRouter({
    history: createWebHistory(),
    routes: [{
            // path: "*",
            path: "/:catchAll(.*)",
            name: "NotFound",
            component: NotFound,
            meta: {
                requiresAuth: false
            }
        },
        { path: '/', component: HomePage },
        { path: '/auth/login', component: LoginPage },
        { path: '/auth/demo', component: DemoRegisterPage },
        { path: '/device', component: DevicePage },
        { path: '/deviceKey', component: DeviceKeyPage },
        { path: '/notifications', component: NotificationPage },
        { path: '/user', component: UserPage },
        { path: '/stream', component: StreamPage },
        { path: '/analyzer', component: AnalyzerPage },
        { path: '/analyzerResult/:analyzerId', component: AnalyzerResultPage },
        { path: '/analyzerRecords/:analyzerId', component: AnalyzerRecordsPage },
        { path: '/analyzerAlerts/:analyzerId', component: AnalyzerAlertsPage },
        { path: '/alerts', component: AlertsPage },
        { path: '/generated-alerts', component: GeneratedAlertList },
        { path: '/alertFilter/:analyzerId', component: AlertFilterPage },
        { path: '/device/:id', component: AnalyzerPageById },
        { path: '/polygon/:deviceId', component: PolygonPage },
        { path: '/charts', component: ChartPage },
        { path: '/chart-device', component: ChartDevicePage },
        { path: '/company', component: CompanyPage },
        { path: '/alert-calendar-view', component: AlertCalendarView },
        { path: '/profile-setting', component: ProfileSettings },
        //
        // // otherwise redirect to home
        // { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login', '/auth/demo'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
   return next('/auth/login');
  }

  next();
})
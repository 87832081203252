import { userRepository } from '../repository';

const initialState = {
    all: [],
    loading: false
}

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        add({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                userRepository.add(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        edit({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                userRepository.edit(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        remove({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                userRepository.remove(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        getAll({ commit }) {
            commit('getAllRequest');

            return userRepository.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
        get({ commit }, data) {

            return userRepository.get(data)
                .then(
                    response => {
                        return response;
                    }
                    // data => commit('getAllSuccess', data),
                    // error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        addRequest(state) {
            state.loading = true;
        },
        addSuccess(state) {
            state.loading = false;
        },
        addFailure(state) {
            state.loading = false;
        },
        getAllRequest(state) {
            state.loading = true;
        },
        getAllFailure(state) {
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data;
        },
    }
}

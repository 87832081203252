<template>

  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Analyzer Filter</h3>
      </div>
      <video autoplay width="0" height="0" controls  :src="videoSource" id="video">
        Your browser does not support the video tag.
      </video>
      <div class="forms-block">
        <h3>Analyzed Data</h3>
        <textarea name="" id="" cols="30" rows="10">{{JSON.stringify(analyzerData)}}</textarea>

        <div class="timepicker-block">
          <label>From:</label>
          <vue-timepicker format="HH:mm:ss"></vue-timepicker>

          <label>To:</label>
          <vue-timepicker format="HH:mm:ss"></vue-timepicker>
        </div>

        <div class="options-block form-group">
          <label>polygon: </label>
          <select  class="form-select form-control" v-model="currentPolygon">
            <option v-for="option in sourcePolygons" v-bind:value="option">
              {{ option.name }}
            </option>
          </select>

          <label>object: </label>
          <select class="form-select form-control" v-model="currentlabel">
            <option v-for="option in labels" v-bind:value="option.val">
              {{ option.name }}
            </option>
          </select>
        </div>


      </div>

      <canvas width="640" height="480"  style="cursor:crosshair" @mousedown="point_it($event)" @contextmenu="contMenu" ref="canRef" id="canvas"></canvas>


      <div class="footer">
        <div class="list">
          <span>Object Count: {{ objectCount }}</span>
        </div>

        <button @click="startAnalyzer" class="btn btn-info btn-flat">Analyze</button>
        <button @click="getData" class="btn btn-success btn-flat">Get Data</button>
        <!--        <button class="btn btn-info btn-flat" @click="undo">Undo</button>-->
        <!--        <button class="btn btn-info btn-flat" @click="clear">Clear</button>-->
        <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    }
  },
  components:{VueTimepicker},
  name: "v-options-modal",
  data() {
    return{
      name: '',
      url: '',
      iter: -1,
      analyzerData: [],
      item: {},
      perimeter: [],
      objectCount: 0,
      video_draw: false,
      complete: false,
      ctx: {},
      objectList: {},
      end: false,
      canvas: document.createElement("canvas"),
      video: document.createElement("video"),
      sourceItem: {},
      sourcePolygons: [],
      currentPolygon: null,
      currentlabel: null,
      startAnalyze: false,
      labels: [{
        name: 'Person',
        val: 0
      }, {
        name: 'Car',
        val: 2,
      }],
      videoSource: '',
      dataUrl: null,
      items: [
        { id: 1, src: 'https://raw.githubusercontent.com/matteomattei/jPolygon/master/image.jpg' },
      ]
    }
  },
  methods:{
    getData() {

      this.$questionHub.sendCommand('AnalyzerData', this.id)
    },
    contMenu() {
      return false;
    },
    point_it(event) {
      console.log(event)

      if(this.complete){
        // alert('Polygon already created');
        return false;
      }
      var rect, x, y;

      if(event.ctrlKey || event.which === 3 || event.button === 2){
        if(this.perimeter.length===2){
          alert('You need at least three points for a polygon');
          return false;
        }
        x = this.perimeter[0]['x'];
        y = this.perimeter[0]['y'];
        if(this.check_intersect(x,y)){
          alert('The line you are drowing intersect another line');
          return false;
        }
        this.end = true;
        this.draw(this.end);
        // alert('Polygon closed');
        // event.preventDefault();
        return false;
      } else {
        rect = this.canvas.getBoundingClientRect();
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
        if (this.perimeter.length>0 && x === this.perimeter[this.perimeter.length-1]['x'] && y === this.perimeter[this.perimeter.length-1]['y']){
          // same point - double click
          return false;
        }
        if(this.check_intersect(x,y)){
          alert('The line you are drowing intersect another line');
          return false;
        }
        this.perimeter.push({'x':x,'y':y});
        this.draw(false);
        return false;
      }
    },
    undo() {
      this.ctx = undefined;
      this.perimeter.pop();
      this.complete = false;
      this.start(true);
    },
    clear() {

      this.ctx = undefined;
      this.perimeter = new Array();
      this.complete = false;
      //document.getElementById('coordinates').value = '';
      this.start();
    },
    start(with_draw) {

      const canvas = document.createElement("canvas");
      this.ctx = canvas.getContext('2d');
      // this.ctx = this.$refs.canRef;
      if (with_draw)
        this.draw(false);

    },
    cancel(){
      this.$emit('cancel')
    },
    draw(end) {
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = "white";
      this.ctx.lineCap = "square";
      this.ctx.beginPath();
      for(let i=0; i<this.perimeter.length; i++){
        if(i===0){
          this.ctx.moveTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
          end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
        } else {
          this.ctx.lineTo(this.perimeter[i]['x'],this.perimeter[i]['y']);
          end || this.point(this.perimeter[i]['x'],this.perimeter[i]['y']);
        }
      }
      if(end){
        this.ctx.lineTo(this.perimeter[0]['x'],this.perimeter[0]['y']);
        this.ctx.closePath();
        this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        this.ctx.fill();
        this.ctx.strokeStyle = 'blue';
        this.complete = true;
      }
      this.ctx.stroke();

      // print coordinates
      if(this.perimeter.length === 0){
        // document.getElementById('coordinates').value = '';
      } else {
        //document.getElementById('coordinates').value = JSON.stringify(perimeter);
      }
    },
    check_intersect(x,y){
      if(this.perimeter.length < 4){
        return false;
      }
      var p0 = new Array();
      var p1 = new Array();
      var p2 = new Array();
      var p3 = new Array();

      p2['x'] = this.perimeter[this.perimeter.length-1]['x'];
      p2['y'] = this.perimeter[this.perimeter.length-1]['y'];
      p3['x'] = x;
      p3['y'] = y;

      for(let i=0; i<this.perimeter.length-1; i++){
        p0['x'] = this.perimeter[i]['x'];
        p0['y'] = this.perimeter[i]['y'];
        p1['x'] = this.perimeter[i+1]['x'];
        p1['y'] = this.perimeter[i+1]['y'];
        if(p1['x'] === p2['x'] && p1['y'] === p2['y']){ continue; }
        if(p0['x'] === p3['x'] && p0['y'] === p3['y']){ continue; }
        if(this.line_intersects(p0,p1,p2,p3)===true){
          return true;
        }
      }
      return false;
    },

    line_intersects(p0, p1, p2, p3) {
      let s1_x, s1_y, s2_x, s2_y;
      s1_x = p1['x'] - p0['x'];
      s1_y = p1['y'] - p0['y'];
      s2_x = p3['x'] - p2['x'];
      s2_y = p3['y'] - p2['y'];

      let s, t;
      s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
      t = ( s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);

      if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
      {
        // Collision detected
        return true;
      }
      return false; // No collision
    },
    point(x, y){
      this.ctx.fillStyle="white";
      this.ctx.strokeStyle = "white";
      this.ctx.fillRect(x-2,y-2,4,4);
      this.ctx.moveTo(x,y);
    },
    pointColor(x, y, color){
      this.ctx.fillStyle=color;
      this.ctx.strokeStyle = color;
      this.ctx.fillRect(x-2,y-2,4,4);
      this.ctx.moveTo(x,y);
    },
    drawData(k) {
      this.ctx.beginPath();
      if (this.startAnalyze) {
        for (let i in this.objectList) {
          let current = this.objectList[i];
          for (let j in current) {
            let obj = current[j];
            console.log(obj)
            let x = obj.x1 + (obj.x2/2);
            let y = obj.y1 + (obj.y2/2);
            this.pointColor(x,y, 'red');
            //     this.ctx.rect(rc.x1, rc.x2, rc.y1, rc.y2);

          }
        }
      }
      // for(let i = k; i < k + 30; i++) {
      //   if (this.analyzerData.length > i) {
      //     let rc = this.analyzerData[i];
      //     this.ctx.rect(rc.x1, rc.x2, rc.y1, rc.y2);
      //   }
      // }
      this.ctx.stroke();
    },
    drawTest() {
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      var ctx = this.ctx;
      var draw = this.draw;
      var end = this.end;
      var drawData = this.drawData;
      var iter = this.iter;
      var video_draw = this.video_draw;
      this.video = document.getElementById('video');
      var video = this.video;

// set canvas size = video size when known
      video.addEventListener('loadedmetadata', () => {
        // canvas.width = video.videoWidth;
        // canvas.height = video.videoHeight;
        // this.start(false);
      });

      video.addEventListener('play', function() {
        var $this = this; //cache
        (function loop() {
          if (!$this.paused && !$this.ended) {
            // if (!video_draw)
            ctx.drawImage($this, 0, 0, 640, 480);

            draw(end);
            // ctx.beginPath();
            // ctx.rect(20, 20, 150, 100);
            // ctx.stroke();
            iter = iter + 1;
            drawData(iter * 30);
            video_draw = true;
            setTimeout(loop, 1000 / 30); // drawing at 30fps
          }
        })();
      }, 0);
    },
    submit() {
      let data = {
        deviceId: this.$route.params.deviceId,
        lines: JSON.stringify(this.perimeter),
      };
      if (!this.edit_mode) {
        this.$store.dispatch('polygon/add', data).then(data => {
          this.cancel();
        });
      } else {
        data.id = this.item.id;
        this.$store.dispatch('polygon/edit', data).then(data => {
          this.cancel();
        });
      }
    },
    onDataChanged({data}) {

      try {
        let current = JSON.parse(data);
        this.analyzerData = JSON.parse(current.message);
        let cars = this.analyzerData;

        let currentObjects = this.objectList;

        for (let i in this.analyzerData) {
          let cur = this.analyzerData[i];
          if (!currentObjects[cur.number]) {
            currentObjects[cur.number] = [];
          }

          currentObjects[cur.number].push(cur);
        }

        // this.calculateObjectCount();
        // this.resetVideo();
      } catch (e) {

      }
      // let analyzer_info = JSON.parse(data);
      // console.log('analyzer-info:', data);
    },
    calculateObjectCount() {
      let count = 0;
      for (let i in  this.objectList) {
        if ( this.objectList.hasOwnProperty(i) && this.objectList[i].length > 40) {

          let found = false;
          if (this.currentPolygon) {

            let currentList = this.objectList[i];
            for (let j in currentList) {
              let cord = currentList[j];
              let x = cord.x1 + (cord.x2/2);
              let y = cord.y1 + (cord.y2/2);
              if (this.check_intersect(x,y)) {
                found = true;
              }
            }

            if (found) {
              count++
              break;
            }
          } else {
            count++;
          }
        }
      }

      this.objectCount = count;

    },
    resetVideo() {
      this.video.pause();
      this.video.currentTime = 0;
      this.video.play();
    },
    startAnalyzer() {
      // this.startAnalyze = true;
      console.log(this.objectList);
      this.calculateObjectCount();
      this.resetVideo();
    }
  },
  mounted() {
    // this.$questionHub.$on('analyzer-data', this.onDataChanged)
    this.start(true);
    if (this.edit_mode) {
      console.log('edit polygon: ', this.id)
      this.$store.dispatch('polygon/get', this.id).then(response => {
        this.item = response;
      });
    } else {
      console.log('add polygon')

      this.$store.dispatch('device/get', this.id).then(response => {

        this.sourceItem = response.source;
        this.sourcePolygons = response.source.sourcePolygons;
        this.videoSource = this.sourceItem.url;

//         const video = document.getElementById("video");
//
//         const canvas = document.createElement("canvas");
// // scale the canvas accordingly
//         canvas.width = video.videoWidth;
//         canvas.height = video.videoHeight;
// // draw the video at that frame
//         canvas.getContext('2d')
//             .drawImage(video, 0, 0, canvas.width, canvas.height);
// // convert it to a usable data URL
//         this.dataUrl = canvas.toDataURL();

        // this.ctx = canvas.getContext('2d');
        // this.img = new Image();
        // this.img.src = this.items[0].src;
        // console.log('this.img.src:', this.img.src);
        // this.img.onload = () => {
        //   console.log('this.ctx:', this.ctx);
        //   this.ctx.drawImage(this.img, 0, 0);
        // }
        // this.drawItem(0, 0, 0);
        this.drawTest();
      });
    }
  },
  watch: {
    videoSource : (a) => {
      console.log(a)
    },
    currentPolygon : function(pol) {
      this.perimeter = JSON.parse(pol.lines);
      this.end = true;
      // this.resetVideo();
    },
    currentlabel : function(label) {
      this.label = label;
      // this.resetVideo();
    }
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > canvas{
  width: 640px;
  height: 480px;
}
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block > label{
  display: block;
  margin-top: 10px;
}
select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 96%;
  background-size: 10px;
  background-color: #fff;
}
.options-block{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 3px 1em;
  padding: 3px;
}
.options-block > input{
  width: 100%;
}
.timepicker-block{
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  grid-gap: 3px 2em;
  padding: 0 5px ;
  align-items: center;
  margin-bottom: 10px;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}

</style>
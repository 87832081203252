<template>
  <div class="col-12">
    <section class="boxWrap">
      <div class="box">
        <div class="boxHeader">
          <div class="boxHeaderLeft">
            <h3>
              <i class="fa fa-bar-chart"></i>
              <span><a href="http://highcharts.com/">Highmaps.js</a></span>
            </h3>
          </div>
          <div class="boxHeaderRight"></div>
        </div>
        <div class="boxBody">


            <div class="description">
              <div class="block">
                <label class="label label-success">Label Name</label>
                <span>58525222222</span>
              </div>
              <div class="block">
                <label class="label label-success">Label Name</label>
                <span>58525</span>
              </div>
              <div class="block">
                <label class="label label-success">Label Name</label>
                <span>58525</span>
              </div>
              <div class="block">
                <label class="label label-warning">Label Name</label>
                <span>58525</span>
              </div>
            </div>



            <div class="chart">
              <fusioncharts
                  :type="type"
                  :width="width"
                  :height="height"
                  :dataFormat="dataFormat"
                  :dataSource="dataSource"
              >
              </fusioncharts>
            </div>

          <div class="camera">
            <video width="60%" height="100%" controls>
              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="boxFooter">
          <div class="boxFooterLeft">
            The footer of the box
          </div>
          <div class="boxFooterRight">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "item",
  data(){
    return{
      type: 'column2d',
      width: '32%',
      height: '180',
      dataFormat: 'json',
      dataSource: {
        chart: {
          caption: 'Recommended Portfolio Split',
          subCaption: 'For a net-worth of $1M',
          showValues: '1',
          showPercentInTooltip: '0',
          numberPrefix: '$',
          enableMultiSlicing: '1',
          theme: 'gammel'
        },
        data: [
          {
            label: 'Equity',
            value: '300000'
          },
          {
            label: 'Debt',
            value: '230000'
          },
          {
            label: 'Bullion',
            value: '180000'
          },
          {
            label: 'Real-estate',
            value: '270000'
          },
          {
            label: 'Insurance',
            value: '20000'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.boxBody{
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxBody > div{
  width: 32.9%;
  height: 155px;
  display: flex;
  justify-content: center;
}
.description{
  display: grid !important;
  height: auto !important;
}
.description > div{
  margin: 3px;
}
.description > div > *{
  vertical-align: middle;
  margin: 5px;
}
</style>
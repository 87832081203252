import axios from "axios";
import { router } from "../_helpers";
import { authHeader } from '@/_helpers';
import Environment from "@/environment/env.js";
const BASE_URL = Environment.APP_BASE_URL;

axios.interceptors.response.use(
  function(response) {
    if (response?.status == 401 || response?.status == 403) {
        this.$store.dispatch('authentication/logout').then(() => {
          this.$router.push('/auth/login');
          this.$disconnectHub();
        });
  }
  else{
  return response;
  }
  },
  function(error) {
    this.$store.dispatch('authentication/logout');
    if (
      error.response &&
      error.response.data
    ) {
      if (error.response.data.code === 401) {
        if (
            error.response.config &&
            !error.response.config.url.includes("/auth/login")
        ) {
          // store.dispatch("auth/logout");
          router.push("/auth/sign-in");
        }
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function(config) {
  let self = this;
  // const token = store.getState().session.token;
  // let obj = localStorage.getItem("user");
  // let user = JSON.parse(obj);
  // if (user && user.tokens.access) {
  //   Object.assign(config.headers, authHeader());
  //   config.headers.Authorization = "Bearer " + user.tokens.access.token;
  // }
  Object.assign(config.headers, authHeader());
  return config;
});

class RequestService {
  base_url = BASE_URL;

  constructor() {
    this.base_url = BASE_URL;
  }
  create(path, data, token = this.generateToken(), config = {}) {
    return axios.post(this.generatePath(path), data, {
      cancelToken: token,
      ...config
    });
  }
  read(path, options = {}, token = this.generateToken()) {
    return axios.get(this.generatePath(path), {
      cancelToken: token
    });

  }
  update(path, data, token = this.generateToken()) {
    return axios.patch(this.generatePath(path), data, {
      cancelToken: token
    });
  }
  delete(path, token = this.generateToken()) {
    return axios.delete(this.generatePath(path), {
      cancelToken: token
    });
  }
  generateToken() {
    const cancelTokenSource = axios.CancelToken.source();
  }
  generatePath(path) {
    return this.base_url + path;
  }
}

export default new RequestService();

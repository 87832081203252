import { createStore } from 'vuex';

import { alert } from './alert.module';
import { authentication } from './auth/authentication.module';
import { device } from './device.module';
import { user } from './users.module';
import { polygon } from './polygon.module';
import { notification } from './notification.module';
import { file } from './file.module';
import { deviceKey } from './deviceKey.module';
import { company } from './companies.module';
import { alertFilter } from './alertFilter.module';
import { app } from './app.module';

const store = createStore({
    modules: {
        app,
        alert,
        authentication,
        file,
        device,
        deviceKey,
        polygon,
        notification,
        user,
        alertFilter,
        company,
    }
});

export default store;

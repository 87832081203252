<template>
  <div class="modal-polygon">
    <div class="content">
      <div class="header panel-heading">
        <h3>Polygon</h3>
      </div>

      <img src="https://www.indiewire.com/wp-content/uploads/2019/07/Screen-Shot-2019-07-08-at-5.02.47-PM.png" alt="">
      <textarea name="" id="" cols="30" rows="10"></textarea>



      <div class="footer">
        <button class="btn btn-info btn-flat">Undo</button>
        <button class="btn btn-info btn-flat">Clear</button>
        <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-polygon-modal",
  methods:{
    cancel(){
      this.$emit('cancel')
    },
    confirm(){
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > img{
  width: 640px;
  height: 480px;
}
.content > textarea{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}


</style>
import {notificationRepository} from '@/repository';

const initialState = {
    all: [],
    unreadAll: [],
    loading: false,
    arrives: [],
}

export const notification = {
    namespaced: true,
    state: initialState,
    actions: {
        add({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                notificationRepository.add(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        arrive({ dispatch, commit }, data) {

            return new Promise((resolve, reject) => {
                let push = !state.arrives.filter(r=>r.id === data.id);
                commit('addArriveNotification', data);
                if (push) {
                    resolve(data);
                } else {
                    reject(data)
                }
            });
        },
        edit({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                notificationRepository.edit(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        markRead({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                notificationRepository.markRead(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        remove({ dispatch, commit }, data) {
            commit('addRequest', data);

            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                notificationRepository.remove(data)
                    .then(
                        incoming => {
                            commit('addSuccess', incoming);
                            resolve(incoming)
                        },
                        error => {
                            commit('addFailure', error);
                            dispatch('alert/error', error, { root: true });
                            reject(error)
                        }
                    );
            });
        },
        getAll({ commit }, read = 1) {

            commit('getAllRequest');

            return notificationRepository.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
        getAllUnread({ commit, state }) {

            console.log('notifications/getAllUnread()');
            commit('getAllUnreadRequest');

            return new Promise((resolve, reject) => {
                let res = {
                    list: []
                }
                // Do something here... lets say, a http call using vue-resource
               notificationRepository.getAllUnread()
                    .then(
                        data => {
                            commit('getAllUnreadSuccess', data);
                            for (let i in data.results) {
                                let current = data.results[i];
                                if (!state.arrives.filter(r=> r.id === current.id).length) {
                                    res.list.push(current);
                                    commit('addArriveNotification', current);
                                }
                            }
                            resolve(res)
                        },
                        error => {
                            commit('getAllUnreadFailure', error);
                            reject(res)
                        }
                    );
            });

        },
        get({ commit }, data) {

            return notificationRepository.get(data)
                .then(
                    response => {
                        return response;
                    }
                    // data => commit('getAllSuccess', data),
                    // error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        addRequest(state) {
            state.loading = true;
        },
        addArriveNotification(state, data) {
            // state.loading = true;
            if (!state.arrives.filter(r=>r.id === data.id).length) {
                state.arrives.push(data);
            }
        },
        addSuccess(state) {
            state.loading = false;
        },
        addFailure(state) {
            state.loading = false;
        },
        getAllRequest(state) {
            state.loading = true;
        },
        getAllUnreadRequest(state) {
            state.loading = true;
        },
        getAllFailure(state) {
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data;
        },
        getAllUnreadFailure(state) {
            state.loading = true;
        },
        getAllUnreadSuccess(state, data) {
            state.unreadAll = data.results;
        },
    }
}

<template>

  <div class="col-xs-12 col-md-4">
    <div v-bind:class="'mt-widget ' +'red'">
      <div class="mt-widgetIcon">
        <i class="fa fa-flag-o"></i>
      </div>
      <div class="mt-widgetContent mb-10">
        <h2>{{ item.alert.name }}</h2>
        <h3>{{ item.alert.start }} - {{item.alert.end}} ({{item.alert.timeZone}})</h3>
        <div class="mt-widgetProgress">
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
          </div>
          <span v-if="alertInfos[item.alert.id] ? alertInfos[item.alert.id].State !== 1 : item.state !== 1">Starts in {{alertInfos[item.alert.id] ? alertInfos[item.alert.id].LeftSpan : item.leftSpan}}</span>
          <span v-if="alertInfos[item.alert.id] ? alertInfos[item.alert.id].State === 1 : item.state === 1">Started</span>
          <span v-if="alertInfos[item.alert.id] ? alertInfos[item.alert.id].State === 2 : item.state === 2">(Success Finished.)</span>
        </div>
      </div>

      <div class="btn-block">

        <button @click="this.$router.push(`/analyzerAlerts/${item.alert.id}`)" class="btn  btn-flat" title=""><i class="fa fa fa-area-chart" data-v-bb6f2a20=""></i></button>
<!--        <button @click="" class="btn  btn-flat" title=""><i class="fa fa fa-pencil" data-v-bb6f2a20=""></i></button>-->

        <button @click="deleteItem(item.alert)" class="btn btn-danger btn-flat" title="Delete"><i class="fa fa-trash-o" data-v-bb6f2a20=""></i></button>
      </div>
    </div>
    <VConfirmModal v-if="modalConfirm" @confirm="confirmModalCallback" @cancel="confirmModalCheck(false)"/>
  </div>
</template>

<script>
import VConfirmModal from "../../components/v-confirm-modal"
import {mapState} from "vuex";

export default {
  props:{
    item: {
      type: Object,
      required: true,
    }
  },
  components: {VConfirmModal},
  data() {
    return{
      modalConfirm: false,
      confirmModalCallback: () => {

      }
    }
  },
  computed: {
    alertInfos () {
      return this.$store.state.alert.infos;
    },
    ...mapState({
      polygons: state => state.polygon.all,
      alertFilters: state => state.alertFilter.all,
    })
  },
  created () {
    // this.$questionHub.$on('source-info', this.onScoreChanged)
  },
  mounted() {
    var a = this.item;
    // this.getData();
  },
  watch: {
  },
  methods: {
    confirmModalCheck(open){
      this.modalConfirm = open;
      if (!open) {
        this.confirmModalCallback = () => {

        }
        this.$store.dispatch('alertFilter/getAll');
      }
    },
    deleteItem(item) {
      this.confirmModalCheck(true);
      this.confirmModalCallback = () => {
        this.$store.dispatch('alertFilter/remove',  [item.id]).then(result => {
          this.confirmModalCheck(false);
        });
      }
    }
  }
};
</script>


<style scoped>
.alert{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  margin-top: 40px;
  background-color: #fff;
  /*-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.17);*/
  /*box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.17);*/
}
.alert > div:first-child{
  display: grid;
  grid-template-columns: 1fr;
}
.alert > div p{
  font-weight: bold;
  font-size: 15px;
  color: #1a1a1a;
}
.alert > div p > span{
  font-weight: initial;
}
.btn-block{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px;
  grid-gap: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}
.mt-widget{
  position: relative;
  min-height: 180px;
  padding-bottom: 80px;
}
span{
  float: left;
}
</style>
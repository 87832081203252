import RequestService from "../_services/request.service";

import { authHeader } from '@/_helpers';
import handleResponse from '../_services/shared';

export const deviceKeyRepository = {
    add,
    getAll,
    get,
    edit,
    remove,
};

function add(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/deviceKey/add`, requestOptions)
        .then(handleResponse);
}

function edit(data) {

    return RequestService.create(
        `/api/deviceKey/edit`, data
    ).then(
        data => {
            return data.data;
        },
        error => {
            return error;
        }
    );
}

function remove(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/deviceKey/delete`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(data) {
    return RequestService.read(
        `/api/deviceKey/${data}`
    ).then(
        data => {
            return data.data;
        },
        error => {
            return error;
        }
    );
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/deviceKey`, requestOptions).then(handleResponse);
}

<template>
  <div class="modal-options">
      <div class="content">
        <div class="header panel-heading">
          <h3 v-if="edit_mode"> Edit Company</h3>
          <h3 v-else> Add Company</h3>
        </div>
        <div class="body">
          <div v-for="error in errors" v-bind:key="error?.id">
            <span style="color: red">{{typeof error === 'Array' ? error[0] : error}}</span>
          </div>
          <span style="color: red" v-if="confirmError">Passwords do not match</span>
          
          <div class="form-group">
            <label>Company Name</label>
            <input v-model="companyName" autocomplete="nope" type="text" placeholder="">
          </div>

          <div v-if="!edit_mode" class="form-group">
            <label>Email</label>
            <input v-model="email" autocomplete="nope" type="email" placeholder="">
          </div>

          <div class="form-group">
            <label>Image</label>
            <input ref="file" v-on:change="handleFileUpload()" autocomplete="off" type="file" class="form-control" placeholder="company name...">
          </div>
          <div class="form-group" v-if="avatarUrl">
            <label>Image</label>
            <img style="width: 200px;" :src="(avatarUrl ? $apiUrl + '/api/file/image/' + avatarUrl : 'img/avatar.png')"  alt="img">
          </div>
        </div>
        <div class="footer">
          <button @click="submit" class="btn btn-info btn-flat">Accept</button>
          <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>


export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String
    }
  },
  name: "v-options-modal",
  data() {
    return{
      errors: [],
      companyName: '',
      email: '',
      password: '',
      passwordError: false,
      passwordMessage: "",
      confirmError: false,
      confirmPassword: '',
      item: {},
      strength: 0,
      avatar: null,
      avatarUrl: null,
    }
  },
  methods:{
    checkConfirm() {
      this.confirmError = this.password !== this.confirmPassword;
    },
   checkPassword() {
     let password = this.password;
  this.strength = 0;
  if (password.match(/[a-z]+/)) {
    this.strength += 1;
  }
  if (password.match(/[A-Z]+/)) {
    this.strength += 1;
  }
  if (password.match(/\d+/)) {
    this.strength += 1;
  }
  if (password.match(/[$@#&!]+/)) {
    this.strength += 1;

  }

  if (password.length >= 8) {
    this.strength += 1;
  }

  if (this.strength < 5) {
    this.passwordError = true;
    this.passwordMessage = `Please take safer password, score ${this.strength}/5`;
  } else {
    this.passwordError = false;
    this.passwordMessage = '';
  }
},
    submit() {
      let data = {
        name: this.companyName,
        email: this.email,
      };
      if (!this.edit_mode) {
        this.$store.dispatch('company/add', data).then(data => {
          this.$toast.success(`Company Created`, {
            position: 'top-right'
          })
          this.errors = [];
          this.cancel();
        }, error => {
          this.errors = error.errors;
          this.$toast.error(`Company Add Error`, {
            position: 'top-right'
          })
        });
      } else {
        data.id = this.item.id;
        delete data.email;
        this.$store.dispatch('company/edit', data).then(data => {
          this.errors = [];
          this.cancel();
        }, error => {
          this.errors = error.errors;
          this.$toast.error(`Company Edit Error`, {
            position: 'top-right'
          })
        });
      }
    },
    handleFileUpload() {
      this.avatar = this.$refs.file.files[0];
      this.$store.dispatch('file/add', {
        data: this.avatar
      }).then(data => {
        this.avatarUrl = data.data;
      });
    },
    cancel(){
      this.$emit('cancel')
    }
  },
  mounted() {
    if (this.edit_mode) {
      console.log('edit company: ', this.id)
      this.$store.dispatch('company/get', this.id).then(response => {
        this.item = response;
        this.companyName = response.companyName;
        this.email = response.email;
        this.avatarUrl = response.avatar;
        this.password = null;
        this.confirmPassword = null;
      });
    } else {
      console.log('modal open', 'company add')
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 400px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}
.body{
  padding: 8px 35px;
}
.form-group > input[type='text'], .form-group > input[type='password']{
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

</style>
import { authHeader } from '@/_helpers';
import handleResponse from '../_services/shared';

export const polygonRepository = {
    add,
    getAll,
    get,
    edit,
    remove,
};

function add(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/DevicePolygon/add`, requestOptions)
        .then(handleResponse);
}

function edit(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/DevicePolygon/edit`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function remove(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`/api/DevicePolygon/delete`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/DevicePolygon/${data}`, requestOptions).then(handleResponse);
}

function getAll(deviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/DevicePolygon?deviceId=${deviceId}`, requestOptions).then(handleResponse);
}


<template>
  <div class="modal-options">
      <div class="content">
        <div class="header panel-heading">
          <h3>Stream Settings</h3>
        </div>
        <div class="body">
          <div class="form-group">
            <label>Name</label>
            <input v-model="name" type="text" placeholder="">
          </div>
          <div v-if="!edit_mode" class="form-group">
            <label>Device</label>
            <select v-modfileel="device_id">
              <option v-bind:value="''"  v-if="sources.results && sources.results.length">Select Device</option>
              <option v-for="option in devices.results" v-bind:key="option.id" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Current Source <span v-if="type === 1">Zpt Camera</span>  <span v-if="type === 2 && item && item.source && item.source.file"><a target="_blank" :href="item.source.fileId ? $apiUrl + '/api/file/image/' + item.source.fileId: '/img/avatar.png'">{{item.source.name}}</a></span></label>


          </div>
          <div  v-if="!edit_mode" class="form-group">
            <label>Video Source</label>
            <select v-model="source_id">
              <option v-bind:value="''" v-if="sources.results && sources.results.length">Select Source</option>
              <option v-for="option in sources.results" v-bind:key="option.id" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div  v-if="!edit_mode" class="form-group">
            <span>Type: {{ type === 1 ? 'Normal' : 'Thermal' }}</span>
          </div>
          <div class="form-group">
            <label>Show mean temperatures.</label>
            <input type="checkbox" v-model="drawTemp">
          </div>
          <div class="form-group">
            <label>Show alerts.</label>
            <input type="checkbox" v-model="drawAlert">
          </div>

          <div class="form-group">
            <label>Show other information.</label>
            <input type="checkbox" v-model="drawOther">
          </div>
        </div>
        <div class="footer">
          <button @click="submit" class="btn btn-info btn-flat">Save</button>
          <button class="btn btn-info btn-flat" @click="cancel">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    edit_mode: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number
    }
  },
  name: "v-options-modal",
  data() {
    return{
      name: '',
      device_id: '',
      source_id: null,
      loop: 1,
      type: 2,
      drawAlert: null,
      drawTemp: null,
      drawOther: null,
      item: {},
    }
  },
  methods:{
    submit() {
      let data = {
        name: this.name,
      };
        data.id = this.item.id;
        data.draw_alert = this.drawAlert;
        data.draw_other = this.drawOther;
        data.draw_temp = this.drawTemp;
        if (this.source_id)
          data.source_id = this.source_id;
      this.$store.dispatch('device/edit', data).then(data => {
        this.$toast.success(`Alert Filter Created`, {
          position: 'top-right'
        });
          this.cancel();
        }, error => {
        this.$toast.error(`Alert Filter Create Error`, {
          position: 'top-right'
        });
      });
    },
    cancel(){
      this.$emit('cancel')
    }
  },
  computed: {
    devices () {
      return this.$store.state.device.all;
    },
    ...mapState({
      loggedIn: state => state.authentication.status.loggedIn,
    })
  },
  mounted() {
    this.$store.dispatch('device/getAll');
    if (this.edit_mode) {
      this.$store.dispatch('device/get', this.id).then(response => {
        this.item = response;
        this.name = response.name;
        this.drawAlert = response.drawAlert;
        this.drawTemp = response.drawTemp;
        this.drawOther = response.drawOther;
        this.device_id = response.device_id;
        this.source_id = response.source_id;
      });
    } else {
      console.log('add analyzer')
    }
  },
 
}
</script>

<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 500px;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;

}
.content > .header>*{
  margin-bottom: 0 !important;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .header > h3, .content > .body > h3{
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 2px;
}
.body{
  padding: 8px 35px;
}
.form-group > input[type='text']{
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
}
.inline-group{
  display: flex;
  align-items: center;
}
.inline-group > .item-block  label{
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 4px;
  font-weight: initial;
  margin-bottom: 0;
}
.inline-group > .item-block{
  display: flex;
  align-items: center;
  width: max-content;

}
.inline-group > .item-block > input{
  margin-bottom: 3px;
}

</style>
<template>
    <div class="modal-options">
    <div class="content">
      <div class="header panel-heading">
        <h5 style="text-transform:capitalize; padding-left:10px; color: #C8C9CE;">{{alert?.alertTitle}}</h5>
      </div>
      <div class="footer" style="position: relative;">
        <ol>
          <li class="alertModal"><div><img src="/img/calendar.png" width="18" height="18" alt=""></div><p class="textdata">{{getDateFormat(alert?.date)}}</p></li>
          <li class="alertModal"><div><img src="/img/clock.png"  width="18" height="18"  alt=""></div><p class="textdata">{{alert?.timestamp ? alert?.timestamp : "12:46:00 AM to 12:46:00"}}</p></li>
          <li class="alertModal"><div><img src="/img/person-circle.svg"  width="18" height="18"  alt=""></div><p class="textdata">{{alert?.userName ? alert?.userName : "supeAdmin"}}</p></li>
          <li class="alertModal"><div><img src="/img/polygon.svg"  width="18" height="18"  alt=""></div><p class="textdata">{{alert?.polygonName}}</p></li>
          <li class="alertModal"><div><img src="/img/cool.png"  width="18" height="18"  alt=""></div><p class="textdata">{{alert?.threshold2 ? alert?.threshold2 : "102"}}</p></li>
          <li class="alertModal"><div><img src="/img/settings.png"  width="18" height="18"  alt=""></div><p class="textdata">{{alert?.deviceName ? alert?.deviceName : "auto added key"}}</p></li>
          <li class="alertModal"><div><img src="/img/company-img.png"  width="20" height="20"  alt=""></div><p class="textdata">{{ this.companyName ? this.companyName : "-" }}</p></li>
          <li class="alertModal parent-name" :title="alert?.days"><div ><img src="/img/divider.png"  width="18" height="18"  alt=""></div><p class="textdata ellipsis">{{alert?.days}}</p></li>
        </ol>
        <div><button type="button" style="border-radius: 5px; color: #C8C9CE; background-color: #272c33; float: right; position: absolute; bottom: 20px; right: 20px;" class="closeBtn" title="Close" @click="this.cancel">close</button></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertDetailModal",
  props:{
    alert :{ 
      type:Object
    },
    modalAlertDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
      return{
        companyName: ""
      }
    },
  computed:{
  },
  mounted(){
    this.$store.dispatch('company/get', this.alert?.companyId).then(response => {
        this.companyName = response.name;
      });
  },
  methods:{
    getDateFormat(date) {
      const dateNew = new Date(date)
      const dateCheck = dateNew.getTime() - (dateNew.getTimezoneOffset() * 60 * 1000);
      const eestDate = new Date(dateCheck + (3 * 60 * 60 * 1000));
      const dateEEST = eestDate.toLocaleString('en-US');
      return dateEEST
      },
    cancel(){
      this.$emit('cancel')
    },
  }
}
</script>
<style scoped>
.modal-options{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right:0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: 350px;
  border-radius: 0;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #2C3E50;
  color: black;
  font-weight: 600;
  font-size: 20px;
  border: 1px solid black;
}
.content > .header>*{
  margin-bottom: 0 !important;
  text-align:left;
  text-transform: capitalize;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: left;
  border: 1px solid black;
  height: 450px;
}
.content > .footer > *{
  margin-left: 0.5rem;
  text-transform: capitalize;
}

.alertModal{
  padding: 10px;
  list-style-type:none;
  vertical-align: middle;
  display: flex;
}
.textdata{
  font-size: 18px;
  padding-left: 10px;
}

.parent-name {
    width: 310px;
}

.ellipsis {
 display: block !important;
  max-width: 100%;
  height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
</style>
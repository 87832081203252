const loggedIn = localStorage.getItem('user');
const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    hubState: 'Disconnected'
}

export const app = {
    namespaced: true,
    state: initialState,
    actions: {
        setHubState({ dispatch, commit }, state) {
            commit('setHubState', state);
        },
    },
    mutations: {
        setHubState(state, hubState) {
            state.hubState = hubState;
        },
    }
}

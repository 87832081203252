<template>
  <div>
    <div v-if="this.analyzerId">
      <div>
        <GeneratedAlertVideo :session-id="this.analyzerId + 'filterModal'" :video_id="this.video_id" @play="sourcePlay" @loadedmetadata="this.metadataLoaded" :id="this.analyzerId" :width="0" :height="0"/>
        <canvas v-show="!showSpinner" :width="sourceCanvasWidth" :height="sourceCanvasHeight" ref="canRef" id="canvas"></canvas>
        <div v-show="showSpinner" class="spinner-div">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import GeneratedAlertVideo from "../../components/GeneratedAlertVideo.vue";
export default {
  props: {
    id: {
      type: Number
    },
    analyzerId: {
      type: Number,
      default: null,
    },
    video_id: {
      type: Number
    },
    alertSpots: {
      type: Array
    }
  },
  components:{GeneratedAlertVideo},
  computed: {
    analyzers () {
      return this.$store.state.analyzer.all;
    },
    ...mapState({
      alertFilters: state => state.alertFilter.all,
    })
  },
  name: "v-options-modal",
  data() {
    return{
      showSpinner: true,
      mode: 0,
      analyzer: null,
      edit_mode: true,
      sourceCanvasWidth: 1024,
      sourceCanvasHeight: 576,
      videoWidth: 640,
      videoHeight: 480,
      iter: -1,
      item: {},
      polygon:{},
      sourcePlayed: false,
      ctx: {},
      end: true,
      canvas: document.createElement("canvas"),
    }
  },
  methods: {
    metadataLoaded(e) {
      this.videoWidth = e.target.videoWidth;
      this.videoHeight = e.target.videoHeight;
      this.sourceCanvasHeight = (this.sourceCanvasWidth * this.videoHeight) / this.videoWidth;
    },
    sourcePlay(e) {
      this.showSpinner = false;
      let self = this;
      this.sourcePlayed = true;
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      var ctx = this.ctx;
      var end = this.end;
      var iter = this.iter;
      var $this = e.target; //cache
      (function loop() {
        if (!$this.paused && !$this.ended) {
          ctx.drawImage($this, 0, 0, self.sourceCanvasWidth, self.sourceCanvasHeight);
          iter = iter + 1;
          setTimeout(loop, 1000 / 30); // drawing at 30fps
        }
      })();
      console.log('source playing', e)
    },
    initAnalyzer(){
      let self = this;
      this.$store.dispatch('device/get', this.analyzerId).then(response => {
        if (!response.id) {
          this.$toast.warning(`Stream has no source setup`, {
            position: 'top-right'
          });
        } else {
          self.analyzer = response;
        }
      })
    },
    sourcePlaying(e) {
      console.log('source playing', e)
    },
  },
  mounted() {
    this.$store.dispatch('device/get', this.analyzerId).then((data) => {
      this.analyzer = data;
    })
  },
  watch: {
    sourcePlayed : function(val) {
    },
    analyzerId: function(){
      this.initAnalyzer();
    }
  }
}
</script>
<style scoped>
.modal-polygon{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(141, 141, 141, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  background-color: #fff;
  width: max-content;
  height: max-content;
  border-radius: 0;
  border: 1px solid #0288d1;
  border-top: 0;
}
.content > *{
  padding: 8px;
  border-radius: 0;
}
.content > .header{
  background-color: #0288d1;
  color: #fff;
}
.content > .header>*{
  margin-bottom: 0 !important;
}
/*.content > canvas{*/
/*  width: 640px;*/
/*  height: 320px;*/
/*}*/
.content > .forms-block{
  background-color: #f9f9f9;
  margin: 10px;
  float: right;
  padding: 0;
}
.content > .forms-block > h3{
  margin-top: 0;
  background-color: #0288d1;
  padding: 5px;
  color: #fff;
  margin-bottom: 1px;
}
.options-block {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  padding: 3px;
  align-items: center;
}
.options-block > label{
  display: block;
}
.options-block > input{
  width: 100%;
}
.content > .forms-block > textarea{
  display: block;
  margin-bottom: 15px;
}
.content > .footer{
  border-top: 1px solid #ccc;
  text-align: right;
}
.content > .footer > *{
  margin-left: 3px;
}
.content > .footer > .list{
  float: left;
  padding-top: 5px;
}
.content > .footer > .list > span{
  margin-right: 15px;
}
.spinner-div {
  width: 1024px; 
  height: 576px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pol-name{
  height: 42px;
}
.list-pol{
  height: 25px;
}
.option-name-colour{
  display: flex;
  justify-content:space-between;
}
.option-name-colour label {
    font-size: 0.875em;
    margin-bottom: 5px;
    display: block;
    margin-right: 15px;
    padding: 6px 15px 0 0;
}
.option-name-colour input{
  width: 50rem !important;
}
.option-name-colour select{
  width: 25rem !important;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #272c33;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #272c33 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>